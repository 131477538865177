define("incubex/components/x-tree", ["exports", "incubex/components/x-tree", "incubex/utils/tree"], function (_exports, _xTree, _tree) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{#if hasBlock}}
    {{#x-tree-branch
      checkable=checkable
      recursiveCheck=recursiveCheck
      chosenId=chosenId
      onCheck=onCheck
      onContextMenu=onContextMenu
      onSelect=onSelect
      onHover=onHover
      onHoverOut=onHoverOut
      model=model
      expandedIcon=expandedIcon
      collapsedIcon=collapsedIcon
      onToggle=onToggle
      as |node|
    }}
      {{yield node}}
    {{/x-tree-branch}}
  {{else}}
    {{x-tree-branch
      model=model
      checkable=checkable
      recursiveCheck=recursiveCheck
      chosenId=chosenId
      onCheck=onCheck
      onContextMenu=onContextMenu
      onSelect=onSelect
      onHover=onHover
      onHoverOut=onHoverOut
      expandedIcon=expandedIcon
      collapsedIcon=collapsedIcon
      onToggle=onToggle
    }}
  {{/if}}
  
  */
  {
    id: "yWwHZyGG",
    block: "{\"symbols\":[\"node\",\"&default\"],\"statements\":[[4,\"if\",[[25,2]],null,{\"statements\":[[4,\"x-tree-branch\",null,[[\"checkable\",\"recursiveCheck\",\"chosenId\",\"onCheck\",\"onContextMenu\",\"onSelect\",\"onHover\",\"onHoverOut\",\"model\",\"expandedIcon\",\"collapsedIcon\",\"onToggle\"],[[24,[\"checkable\"]],[24,[\"recursiveCheck\"]],[24,[\"chosenId\"]],[24,[\"onCheck\"]],[24,[\"onContextMenu\"]],[24,[\"onSelect\"]],[24,[\"onHover\"]],[24,[\"onHoverOut\"]],[24,[\"model\"]],[24,[\"expandedIcon\"]],[24,[\"collapsedIcon\"]],[24,[\"onToggle\"]]]],{\"statements\":[[0,\"    \"],[14,2,[[23,1,[]]]],[0,\"\\n\"]],\"parameters\":[1]},null]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[1,[28,\"x-tree-branch\",null,[[\"model\",\"checkable\",\"recursiveCheck\",\"chosenId\",\"onCheck\",\"onContextMenu\",\"onSelect\",\"onHover\",\"onHoverOut\",\"expandedIcon\",\"collapsedIcon\",\"onToggle\"],[[24,[\"model\"]],[24,[\"checkable\"]],[24,[\"recursiveCheck\"]],[24,[\"chosenId\"]],[24,[\"onCheck\"]],[24,[\"onContextMenu\"]],[24,[\"onSelect\"]],[24,[\"onHover\"]],[24,[\"onHoverOut\"]],[24,[\"expandedIcon\"]],[24,[\"collapsedIcon\"]],[24,[\"onToggle\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    meta: {
      moduleName: "incubex/components/x-tree.hbs"
    }
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember.Component.extend({
    layout: _xTree.default,
    expandDepth: 0,
    recursiveCheck: false,
    classNames: ['tree'],
    expandedIcon: 'x-tree-expanded-icon',
    collapsedIcon: 'x-tree-collapsed-icon',

    init() {
      this._super(...arguments); // Make sure chosen item is highlighted and expanded-to in the tree


      if (this.chosenId) {
        let chosen = (0, _tree.getDescendents)(this.model).findBy('id', this.chosenId);

        if (chosen) {
          (0, _tree.getAncestors)(this.model, chosen).forEach(x => {
            if (Ember.get(x, 'id') !== this.chosenId) {
              Ember.set(x, 'isExpanded', true);
            }
          });
        }
      } // Expand to given depth


      if (this.expandDepth) {
        (0, _tree.getDescendents)(this.model, this.expandDepth).setEach('isExpanded', true);
      }
    }

  }));

  _exports.default = _default;
});