define("incubex/components/positions-filter", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class, _descriptor, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{#modal-dialog onClickOverlay= (action this.args.onClose )}}
    <Button
      @style="link"
      @class="flex text-base items-center absolute top-16 right-16 font-bold"
      @onClick={{this.args.onClose}}
    >
      {{svg-jar "close" height="12" width="12" class="ml-8 font-bold"}}
    </Button>
    <h1 class="text-16 font-bold text-base mt-16">Filters - Positions Tab</h1>
    <div class="flex justify-between items-center mt-16 text-sm">
        Filter to Active Portfolio 
        <Toggle
          @checked={{this.toActivePortfolio}}
          @label="filterToActivePortfolio"
          @role="filter-to-active-portfolio"
          @class="ml-8"
        />
    </div>
    <div class="mt-18 flex  flex-row justify-end">
      <Button
        @size="small"
        @style="link"
        @class="m-8 text-base font-bold mr-24"
        @onClick={{this.args.onClose}}
        @id="close-column-display-settings"
      >
        Cancel
      </Button>
      <Button
        @size="small"
        @class="m-8"
        @id="update-orders"
        @onClick={{this.onUpdate}}
      >
        Update
      </Button>
    </div>
  {{/modal-dialog}}
  */
  {
    id: "MZPyoPI2",
    block: "{\"symbols\":[],\"statements\":[[4,\"modal-dialog\",null,[[\"onClickOverlay\"],[[28,\"action\",[[23,0,[]],[23,0,[\"args\",\"onClose\"]]],null]]],{\"statements\":[[0,\"  \"],[5,\"button\",[],[[\"@style\",\"@class\",\"@onClick\"],[\"link\",\"flex text-base items-center absolute top-16 right-16 font-bold\",[23,0,[\"args\",\"onClose\"]]]],{\"statements\":[[0,\"\\n    \"],[1,[28,\"svg-jar\",[\"close\"],[[\"height\",\"width\",\"class\"],[\"12\",\"12\",\"ml-8 font-bold\"]]],false],[0,\"\\n  \"]],\"parameters\":[]}],[0,\"\\n  \"],[7,\"h1\",true],[10,\"class\",\"text-16 font-bold text-base mt-16\"],[8],[0,\"Filters - Positions Tab\"],[9],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"flex justify-between items-center mt-16 text-sm\"],[8],[0,\"\\n      Filter to Active Portfolio \\n      \"],[5,\"toggle\",[],[[\"@checked\",\"@label\",\"@role\",\"@class\"],[[23,0,[\"toActivePortfolio\"]],\"filterToActivePortfolio\",\"filter-to-active-portfolio\",\"ml-8\"]]],[0,\"\\n  \"],[9],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"mt-18 flex  flex-row justify-end\"],[8],[0,\"\\n    \"],[5,\"button\",[],[[\"@size\",\"@style\",\"@class\",\"@onClick\",\"@id\"],[\"small\",\"link\",\"m-8 text-base font-bold mr-24\",[23,0,[\"args\",\"onClose\"]],\"close-column-display-settings\"]],{\"statements\":[[0,\"\\n      Cancel\\n    \"]],\"parameters\":[]}],[0,\"\\n    \"],[5,\"button\",[],[[\"@size\",\"@class\",\"@id\",\"@onClick\"],[\"small\",\"m-8\",\"update-orders\",[23,0,[\"onUpdate\"]]]],{\"statements\":[[0,\"\\n      Update\\n    \"]],\"parameters\":[]}],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    meta: {
      moduleName: "incubex/components/positions-filter.hbs"
    }
  });

  let PositionsFilterComponent = (_class = (_temp = class PositionsFilterComponent extends _component.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "toActivePortfolio", _descriptor, this);

      this.toActivePortfolio = this.args.positionsFilters.toActivePortfolio;
    }

    onUpdate() {
      let positionsFilters = {
        toActivePortfolio: this.toActivePortfolio
      };

      if (this.args.onUpdate) {
        this.args.onUpdate(positionsFilters);
      }
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "toActivePortfolio", [Ember._tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "onUpdate", [Ember._action], Object.getOwnPropertyDescriptor(_class.prototype, "onUpdate"), _class.prototype)), _class);
  _exports.default = PositionsFilterComponent;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, PositionsFilterComponent);
});