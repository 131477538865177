define("incubex/routes/authenticated/new-tab", ["exports", "incubex/types/url", "incubex/utils/common"], function (_exports, _url, _common) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class, _descriptor, _descriptor2, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let AuthenticatedNewTabRoute = (_class = (_temp = class AuthenticatedNewTabRoute extends Ember.Route {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "sessionApi", _descriptor, this);

      _initializerDefineProperty(this, "portfolioTabsService", _descriptor2, this);

      _defineProperty(this, "queryParams", {
        copyTabId: {
          refreshModel: true
        },
        editTabId: {
          refreshModel: true
        }
      });
    }

    async model(params) {
      if (params.copyTabId || params.editTabId) {
        if (params.copyTabId) {
          this.mode = 'copy';
        }

        if (params.editTabId) {
          this.mode = 'edit';
        }

        let tabId = params.copyTabId || params.editTabId;
        let tab = await this.sessionApi.get("".concat(_url.default.PORTFOLIO_TAB, "/").concat(tabId));
        return tab;
      }

      this.mode = 'new';
      return null;
    }

    async setupController(controller, model) {
      controller.mode = this.mode;
      controller.tabData = null;
      controller.headerName = 'New Portfolio Tab';
      controller.orderSettingData = null;

      if (model) {
        if (this.mode == 'copy') controller.portfolioName = "Copy of ".concat(model.name);

        if (this.mode == 'edit') {
          controller.portfolioName = model.name;
          controller.headerName = model.name;
        }

        controller.tabData = model;
        let instruments = (0, _common.sortTradableInstruments)((await this.portfolioTabsService.getTradableInstruments(controller.tabData.id)), model.tradable_instrument_ids);
        controller.instruments = instruments ? instruments.map(item => {
          return {
            label: item ? item.instrument_description : null,
            id: item ? item.id : null
          };
        }) : [];
      } else {
        controller.portfolioName = 'Portfolio Name';
        controller.instruments = [];
      }

      controller.searchedResults = [];
      controller.productGroup = 'All';
      controller.productType = 'Future';
      controller.productSubType = 'All';
      controller.searchValue = '';
      controller.showNewProductsOnly = false;
      controller.updateSearchResults();
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "sessionApi", [Ember.inject.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "portfolioTabsService", [Ember.inject.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class);
  _exports.default = AuthenticatedNewTabRoute;
});