define("incubex/utils/input-validators", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.validatePhoneNumber = _exports.validateContainsLowerCase = _exports.validateContainsUpperCase = _exports.validateContainsNumber = _exports.validateLength = _exports.validateEmail = void 0;

  const validateEmail = value => {
    let regex = /^[\w]+([+.-]?\w+)+@([\w-]+\.)+[\w-]{2,3}$/g;
    return value && value.match(regex);
  };

  _exports.validateEmail = validateEmail;

  const validateLength = (value, length = 8) => {
    return value && value.length >= length;
  };

  _exports.validateLength = validateLength;

  const validateContainsNumber = value => {
    return value && value.match(/\d/);
  };

  _exports.validateContainsNumber = validateContainsNumber;

  const validateContainsUpperCase = value => {
    return value && value.match(/[A-Z]/);
  };

  _exports.validateContainsUpperCase = validateContainsUpperCase;

  const validateContainsLowerCase = value => {
    return value && value.match(/[a-z]/);
  };

  _exports.validateContainsLowerCase = validateContainsLowerCase;

  const validatePhoneNumber = value => {
    //let regex = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
    let regex = /^(\+\d{1,2})?\d{10}$/;
    return value && value.match(regex);
  };

  _exports.validatePhoneNumber = validatePhoneNumber;
});