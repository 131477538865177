define("incubex/templates/components/instruments-collection", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "F1JaMm1O",
    "block": "{\"symbols\":[\"opt\",\"index\",\"@select\",\"@groupIndex\",\"&default\",\"@loadingMessage\",\"&attrs\",\"@options\"],\"statements\":[[7,\"ul\",false],[12,\"role\",\"listbox\"],[12,\"aria-controls\",[29,[\"ember-power-select-trigger-\",[23,3,[\"uniqueId\"]]]]],[13,7],[3,\"did-insert\",[[23,0,[\"addHandlers\"]]]],[8],[0,\"\\n\"],[4,\"if\",[[23,3,[\"loading\"]]],null,{\"statements\":[[4,\"if\",[[23,6,[]]],null,{\"statements\":[[0,\"      \"],[7,\"li\",true],[10,\"class\",\"ember-power-select-option ember-power-select-option--loading-message\"],[10,\"role\",\"option\"],[8],[1,[23,6,[]],false],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null],[0,\"  \\n\"],[4,\"vertical-collection\",[[23,8,[]]],[[\"renderAll\",\"estimateHeight\"],[false,20]],{\"statements\":[[0,\"    \"],[7,\"li\",true],[10,\"class\",\"ember-power-select-option\"],[11,\"aria-selected\",[29,[[28,\"ember-power-select-is-selected\",[[23,1,[]],[23,3,[\"selected\"]]],null]]]],[11,\"aria-disabled\",[28,\"if\",[[23,1,[\"disabled\"]],\"true\"],null]],[11,\"aria-current\",[29,[[28,\"eq\",[[23,1,[]],[23,3,[\"highlighted\"]]],null]]]],[11,\"data-option-index\",[29,[[23,4,[]],[23,2,[]]]]],[10,\"role\",\"option\"],[8],[0,\"\\n      \"],[14,5,[[23,1,[]],[23,3,[]]]],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[1,2]},null],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "incubex/templates/components/instruments-collection.hbs"
    }
  });

  _exports.default = _default;
});