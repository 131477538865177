define("incubex/components/order-trade-history-table", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class, _descriptor, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <EmberTable @name="order-trade-history-table" @class="order-trade-history-table" as |t|>
    <t.head @columns={{this.columns}} @class="order-trade-history-table-header" @enableReorder={{false}} @sorts={{this.sorts}}
      @onUpdateSorts={{action this.onSort}} @sortFunction={{null}} as |h|>
      <h.row as |r|>
        <r.cell as |column columnMeta|>
          <div class="flex justify-between">
            {{column.name}}
            <div class="mr-8">
              <EmberTh::SortIndicator @columnMeta={{columnMeta}} />
            </div>
  
          </div>
          <EmberTh::ResizeHandle @columnMeta={{columnMeta}} />
        </r.cell>
      </h.row>
    </t.head>
    <t.body @rows={{this.rows}} @renderAll={{false}} as |b|>
      <b.row as |r|>
        <r.cell as |value|>
          {{value}}
        </r.cell>
      </b.row>
    </t.body>
  </EmberTable>
  <div class="mt-16">
    <Pagination @paginationData={{this.args.paginationData}} @onPageChange={{this.args.onPageChange}} ></Pagination>
  </div>
  */
  {
    id: "lpRYyRsC",
    block: "{\"symbols\":[\"t\",\"b\",\"r\",\"value\",\"h\",\"r\",\"column\",\"columnMeta\"],\"statements\":[[5,\"ember-table\",[],[[\"@name\",\"@class\"],[\"order-trade-history-table\",\"order-trade-history-table\"]],{\"statements\":[[0,\"\\n  \"],[6,[23,1,[\"head\"]],[],[[\"@columns\",\"@class\",\"@enableReorder\",\"@sorts\",\"@onUpdateSorts\",\"@sortFunction\"],[[23,0,[\"columns\"]],\"order-trade-history-table-header\",false,[23,0,[\"sorts\"]],[28,\"action\",[[23,0,[]],[23,0,[\"onSort\"]]],null],null]],{\"statements\":[[0,\"\\n    \"],[6,[23,5,[\"row\"]],[],[[],[]],{\"statements\":[[0,\"\\n      \"],[6,[23,6,[\"cell\"]],[],[[],[]],{\"statements\":[[0,\"\\n        \"],[7,\"div\",true],[10,\"class\",\"flex justify-between\"],[8],[0,\"\\n          \"],[1,[23,7,[\"name\"]],false],[0,\"\\n          \"],[7,\"div\",true],[10,\"class\",\"mr-8\"],[8],[0,\"\\n            \"],[5,\"ember-th/sort-indicator\",[],[[\"@columnMeta\"],[[23,8,[]]]]],[0,\"\\n          \"],[9],[0,\"\\n\\n        \"],[9],[0,\"\\n        \"],[5,\"ember-th/resize-handle\",[],[[\"@columnMeta\"],[[23,8,[]]]]],[0,\"\\n      \"]],\"parameters\":[7,8]}],[0,\"\\n    \"]],\"parameters\":[6]}],[0,\"\\n  \"]],\"parameters\":[5]}],[0,\"\\n  \"],[6,[23,1,[\"body\"]],[],[[\"@rows\",\"@renderAll\"],[[23,0,[\"rows\"]],false]],{\"statements\":[[0,\"\\n    \"],[6,[23,2,[\"row\"]],[],[[],[]],{\"statements\":[[0,\"\\n      \"],[6,[23,3,[\"cell\"]],[],[[],[]],{\"statements\":[[0,\"\\n        \"],[1,[23,4,[]],false],[0,\"\\n      \"]],\"parameters\":[4]}],[0,\"\\n    \"]],\"parameters\":[3]}],[0,\"\\n  \"]],\"parameters\":[2]}],[0,\"\\n\"]],\"parameters\":[1]}],[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"mt-16\"],[8],[0,\"\\n  \"],[5,\"pagination\",[],[[\"@paginationData\",\"@onPageChange\"],[[23,0,[\"args\",\"paginationData\"]],[23,0,[\"args\",\"onPageChange\"]]]],{\"statements\":[],\"parameters\":[]}],[0,\"\\n\"],[9]],\"hasEval\":false}",
    meta: {
      moduleName: "incubex/components/order-trade-history-table.hbs"
    }
  });

  let OrderTradeHistoryTableComponent = (_class = (_temp = class OrderTradeHistoryTableComponent extends _component.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "sorts", _descriptor, this);
    }

    get columns() {
      return [{
        name: 'Exchange',
        valuePath: 'exchange',
        width: 50
      }, {
        name: 'Product Group',
        valuePath: 'product_group',
        width: 70
      }, {
        name: 'Product Sub-Group',
        valuePath: 'product_sub_group',
        width: 70
      }, {
        name: 'Product Name',
        valuePath: 'product_short_name',
        width: 150
      }, {
        name: 'Expiration',
        valuePath: 'expiration_date',
        width: 75
      }, {
        name: 'Exec Type',
        valuePath: 'exec_type',
        width: 65
      }, {
        name: 'Side',
        valuePath: 'side',
        width: 50
      }, {
        name: 'Filled/Total',
        valuePath: 'filled_total',
        width: 65
      }, {
        name: 'Order Price',
        valuePath: 'price',
        width: 50
      }, {
        name: 'Time-In-Force',
        valuePath: 'time_in_force',
        width: 50
      }, {
        name: 'Status',
        valuePath: 'ord_status',
        width: 65
      }, {
        name: 'Rejection Reason',
        valuePath: 'rejection_text'
      }, {
        name: 'Trade Price',
        valuePath: 'fill_px',
        width: 50
      }, {
        name: 'Trade Quantity',
        valuePath: 'fill_qty',
        width: 60
      }, {
        name: 'Time (EDT)',
        valuePath: 'transaction_time',
        width: 100
      }, {
        name: 'T7 User',
        valuePath: 't7_user_id',
        width: 60
      }, {
        name: 'Order ID',
        valuePath: 'order_id',
        width: 80
      }];
    }

    get rows() {
      return this.args.rows;
    }

    onSort(args1) {
      let new_args1 = args1.rejectBy('valuePath', 'filled_total');
      this.sorts = new_args1;

      if (this.args.onSort) {
        this.args.onSort(new_args1);
      }
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "sorts", [Ember._tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return [];
    }
  }), _applyDecoratedDescriptor(_class.prototype, "onSort", [Ember._action], Object.getOwnPropertyDescriptor(_class.prototype, "onSort"), _class.prototype)), _class);
  _exports.default = OrderTradeHistoryTableComponent;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, OrderTradeHistoryTableComponent);
});