define("incubex/templates/components/x-tab/nav-item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "glK9aPIR",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[7,\"a\",false],[12,\"href\",[29,[\"#\",[22,\"paneId\"]]]],[12,\"role\",\"tab\"],[12,\"aria-controls\",[22,\"paneId\"]],[3,\"action\",[[23,0,[]],[28,\"action\",[[23,0,[]],[24,[\"selectAction\"]],[24,[\"paneId\"]]],null]]],[8],[0,\"\\n  \"],[14,1],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "incubex/templates/components/x-tab/nav-item.hbs"
    }
  });

  _exports.default = _default;
});