define("incubex/components/x-tree-node", ["exports", "incubex/components/x-tree-node"], function (_exports, _xTreeNode) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{#if hasBlock}}
    {{yield
      (hash
        model=model
        toggle=(component "x-tree-toggle"
          model=model
          toggleExpand=(action "toggleExpand")
          expandedIcon=expandedIcon
          collapsedIcon=collapsedIcon
        )
        checkbox=(component "x-tree-checkbox"
          model=model
          toggleCheck=(action "toggleCheck")
        )
      )
    }}
  {{else}}
    {{x-tree-toggle
      model=model
      toggleExpand=(action "toggleExpand")
      expandedIcon=expandedIcon
      collapsedIcon=collapsedIcon
    }}
  
    {{#if checkable}}
      {{x-tree-checkbox
        model=model
        toggleCheck=(action "toggleCheck")
      }}
    {{/if}}
    <span class="tree-label">
      {{model.name}}
    </span>
  {{/if}}
  
  */
  {
    id: "yAl6A11q",
    block: "{\"symbols\":[\"&default\"],\"statements\":[[4,\"if\",[[25,1]],null,{\"statements\":[[0,\"  \"],[14,1,[[28,\"hash\",null,[[\"model\",\"toggle\",\"checkbox\"],[[24,[\"model\"]],[28,\"component\",[\"x-tree-toggle\"],[[\"model\",\"toggleExpand\",\"expandedIcon\",\"collapsedIcon\"],[[24,[\"model\"]],[28,\"action\",[[23,0,[]],\"toggleExpand\"],null],[24,[\"expandedIcon\"]],[24,[\"collapsedIcon\"]]]]],[28,\"component\",[\"x-tree-checkbox\"],[[\"model\",\"toggleCheck\"],[[24,[\"model\"]],[28,\"action\",[[23,0,[]],\"toggleCheck\"],null]]]]]]]]],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[1,[28,\"x-tree-toggle\",null,[[\"model\",\"toggleExpand\",\"expandedIcon\",\"collapsedIcon\"],[[24,[\"model\"]],[28,\"action\",[[23,0,[]],\"toggleExpand\"],null],[24,[\"expandedIcon\"]],[24,[\"collapsedIcon\"]]]]],false],[0,\"\\n\\n\"],[4,\"if\",[[24,[\"checkable\"]]],null,{\"statements\":[[0,\"    \"],[1,[28,\"x-tree-checkbox\",null,[[\"model\",\"toggleCheck\"],[[24,[\"model\"]],[28,\"action\",[[23,0,[]],\"toggleCheck\"],null]]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"],[7,\"span\",true],[10,\"class\",\"tree-label\"],[8],[0,\"\\n    \"],[1,[24,[\"model\",\"name\"]],false],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    meta: {
      moduleName: "incubex/components/x-tree-node.hbs"
    }
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember.Component.extend({
    layout: _xTreeNode.default,
    classNameBindings: ['model.isDisabled:tree-disabled', 'model.isSelected:tree-highlight', 'isChosen:tree-chosen', 'model.children.length:tree-children'],
    recursiveCheck: false,
    isChosen: Ember.computed('model.id', 'chosenId', function () {
      return Ember.get(this, 'model.id') === this.chosenId;
    }),

    click() {
      if (this.onSelect && !Ember.get(this, 'model.isDisabled')) {
        let wasChecked = Ember.get(this, 'model.isChecked');
        this.onSelect(this.model);
        let isChecked = Ember.get(this, 'model.isChecked');

        if (isChecked !== wasChecked && this.recursiveCheck) {
          this.setChildCheckboxesRecursively(this.model, isChecked);
          this.updateCheckbox();
        }
      }
    },

    contextMenu(event) {
      if (this.onContextMenu) {
        event.preventDefault();
        this.onContextMenu(this.model);
      }
    },

    didInsertElement() {
      this._super(...arguments);

      this._handleMouseEnter = this.handleMouseEnter.bind(this);
      this._handleMouseLeave = this.handleMouseLeave.bind(this);
      this.element.addEventListener('mouseenter', this._handleMouseEnter);
      this.element.addEventListener('mouseleave', this._handleMouseLeave);
    },

    willDestroyElement() {
      this._super(...arguments);

      this.element.removeEventListener('mouseenter', this._handleMouseEnter);
      this.element.removeEventListener('mouseleave', this._handleMouseLeave);
    },

    handleMouseEnter() {
      if (!Ember.get(this, 'model.isDisabled')) {
        Ember.set(this, 'model.isSelected', true);
      }

      if (this.onHover) {
        this.onHover(this.model);
      }
    },

    handleMouseLeave() {
      Ember.set(this, 'model.isSelected', false);

      if (this.onHoverOut) {
        this.onHoverOut(this.model);
      }
    },

    setChildCheckboxesRecursively(node, isChecked) {
      let children = Ember.get(node, 'children');

      if (children && children.length) {
        children.forEach(child => {
          Ember.setProperties(child, {
            isChecked,
            isIndeterminate: false
          });
          this.setChildCheckboxesRecursively(child, isChecked);
        });
      }
    },

    actions: {
      toggleCheck(event) {
        event.stopPropagation();

        if (!Ember.get(this, 'model.isDisabled')) {
          let isChecked = this.toggleProperty('model.isChecked');

          if (this.recursiveCheck) {
            this.setChildCheckboxesRecursively(this.model, isChecked);
            this.updateCheckbox();
          }

          if (this.onCheck) {
            this.onCheck(this.model);
          }
        }
      },

      toggleExpand() {
        this.toggleProperty('model.isExpanded');
        if (this.onToggle) this.onToggle(this.model);
      }

    }
  }));

  _exports.default = _default;
});