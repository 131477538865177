define("incubex/components/cell-fills-table", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{#if this.args.column.name}}
    <div>
      {{this.args.value}}
    </div>
    {{else}}
      <div>
        <Button
          @style="link"
          @id="view"
          @class="text-sm text-green-400"
          @onClick={{action this.onFillView this.args.cell}}
        >
          View
        </Button>
      </div>
  {{/if}}
  */
  {
    id: "QABVNafh",
    block: "{\"symbols\":[],\"statements\":[[4,\"if\",[[23,0,[\"args\",\"column\",\"name\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\",true],[8],[0,\"\\n    \"],[1,[23,0,[\"args\",\"value\"]],false],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[7,\"div\",true],[8],[0,\"\\n      \"],[5,\"button\",[],[[\"@style\",\"@id\",\"@class\",\"@onClick\"],[\"link\",\"view\",\"text-sm text-green-400\",[28,\"action\",[[23,0,[]],[23,0,[\"onFillView\"]],[23,0,[\"args\",\"cell\"]]],null]]],{\"statements\":[[0,\"\\n        View\\n      \"]],\"parameters\":[]}],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    meta: {
      moduleName: "incubex/components/cell-fills-table.hbs"
    }
  });

  let CellFillsTableComponent = (_class = class CellFillsTableComponent extends _component.default {
    onFillView(cell) {
      if (this.args.onViewFillDetails) this.args.onViewFillDetails(cell.fill);
    }

  }, (_applyDecoratedDescriptor(_class.prototype, "onFillView", [Ember._action], Object.getOwnPropertyDescriptor(_class.prototype, "onFillView"), _class.prototype)), _class);
  _exports.default = CellFillsTableComponent;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, CellFillsTableComponent);
});