define("incubex/freestyle-snippets", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "border-box--usage.hbs": "        <p class=\"border-box inline-block w-1-4 p-24 border text-txt\">This box is 25% wide with a 24px padding on all sides.</p>",
    "button-class--usage.hbs": "        <Button\n          @class=\"m-16\"\n          @onClick={{action \"onClick\"}}\n          >\n          Click Me\n        </Button>",
    "button-disabled--usage.hbs": "        <Button\n          @disabled={{true}}\n          @onClick={{action \"onClick\"}}\n        >\n          Click Me\n        </Button>",
    "button-large--usage.hbs": "        <Button\n          @size=\"large\"\n          @onClick={{action \"onClick\"}}\n        >\n          Click Me\n        </Button>",
    "button-link--usage.hbs": "        <Button\n          @style=\"link\"\n          @onClick={{action \"onClick\"}}\n          @class=\"text-sm text-base\"\n        >\n          Click Me\n        </Button>",
    "button-primary--usage.hbs": "        <Button\n          @onClick={{action \"onClick\"}}\n        >\n          Click Me\n        </Button>",
    "button-primary-icon--usage.hbs": "        <Button\n          @icon=\"test\"\n          @onClick={{action \"onClick\"}}\n        >\n          Click Me\n        </Button>",
    "button-role--usage.hbs": "        <Button\n          @onClick={{action \"onClick\"}}\n          @role=\"example\"\n        >\n          Click Me\n        </Button>",
    "button-secondary--usage.hbs": "        <Button\n          @style=\"secondary\"\n          @onClick={{action \"onClick\"}}\n        >\n          Click Me\n        </Button>",
    "button-secondary-icon--usage.hbs": "        <Button\n          @icon=\"test\"\n          @style=\"secondary\"\n          @onClick={{action \"onClick\"}}\n        >\n          Click Me\n        </Button>",
    "button-small--usage.hbs": "        <Button\n          @size=\"small\"\n          @onClick={{action \"onClick\"}}\n        >\n          Click Me\n        </Button>",
    "checkbox-basic--usage.hbs": "        <Input::Checkbox\n          @checked={{this.checkboxValue}}\n          @label=\"Is this checked?\"\n          @name=\"whatever\"\n        />",
    "checkbox-disabled--usage.hbs": "        <Input::Checkbox\n          @checked={{true}}\n          @disabled=\"true\"\n          @label=\"Is this checked?\"\n          @name=\"whatever\"\n        />",
    "checkbox-event--usage.hbs": "        <Input::Checkbox\n          @checked={{this.checkboxAltValue}}\n          @label=\"Is this checked?\"\n          @name=\"whatever\"\n          @onClick={{action \"onCheckBoxClick\"}}\n        />",
    "checkbox-with-block--usage.hbs": "        <Input::Checkbox\n          @checked={{this.checkboxValue}}\n          @name=\"whatever\"\n        >\n          Is this checked?\n        </Input::Checkbox>",
    "content-box--usage.hbs": "        <p class=\"content-box inline-block w-1-4 p-24 border text-txt\">This box is 25% wide with a 24px padding on all sides.</p>",
    "copyright--usage.hbs": "        <Copyright @class=\"text-base-50a\" />",
    "divider-dark--usage.hbs": "        <hr class=\"div-dark\">",
    "divider-light--usage.hbs": "        <hr class=\"div-light\">",
    "dropdown-base--usage.hbs": "        <Dropdown\n          @class=\"m-8\"\n          @label=\"Example Dropdown\"\n          @options={{this.dropdownOptions}}\n          @value={{this.dropdownValue}}\n        />",
    "dropdown-disabled--usage.hbs": "        <Dropdown\n          @label=\"Example Dropdown\"\n          @options={{this.dropdownOptions}}\n          @value={{this.dropdownValue}}\n          @disabled={{true}}\n        />",
    "dropdown-full-width--usage.hbs": "        <Dropdown\n          @class=\"w-full\"\n          @label=\"Example Dropdown\"\n          @options={{this.dropdownOptions}}\n          @value={{this.dropdownValue}}\n        />",
    "dropdown-with-message--usage.hbs": "        <Dropdown\n          @label=\"Example Dropdown\"\n          @options={{this.dropdownOptions}}\n          @value={{this.dropdownValue}}\n          @message=\"Help text\"\n        />",
    "font-primary--usage.hbs": "        <h3 class=\"heading-3 font-primary\">Source Sans Pro</h3>\n        <p class=\"font-primary\">\n          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut\n          labore et dolore magna aliqua. Libero volutpat sed cras ornare arcu dui. Urna condimentum mattis\n          pellentesque id. Non nisi est sit amet facilisis magna etiam tempor orci.\n        </p>",
    "font-secondary--usage.hbs": "        <h3 class=\"heading-3 font-secondary\">Open Sans</h3>\n        <p class=\"font-secondary\">\n          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut\n          labore et dolore magna aliqua. Libero volutpat sed cras ornare arcu dui. Urna condimentum mattis\n          pellentesque id. Non nisi est sit amet facilisis magna etiam tempor orci.\n        </p>",
    "heading-1--usage.hbs": "        <h2 class=\"heading-1\">Heading 1 Style</h2>",
    "heading-2--usage.hbs": "        <h1 class=\"heading-2\">Heading 2 Style</h1>",
    "heading-3--usage.hbs": "        <h3 class=\"heading-3\">Heading 3 Style</h3>",
    "heading-4--usage.hbs": "        <h6 class=\"heading-4\">Heading 4 Style</h6>",
    "heading-5--usage.hbs": "        <h4 class=\"heading-5\">Heading 5 Style</h4>",
    "heading-6--usage.hbs": "        <h5 class=\"heading-6\">Heading 6 Style</h5>",
    "link-basic--usage.hbs": "        <a href=\"#\">Click Me</a>",
    "link-classes--usage.hbs": "        <a href=\"#\" class=\"m-12 text-lg text-base hover:text-gray-700\">Click Me</a>",
    "link-link-to--usage.hbs": "        <LinkTo @route=\"freestyle\">\n          Click Me\n        </LinkTo>",
    "link-link-to-button--usage.hbs": "        <LinkTo @route=\"freestyle\" class=\"link-button-primary\">\n          Style Guide\n        </LinkTo>",
    "link-primary-button--usage.hbs": "        <a href=\"#\" class=\"link-button-primary\">Click Me</a>",
    "link-secondary-button--usage.hbs": "        <a href=\"#\" class=\"link-button-secondary\">Click Me</a>",
    "radio-basic--usage.hbs": "        <Input::RadioGroup\n          @value={{this.radioValue}}\n          @name=\"foobar\"\n          as |Group|\n        >\n          <Group.Radio\n            @label=\"Foo\"\n            @value=\"foo\"\n          />\n          <Group.Radio\n            @label=\"Bar\"\n            @value=\"bar\"\n          />\n        </Input::RadioGroup>",
    "radio-blocks--usage.hbs": "        <Input::RadioGroup\n          @value={{this.radioValue}}\n          @name=\"foobar\"\n          as |Group|\n        >\n          <Group.Radio\n            @value=\"foo\"\n          >\n            Foo\n          </Group.Radio>\n          <Group.Radio\n            @value=\"bar\"\n          >\n            Bar\n          </Group.Radio>\n        </Input::RadioGroup>",
    "radio-disabled--usage.hbs": "        <Input::RadioGroup\n          @value={{this.radioValue}}\n          @name=\"foobar\"\n          @disabled={{true}}\n          as |Group|\n        >\n          <Group.Radio\n            @label=\"Foo\"\n            @value=\"foo\"\n          />\n          <Group.Radio\n            @label=\"Bar\"\n            @value=\"bar\"\n          />\n        </Input::RadioGroup>",
    "screen-hidden--usage.hbs": "        <div class=\"screen-hidden\">Hidden content</div>",
    "text-lg--usage.hbs": "        <p class=\"text-lg\">\n          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut\n          labore et dolore magna aliqua. Libero volutpat sed cras ornare arcu dui. Urna condimentum mattis\n          pellentesque id. Non nisi est sit amet facilisis magna etiam tempor orci.\n        </p>",
    "text-md--usage.hbs": "        <p class=\"text-md\">\n          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut\n          labore et dolore magna aliqua. Libero volutpat sed cras ornare arcu dui. Urna condimentum mattis\n          pellentesque id. Non nisi est sit amet facilisis magna etiam tempor orci.\n        </p>",
    "text-sm--usage.hbs": "        <p class=\"text-sm\">\n          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut\n          labore et dolore magna aliqua. Libero volutpat sed cras ornare arcu dui. Urna condimentum mattis\n          pellentesque id. Non nisi est sit amet facilisis magna etiam tempor orci.\n        </p>",
    "text-xs--usage.hbs": "        <p class=\"text-xs\">\n          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut\n          labore et dolore magna aliqua. Libero volutpat sed cras ornare arcu dui. Urna condimentum mattis\n          pellentesque id. Non nisi est sit amet facilisis magna etiam tempor orci.\n        </p>",
    "textbox-input-basic--usage.hbs": "        <Input::Textbox\n          @name=\"example\"\n          @label=\"Example Input\"\n          @value={{this.inputTextValue}}\n        />",
    "textbox-input-disabled--usage.hbs": "        <Input::Textbox\n          @name=\"example\"\n          @label=\"Example Input\"\n          @value=\"Howdy, friend!\"\n          @disabled={{true}}\n        />",
    "textbox-input-error--usage.hbs": "        <Input::Textbox\n          @name=\"example\"\n          @label=\"Example Input\"\n          @value={{this.inputTextValue}}\n          @error={{true}}\n          @message=\"Oh no, something is wrong!\"\n        />",
    "textbox-input-full-width--usage.hbs": "        <Input::Textbox\n          @name=\"example\"\n          @label=\"Example Input\"\n          @value={{this.inputTextValue}}\n          @full={{true}}\n        />",
    "textbox-input-hide-label--usage.hbs": "        <Input::Textbox\n          @hideLabel={{true}}\n          @name=\"example\"\n          @value={{this.inputTextValue}}\n          @label=\"Example Input\"\n        />",
    "textbox-input-other-types--usage.hbs": "        <Input::Textbox\n          @name=\"example\"\n          @label=\"Number Input\"\n          @value={{this.inputTextValue}}\n          @placeholder=\"1 to 100\"\n          @type=\"number\"\n          @min=\"1\"\n          @max=\"100\"\n        />",
    "textbox-input-readonly--usage.hbs": "        <Input::Textbox\n          @name=\"example\"\n          @label=\"Example Input\"\n          @value=\"Howdy, friend!\"\n          @readonly={{true}}\n        />",
    "textbox-input-success--usage.hbs": "        <Input::Textbox\n          @name=\"example\"\n          @label=\"Example Input\"\n          @value={{this.inputTextValue}}\n          @success={{true}}\n          @message=\"All is well, go ahead!\"\n        />",
    "textbox-input-with-classes--usage.hbs": "        <Input::Textbox\n          @class=\"m-16\"\n          @name=\"example\"\n          @label=\"Example Input\"\n          @value={{this.inputTextValue}}\n        />",
    "textbox-input-with-icon--usage.hbs": "        <Input::Textbox\n          @name=\"example\"\n          @label=\"Example Input\"\n          @value={{this.inputTextValue}}\n          @icon=\"test\"\n        />",
    "toggle-base--usage.hbs": "        <Toggle\n          @class=\"m-8\"\n          @checked={{this.toggleCheck}}\n          @label=\"Check\"\n        />",
    "toggle-disabled--usage.hbs": "        <Toggle\n          @class=\"m-8\"\n          @checked={{this.toggleCheck}}\n          @label=\"Check\"\n          @disabled={{true}}\n        />",
    "transition-500--usage.hbs": "        <button class=\"transition-500 text-gray-900 p-4 border border-green text-txt\" onClick={{fn this.onTransition}}>Click Me</button>",
    "transition-default--usage.hbs": "        <button class=\"transition text-gray-900 p-4 border border-green text-txt\" onClick={{fn this.onTransition}}>Click Me</button>"
  };
  _exports.default = _default;
});