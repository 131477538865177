define("incubex/components/cancel-all-orders-confirmation", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{#modal-dialog onClickOverlay= (action this.args.onClose )}}
    <div>
      <h1 class="text-lg ml-8 mt-8 mb-8 font-bold text-base">Cancel All Confirmation</h1>
    </div>
    <label class="ml-8 text-base">Are you sure you want to cancel all working orders?</label>
    <div class="mt-16 flex flex-row justify-end">
      <Button
        @style="link"
        @class="m-8 text-base font-bold mr-24"
        @onClick={{this.args.onClose}}
        @id="close"
      >
        No, go back
      </Button>
      <Button
        @class="m-8 bg-red"
        @id="cancel-all-orders"
        @onClick={{this.args.cancelAllOrders}}
      >
        Yes, Cancel All
      </Button>
    </div>
  {{/modal-dialog}}
  
  */
  {
    id: "BpUYQb7H",
    block: "{\"symbols\":[],\"statements\":[[4,\"modal-dialog\",null,[[\"onClickOverlay\"],[[28,\"action\",[[23,0,[]],[23,0,[\"args\",\"onClose\"]]],null]]],{\"statements\":[[0,\"  \"],[7,\"div\",true],[8],[0,\"\\n    \"],[7,\"h1\",true],[10,\"class\",\"text-lg ml-8 mt-8 mb-8 font-bold text-base\"],[8],[0,\"Cancel All Confirmation\"],[9],[0,\"\\n  \"],[9],[0,\"\\n  \"],[7,\"label\",true],[10,\"class\",\"ml-8 text-base\"],[8],[0,\"Are you sure you want to cancel all working orders?\"],[9],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"mt-16 flex flex-row justify-end\"],[8],[0,\"\\n    \"],[5,\"button\",[],[[\"@style\",\"@class\",\"@onClick\",\"@id\"],[\"link\",\"m-8 text-base font-bold mr-24\",[23,0,[\"args\",\"onClose\"]],\"close\"]],{\"statements\":[[0,\"\\n      No, go back\\n    \"]],\"parameters\":[]}],[0,\"\\n    \"],[5,\"button\",[],[[\"@class\",\"@id\",\"@onClick\"],[\"m-8 bg-red\",\"cancel-all-orders\",[23,0,[\"args\",\"cancelAllOrders\"]]]],{\"statements\":[[0,\"\\n      Yes, Cancel All\\n    \"]],\"parameters\":[]}],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    meta: {
      moduleName: "incubex/components/cancel-all-orders-confirmation.hbs"
    }
  });

  class CancelAllOrdersConfirmationComponent extends _component.default {
    constructor() {
      super(...arguments);
    }

  }

  _exports.default = CancelAllOrdersConfirmationComponent;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, CancelAllOrdersConfirmationComponent);
});