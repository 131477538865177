define("incubex/components/instruments-collection", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  let InstrumentsCollection = (_class = class InstrumentsCollection extends Ember.Component {
    addHandlers(element) {
      let role = element.getAttribute('role');

      if (role === 'group') {
        return;
      }

      let findOptionAndPerform = (action, select, e) => {
        if (e.target === null) return;
        let optionItem = e.target.closest('[data-option-index]');

        if (!optionItem) {
          return;
        }

        if (optionItem.closest('[aria-disabled=true]')) {
          return; // Abort if the item or an ancestor is disabled
        }

        let optionIndex = optionItem.getAttribute('data-option-index');
        if (optionIndex === null) return;
        action(this._optionFromIndex(optionIndex), select, e);
      };

      element.addEventListener('mouseup', e => findOptionAndPerform(this.select.actions.choose, this.select, e));

      if (this.highlightOnHover) {
        element.addEventListener('mouseover', e => findOptionAndPerform(this.select.actions.highlight, this.select, e));
      }

      if (this.isTouchDevice) {
        let touchMoveHandler = () => {
          this.hasMoved = true;

          if (element) {
            element.removeEventListener('touchmove', touchMoveHandler);
          }
        }; // Add touch event handlers to detect taps


        element.addEventListener('touchstart', () => {
          element.addEventListener('touchmove', touchMoveHandler);
        });
        element.addEventListener('touchend', e => {
          if (e.target === null) return;
          let optionItem = e.target.closest('[data-option-index]');
          if (optionItem === null) return;
          e.preventDefault();

          if (this.hasMoved) {
            this.hasMoved = false;
            return;
          }

          if (optionItem.closest('[aria-disabled=true]')) {
            return; // Abort if the item or an ancestor is disabled
          }

          let optionIndex = optionItem.getAttribute('data-option-index');
          if (optionIndex === null) return;
          this.select.actions.choose(this._optionFromIndex(optionIndex), e);
        });
      }

      if (role !== 'group') {
        this.select.actions.scrollTo(this.select.highlighted);
      }
    }

    _optionFromIndex(index) {
      let parts = index.split('.');
      let option = this.options[parseInt(parts[0], 10)];

      for (let i = 1; i < parts.length; i++) {
        option = option.options[parseInt(parts[i], 10)];
      }

      return option;
    }

  }, (_applyDecoratedDescriptor(_class.prototype, "addHandlers", [Ember._action], Object.getOwnPropertyDescriptor(_class.prototype, "addHandlers"), _class.prototype)), _class);
  _exports.default = InstrumentsCollection;
});