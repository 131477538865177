define("incubex/templates/components/x-tabs-tabs", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "6f8jI7I2",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[7,\"ul\",true],[8],[0,\"\\n  \"],[14,1,[[28,\"hash\",null,[[\"api\",\"tab\"],[[24,[\"api\"]],[28,\"component\",[\"x-tabs-tab\"],[[\"api\",\"ownerName\"],[[24,[\"api\"]],[24,[\"ownerName\"]]]]]]]]]],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "incubex/templates/components/x-tabs-tabs.hbs"
    }
  });

  _exports.default = _default;
});