define("incubex/routes/password-update", ["exports", "incubex/routes/unauthenticated"], function (_exports, _unauthenticated) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class, _descriptor, _descriptor2, _descriptor3, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let PasswordUpdateRoute = (_class = (_temp = class PasswordUpdateRoute extends _unauthenticated.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "passwordManager", _descriptor, this);

      _initializerDefineProperty(this, "notification", _descriptor2, this);

      _initializerDefineProperty(this, "api", _descriptor3, this);
    }

    model() {
      this.passwordManager.loadToken();
    }

    async redirect() {
      if (!this.passwordManager.token) {
        this.notification.info('We could not find your reset password token.');
        this.transitionTo('login');
      }
    }

    async didTransition() {
      try {
        await this.passwordManager.requestResetPasswordOTP();
      } catch (error) {
        this.notification.error(error.errors || error);
      }
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "passwordManager", [Ember.inject.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "notification", [Ember.inject.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "api", [Ember.inject.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "didTransition", [Ember._action], Object.getOwnPropertyDescriptor(_class.prototype, "didTransition"), _class.prototype)), _class);
  _exports.default = PasswordUpdateRoute;
});