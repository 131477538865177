define("incubex/services/notification", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  class NotificationService extends Ember.Service {
    constructor() {
      super(...arguments);
      this.messages = [];
      this.toasts = [];
      this.delay = {
        auto: 2500,
        close: 250
      };
    }

    addMessage(text, type, options = {}) {
      let message = {
        text,
        type,
        options,
        isDeleted: false
      };
      this.messages.insertAt(0, message);
    }

    removeMessage(message) {
      this.messages.removeObject(message);

      const newMsg = _objectSpread({}, message, {
        isDeleted: true
      });

      this.messages.insertAt(0, newMsg);
    }

    addToast(text, options = {}) {
      let toast = {
        text,
        options
      };
      this.toasts.pushObject(toast);
    }

    removeToast(toast) {
      this.toasts.removeObject(toast);
    }

    clearAllMessages() {
      this.messages.clear();
    }

    clearErrors() {
      let errors = this.messages.filterBy('type', 'error');
      this.messages.removeObjects(errors);
    }

    error(error, options = {
      countdown: false,
      closeHandler: null,
      context: null
    }) {
      error = error.message || error;
      this.addMessage(error, 'error', options);
    }

    success(text, options = {
      countdown: true,
      closeHandler: null,
      context: null
    }) {
      this.addMessage(text, 'success', options);
    }

    info(text, options = {
      countdown: true,
      closeHandler: null,
      context: null
    }) {
      this.addMessage(text, 'info', options);
    }

    toast(text, options = {
      countdown: false,
      closeHandler: null,
      context: null
    }) {
      this.addToast(text, options);
    }

    serviceError(error, source, options = {
      countdown: false,
      closeHandler: null,
      context: null
    }) {
      const errorMsg = error.message || error;
      let message = {
        text: errorMsg,
        type: "error",
        options,
        source: source,
        isDeleted: false
      };
      this.messages.insertAt(0, message);
    }

  }

  _exports.default = NotificationService;
});