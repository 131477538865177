define("incubex/components/risk-positions-table", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class, _descriptor, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <EmberTable @name="risk-positions-table" @class="risk-positions-table" as |t|>
    <t.head @columns={{this.columns}} @class="risk-positions-table-header" @enableReorder={{false}} @sorts={{this.sorts}}
      @onUpdateSorts={{action this.onSort}} as |h|>
      <h.row as |r|>
        <r.cell as |column columnMeta|>
          <div class="flex justify-between">
            {{column.name}}
            <div class="mr-8">
              <EmberTh::SortIndicator @columnMeta={{columnMeta}} />
            </div>
  
          </div>
          <EmberTh::ResizeHandle @columnMeta={{columnMeta}} />
        </r.cell>
      </h.row>
    </t.head>
    <t.body @rows={{this.rows}} @renderAll={{true}} as |b|>
      <b.row as |r|>
        <r.cell @class="nowrap" as |value|>
          {{value}}
        </r.cell>
      </b.row>
    </t.body>
  </EmberTable>
  */
  {
    id: "SWfPaioT",
    block: "{\"symbols\":[\"t\",\"b\",\"r\",\"value\",\"h\",\"r\",\"column\",\"columnMeta\"],\"statements\":[[5,\"ember-table\",[],[[\"@name\",\"@class\"],[\"risk-positions-table\",\"risk-positions-table\"]],{\"statements\":[[0,\"\\n  \"],[6,[23,1,[\"head\"]],[],[[\"@columns\",\"@class\",\"@enableReorder\",\"@sorts\",\"@onUpdateSorts\"],[[23,0,[\"columns\"]],\"risk-positions-table-header\",false,[23,0,[\"sorts\"]],[28,\"action\",[[23,0,[]],[23,0,[\"onSort\"]]],null]]],{\"statements\":[[0,\"\\n    \"],[6,[23,5,[\"row\"]],[],[[],[]],{\"statements\":[[0,\"\\n      \"],[6,[23,6,[\"cell\"]],[],[[],[]],{\"statements\":[[0,\"\\n        \"],[7,\"div\",true],[10,\"class\",\"flex justify-between\"],[8],[0,\"\\n          \"],[1,[23,7,[\"name\"]],false],[0,\"\\n          \"],[7,\"div\",true],[10,\"class\",\"mr-8\"],[8],[0,\"\\n            \"],[5,\"ember-th/sort-indicator\",[],[[\"@columnMeta\"],[[23,8,[]]]]],[0,\"\\n          \"],[9],[0,\"\\n\\n        \"],[9],[0,\"\\n        \"],[5,\"ember-th/resize-handle\",[],[[\"@columnMeta\"],[[23,8,[]]]]],[0,\"\\n      \"]],\"parameters\":[7,8]}],[0,\"\\n    \"]],\"parameters\":[6]}],[0,\"\\n  \"]],\"parameters\":[5]}],[0,\"\\n  \"],[6,[23,1,[\"body\"]],[],[[\"@rows\",\"@renderAll\"],[[23,0,[\"rows\"]],true]],{\"statements\":[[0,\"\\n    \"],[6,[23,2,[\"row\"]],[],[[],[]],{\"statements\":[[0,\"\\n      \"],[6,[23,3,[\"cell\"]],[],[[\"@class\"],[\"nowrap\"]],{\"statements\":[[0,\"\\n        \"],[1,[23,4,[]],false],[0,\"\\n      \"]],\"parameters\":[4]}],[0,\"\\n    \"]],\"parameters\":[3]}],[0,\"\\n  \"]],\"parameters\":[2]}],[0,\"\\n\"]],\"parameters\":[1]}]],\"hasEval\":false}",
    meta: {
      moduleName: "incubex/components/risk-positions-table.hbs"
    }
  });

  let RiskPositionsTableComponent = (_class = (_temp = class RiskPositionsTableComponent extends _component.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "sorts", _descriptor, this);
    }

    get columns() {
      return [{
        name: '',
        subcolumns: [{
          name: 'Exchange',
          valuePath: 'exchange'
        }]
      }, {
        name: '',
        subcolumns: [{
          name: 'Product Group',
          valuePath: 'group'
        }]
      }, {
        name: '',
        subcolumns: [{
          name: 'Product Sub-Group',
          valuePath: 'sub_group'
        }]
      }, {
        name: '',
        subcolumns: [{
          name: 'Product Name',
          valuePath: 'instrument_description',
          width: 230
        }]
      }, {
        name: 'Actual Position',
        subcolumns: [{
          name: 'Net',
          valuePath: 'actual_position',
          width: 75
        }]
      }, {
        name: 'Working Orders',
        subcolumns: [{
          name: 'Long',
          valuePath: 'long_working_order_count'
        }, {
          name: 'Short',
          valuePath: 'short_working_order_count',
          width: 50
        }]
      }, {
        name: 'Risk Position',
        subcolumns: [{
          name: 'Long',
          valuePath: 'long_position'
        }, {
          name: 'Short',
          valuePath: 'short_position',
          width: 50
        }]
      }, {
        name: 'Risk Limits',
        subcolumns: [{
          name: 'Long',
          valuePath: 'long_risk_limit'
        }, {
          name: 'Short',
          valuePath: 'short_risk_limit',
          width: 50
        }]
      }, {
        name: '% of Risk Limits Used by Risk Position',
        subcolumns: [{
          name: 'Long',
          valuePath: 'long_position_risk_limit_percentage'
        }, {
          name: 'Short',
          valuePath: 'short_position_risk_limit_percentage',
          width: 50
        }]
      }, {
        name: 'Alert Threshold Percentages',
        subcolumns: [{
          name: 'Threshold 1',
          valuePath: 'alert_threshold1_percentage',
          width: 160
        }, {
          name: 'Threshold 2',
          valuePath: 'alert_threshold2_percentage'
        }]
      }];
    }

    get rows() {
      return this.args.rows;
    }

    onSort(args1) {
      this.sorts = [...args1];
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "sorts", [Ember._tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return [];
    }
  }), _applyDecoratedDescriptor(_class.prototype, "onSort", [Ember._action], Object.getOwnPropertyDescriptor(_class.prototype, "onSort"), _class.prototype)), _class);
  _exports.default = RiskPositionsTableComponent;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, RiskPositionsTableComponent);
});