define("incubex/templates/onboard/complete", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ypNKSQ0+",
    "block": "{\"symbols\":[],\"statements\":[[5,\"layout/logged-out\",[],[[\"@pageTitle\"],[\"Welcome aboard!\"]],{\"statements\":[[0,\"\\n  \"],[1,[28,\"svg-jar\",[\"check-circle\"],[[\"class\",\"height\",\"width\"],[\"fill-system-success\",\"40\",\"40\"]]],false],[0,\"\\n\\n  \"],[7,\"p\",true],[10,\"class\",\"heading-5 mt-24\"],[8],[0,\"Thanks for completing your onboarding\"],[9],[0,\"\\n  \"],[7,\"p\",true],[10,\"class\",\"mt-16 mb-40\"],[8],[0,\"You can now login to the IncubEx platform.\"],[9],[0,\"\\n\\n  \"],[5,\"link-to\",[],[[\"@route\",\"@class\"],[\"login\",\"link-button-primary\"]],{\"statements\":[[0,\"\\n    Go to Login\\n  \"]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[]}],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "incubex/templates/onboard/complete.hbs"
    }
  });

  _exports.default = _default;
});