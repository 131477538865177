define("incubex/components/x-tree-collapsed-icon", ["exports", "incubex/components/x-tree-collapsed-icon"], function (_exports, _xTreeCollapsedIcon) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
     {{svg-jar "expand" class="collapse-icon" width="14" height="14"}}
  */
  {
    id: "AXzZ1RA2",
    block: "{\"symbols\":[],\"statements\":[[0,\" \"],[1,[28,\"svg-jar\",[\"expand\"],[[\"class\",\"width\",\"height\"],[\"collapse-icon\",\"14\",\"14\"]]],false]],\"hasEval\":false}",
    meta: {
      moduleName: "incubex/components/x-tree-collapsed-icon.hbs"
    }
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember.Component.extend({
    layout: _xTreeCollapsedIcon.default
  }));

  _exports.default = _default;
});