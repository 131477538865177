define("incubex/services/api", ["exports", "fetch", "ember-get-config", "camelcase-keys-deep", "decamelize-keys-deep"], function (_exports, _fetch, _emberGetConfig, _camelcaseKeysDeep, _decamelizeKeysDeep) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class, _descriptor, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const HEADER_BASE = Object.freeze({
    Accept: 'application/json',
    'Content-Type': 'application/json'
  });
  const {
    host
  } = _emberGetConfig.default;
  let ApiService = (_class = (_temp = class ApiService extends Ember.Service {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "session", _descriptor, this);
    }

    async get(url, headers = {}, options = {
      camelize: true
    }) {
      url = host + url;
      let response = await (0, _fetch.default)(url, {
        method: 'get',
        headers: Ember.assign({}, HEADER_BASE, headers)
      });
      let text = await response.text();
      let responseData = text ? JSON.parse(text) : {};

      if (response.ok) {
        if (options.camelize) {
          responseData = (0, _camelcaseKeysDeep.default)(responseData);
        }

        return responseData;
      } else {
        this.checkLogOut(response);
        throw Error(responseData.errors || responseData.error || 'An error occured fetching data.');
      }
    }

    async post(url, data, headers = {}, options = {
      camelize: true
    }) {
      url = host + url;

      if (options.camelize) {
        data = (0, _decamelizeKeysDeep.default)(data);
      }

      let response = await (0, _fetch.default)(url, {
        method: 'post',
        headers: Ember.assign({}, HEADER_BASE, headers),
        body: JSON.stringify(data)
      });
      let text = await response.text();
      let responseData = text ? JSON.parse(text) : {};

      if (response.ok) {
        if (options.camelize) {
          responseData = (0, _camelcaseKeysDeep.default)(responseData);
        }

        return responseData;
      } else {
        this.checkLogOut(response);
        throw Error(responseData.errors || responseData.error || 'An error occured posting data.');
      }
    }

    async put(url, data, headers = {}, options = {
      camelize: true
    }) {
      url = host + url;

      if (options.camelize) {
        data = (0, _decamelizeKeysDeep.default)(data);
      }

      let response = await (0, _fetch.default)(url, {
        method: 'put',
        headers: Ember.assign({}, HEADER_BASE, headers),
        body: JSON.stringify(data)
      });
      let text = await response.text();
      let responseData = text ? JSON.parse(text) : {};

      if (response.ok) {
        if (options.camelize) {
          responseData = (0, _decamelizeKeysDeep.default)(responseData);
        }

        return responseData;
      } else {
        this.checkLogOut(response);
        throw Error(responseData.errors || responseData.error || 'An error occured patching data.');
      }
    }

    async delete(url, headers = {}, options = {
      camelize: true
    }) {
      url = host + url;
      let response = await (0, _fetch.default)(url, {
        method: 'delete',
        headers: Ember.assign({}, HEADER_BASE, headers)
      });
      let text = await response.text();
      let responseData = text ? JSON.parse(text) : {};

      if (response.ok) {
        if (options.camelize) {
          responseData = (0, _camelcaseKeysDeep.default)(responseData);
        }

        return responseData;
      } else {
        this.checkLogOut(response);
        throw Error(responseData.errors || responseData.error || 'An error occured deleting resource.');
      }
    }

    async patch(url, data, headers = {}, options = {
      camelize: true
    }) {
      url = host + url;

      if (options.camelize) {
        data = (0, _decamelizeKeysDeep.default)(data);
      }

      let response = await (0, _fetch.default)(url, {
        method: 'patch',
        headers: Ember.assign({}, HEADER_BASE, headers),
        body: JSON.stringify(data)
      });
      let text = await response.text();
      let responseData = text ? JSON.parse(text) : {};

      if (response.ok) {
        if (options.camelize) {
          responseData = (0, _decamelizeKeysDeep.default)(responseData);
        }

        return responseData;
      } else {
        this.checkLogOut(response);
        throw Error(responseData.errors || responseData.error || 'An error occured patching data.');
      }
    }

    async post2(url, data, headers = {}, options = {
      camelize: true
    }) {
      url = host + url;

      if (options.camelize) {
        data = (0, _decamelizeKeysDeep.default)(data);
      }

      let response = await (0, _fetch.default)(url, {
        method: 'post',
        headers: Ember.assign({}, HEADER_BASE, headers),
        body: JSON.stringify(data)
      });

      if (response.ok) {
        return response;
      } else {
        this.checkLogOut(response);
        throw Error('An error occured posting data.');
      }
    }

    url(_url) {
      return host + _url;
    }

    checkLogOut(response) {
      if (response.status == 401) {
        this.session.invalidate();
      }
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "session", [Ember.inject.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class);
  _exports.default = ApiService;
});