define("incubex/components/x-tab/pane", ["exports", "incubex/templates/components/x-tab/pane", "incubex/mixins/component-child"], function (_exports, _pane, _componentChild) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_componentChild.default, {
    layout: _pane.default,
    classNameBindings: ['contentCurrent'],
    tagName: 'section',
    activeId: null,
    isActive: Ember.computed('activeId', 'elementId', function () {
      return this.get('activeId') === this.get('elementId');
    }).readOnly(),
    contentCurrent: true,

    show() {
      this.set('contentCurrent', true);
    },

    hide() {
      this.set('contentCurrent', false);
    },

    _showHide: Ember.observer('isActive', function () {
      if (this.get('isActive')) {
        this.show();
      } else {
        this.hide();
      }
    }),

    init() {
      this._super(...arguments);

      Ember.run.scheduleOnce('afterRender', this, function () {
        this.set('contentCurrent', this.get('isActive'));
      });
    }

  });

  _exports.default = _default;
});