define("incubex/components/pagination", ["exports", "@glimmer/component", "jquery"], function (_exports, _component, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class="flex" {{did-update this.onUpdate}}>
    <Button @disabled={{this.prevBtnDisabled}} @onClick={{action this.onPrevious}} @style="link"  @class="text-sm mr-16">Previous</Button>
    <select {{did-update this.onUpdate this.args.paginationData}} disabled={{not this.args.paginationData}} id="pagination-select" class="pagination-select" onchange={{action this.onPageSelect}} >
      {{#each this.pages as |page| }}
        {{#if (eq page this.args.paginationData.current_page) }}
         <option selected>{{page}}</option>
        {{else}}
          <option>{{page}}</option>
        {{/if}}
      {{/each}}
    </select>
    <Button @disabled={{this.nextBtnDisabled}} @onClick={{action this.onNext}} @style="link"@class="text-sm ml-16" >Next</Button>
  </div>
  */
  {
    id: "TrV2Yi4Q",
    block: "{\"symbols\":[\"page\"],\"statements\":[[7,\"div\",false],[12,\"class\",\"flex\"],[3,\"did-update\",[[23,0,[\"onUpdate\"]]]],[8],[0,\"\\n  \"],[5,\"button\",[],[[\"@disabled\",\"@onClick\",\"@style\",\"@class\"],[[23,0,[\"prevBtnDisabled\"]],[28,\"action\",[[23,0,[]],[23,0,[\"onPrevious\"]]],null],\"link\",\"text-sm mr-16\"]],{\"statements\":[[0,\"Previous\"]],\"parameters\":[]}],[0,\"\\n  \"],[7,\"select\",false],[12,\"disabled\",[28,\"not\",[[23,0,[\"args\",\"paginationData\"]]],null]],[12,\"id\",\"pagination-select\"],[12,\"class\",\"pagination-select\"],[12,\"onchange\",[28,\"action\",[[23,0,[]],[23,0,[\"onPageSelect\"]]],null]],[3,\"did-update\",[[23,0,[\"onUpdate\"]],[23,0,[\"args\",\"paginationData\"]]]],[8],[0,\"\\n\"],[4,\"each\",[[23,0,[\"pages\"]]],null,{\"statements\":[[4,\"if\",[[28,\"eq\",[[23,1,[]],[23,0,[\"args\",\"paginationData\",\"current_page\"]]],null]],null,{\"statements\":[[0,\"       \"],[7,\"option\",true],[10,\"selected\",\"\"],[8],[1,[23,1,[]],false],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"        \"],[7,\"option\",true],[8],[1,[23,1,[]],false],[9],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[1]},null],[0,\"  \"],[9],[0,\"\\n  \"],[5,\"button\",[],[[\"@disabled\",\"@onClick\",\"@style\",\"@class\"],[[23,0,[\"nextBtnDisabled\"]],[28,\"action\",[[23,0,[]],[23,0,[\"onNext\"]]],null],\"link\",\"text-sm ml-16\"]],{\"statements\":[[0,\"Next\"]],\"parameters\":[]}],[0,\"\\n\"],[9]],\"hasEval\":false}",
    meta: {
      moduleName: "incubex/components/pagination.hbs"
    }
  });
  /* eslint-disable ember/no-jquery */


  let PaginationComponent = (_class = class PaginationComponent extends _component.default {
    get pages() {
      let _pages = [];
      if (this.args.paginationData) for (let i = 1; i <= this.args.paginationData.total_pages; i++) {
        _pages.push(i);
      }
      return _pages;
    }

    get prevBtnDisabled() {
      if (!this.args.paginationData) return true;
      if (!this.args.paginationData.prev_page) return true;
      return false;
    }

    get nextBtnDisabled() {
      if (!this.args.paginationData) return true;
      if (!this.args.paginationData.next_page) return true;
      return false;
    }

    onPageSelect(event) {
      if (this.args.onPageChange) {
        this.args.onPageChange(event.currentTarget.value);
      }
    }

    onPrevious() {
      if (this.args.onPageChange) {
        this.args.onPageChange(this.args.paginationData.prev_page);
      }
    }

    onNext() {
      if (this.args.onPageChange) {
        this.args.onPageChange(this.args.paginationData.next_page);
      }
    }

    onUpdate() {
      let select = (0, _jquery.default)('#pagination-select');
      (0, _jquery.default)(select[0]).val(this.args.paginationData.current_page);
    }

  }, (_applyDecoratedDescriptor(_class.prototype, "onPageSelect", [Ember._action], Object.getOwnPropertyDescriptor(_class.prototype, "onPageSelect"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onPrevious", [Ember._action], Object.getOwnPropertyDescriptor(_class.prototype, "onPrevious"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onNext", [Ember._action], Object.getOwnPropertyDescriptor(_class.prototype, "onNext"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onUpdate", [Ember._action], Object.getOwnPropertyDescriptor(_class.prototype, "onUpdate"), _class.prototype)), _class);
  _exports.default = PaginationComponent;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, PaginationComponent);
});