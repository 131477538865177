define("incubex/services/session-api", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class, _descriptor, _descriptor2, _temp;

  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let SessionApiService = (_class = (_temp = class SessionApiService extends Ember.Service {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "api", _descriptor, this);

      _initializerDefineProperty(this, "session", _descriptor2, this);
    }

    async get(url, headers = {}, options = {}) {
      let header = {
        Authorization: this.sessionAuthHeader
      };
      return this.api.get(url, _objectSpread({}, headers, {}, header), options);
    }

    async post(url, data, headers = {}, options = {}) {
      let header = {
        Authorization: this.sessionAuthHeader
      };
      return this.api.post(url, data, _objectSpread({}, headers, {}, header), options);
    }

    async post2(url, data, headers = {}, options = {}) {
      let header = {
        Authorization: this.sessionAuthHeader
      };
      return this.api.post2(url, data, _objectSpread({}, headers, {}, header), options);
    }

    async put(url, data, headers = {}, options = {}) {
      let header = {
        Authorization: this.sessionAuthHeader
      };
      return this.api.put(url, data, _objectSpread({}, headers, {}, header), options);
    }

    async delete(url, headers = {}, options = {
      camelize: true
    }) {
      let header = {
        Authorization: this.sessionAuthHeader
      };
      return this.api.delete(url, _objectSpread({}, headers, {}, header), options);
    }

    async patch(url, data, headers = {}, options = {}) {
      let header = {
        Authorization: this.sessionAuthHeader
      };
      return this.api.patch(url, data, _objectSpread({}, headers, {}, header), options);
    }

    get sessionAuthHeader() {
      return "".concat(this.session.data.authenticated.token_type, " ").concat(this.session.data.authenticated.auth_token);
    }

    get sessionAuthToken() {
      return "".concat(this.session.data.authenticated.auth_token);
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "api", [Ember.inject.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "session", [Ember.inject.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class);
  _exports.default = SessionApiService;
});