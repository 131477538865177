define("incubex/templates/onboard/two-factor/recovery-codes", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "5nFzkad5",
    "block": "{\"symbols\":[\"code\"],\"statements\":[[5,\"layout/logged-out\",[],[[\"@pageTitle\"],[\"Two-Factor Authentication\"]],{\"statements\":[[0,\"\\n  \"],[7,\"form\",true],[10,\"method\",\"POST\"],[11,\"onsubmit\",[28,\"action\",[[23,0,[]],[23,0,[\"downloadRecoveryCodes\"]]],null]],[8],[0,\"\\n    \"],[7,\"div\",true],[8],[0,\"  \\n      \"],[7,\"p\",true],[10,\"class\",\"mt-16 text-base \"],[8],[0,\"Your Recovery Codes\"],[9],[0,\"\\n      \"],[7,\"p\",true],[10,\"class\",\"mt-24\"],[8],[0,\"Recovery codes are used to access your account in the event you cannot receive two-factor authentication codes.\"],[9],[0,\"\\n      \"],[7,\"p\",true],[10,\"class\",\"mt-16 text-base \"],[8],[0,\"Download your recovery codes before going to the next step.\"],[9],[0,\"\\n    \"],[9],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"mt-24\"],[8],[0,\"\\n\"],[4,\"each\",[[23,0,[\"codes\"]]],null,{\"statements\":[[0,\"        \"],[7,\"div\",true],[8],[1,[23,1,[]],false],[9],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"    \"],[9],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"flex justify-between items-center mt-24\"],[8],[0,\"\\n      \"],[5,\"button\",[],[[\"@name\",\"@style\",\"@type\"],[\"recoveryCodes\",\"link\",\"submit\"]],{\"statements\":[[0,\"\\n        Download recovery codes\\n      \"]],\"parameters\":[]}],[0,\"\\n\\n      \"],[5,\"button\",[],[[\"@name\",\"@onClick\"],[\"continue\",[28,\"action\",[[23,0,[]],[23,0,[\"onContinue\"]]],null]]],{\"statements\":[[0,\"\\n        Continue\\n      \"]],\"parameters\":[]}],[0,\"\\n    \"],[9],[0,\"\\n    \"],[7,\"input\",true],[10,\"class\",\"invisible\"],[10,\"name\",\"codes\"],[11,\"value\",[23,0,[\"codes\"]]],[8],[9],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "incubex/templates/onboard/two-factor/recovery-codes.hbs"
    }
  });

  _exports.default = _default;
});