define("incubex/utils/ui-settings", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.setDefaultUiSettingsForColumns = void 0;

  const setDefaultUiSettingsForColumns = ui_settings => {
    if (!ui_settings) {
      ui_settings = '{}';
    }

    ui_settings = JSON.parse(ui_settings);
    let columns = {};

    if (!ui_settings.columns) {
      ui_settings.columns = columns;
    } else {
      columns = ui_settings.columns;
    }

    if (!columns.productName) {
      columns.productName = {
        visible: true
      };
    }

    if (!columns.productDescription) {
      columns.productDescription = {
        visible: false
      };
    }

    if (!columns.productDetails) {
      columns.productDetails = {
        visible: false
      };
    }

    if (!columns.productCode) {
      columns.productCode = {
        visible: false
      };
    }

    if (!columns.productGroup) {
      columns.productGroup = {
        visible: false
      };
    }

    if (!columns.productSubGroup) {
      columns.productSubGroup = {
        visible: false
      };
    }

    if (!columns.vintage) {
      columns.vintage = {
        visible: false
      };
    }

    if (!columns.expiration) {
      columns.expiration = {
        visible: true
      };
    }

    if (!columns.expirationDate) {
      columns.expirationDate = {
        visible: false
      };
    }

    if (!columns.exchange) {
      columns.exchange = {
        visible: false
      };
    }

    if (!columns.contractStatus) {
      columns.contractStatus = {
        visible: false
      };
    }

    if (!columns.tradingStatus) {
      columns.tradingStatus = {
        visible: false
      };
    }

    if (!columns.volume) {
      columns.volume = {
        visible: true
      };
    }

    if (!columns.openInterest) {
      columns.openInterest = {
        visible: false
      };
    }

    if (!columns.hit) {
      columns.hit = {
        visible: true
      };
    }

    if (!columns.bidQty) {
      columns.bidQty = {
        visible: true
      };
    }

    if (!columns.bid) {
      columns.bid = {
        visible: true
      };
    }

    if (!columns.ask) {
      columns.ask = {
        visible: true
      };
    }

    if (!columns.askQty) {
      columns.askQty = {
        visible: true
      };
    }

    if (!columns.lift) {
      columns.lift = {
        visible: true
      };
    }

    if (!columns.last) {
      columns.last = {
        visible: true
      };
    }

    if (!columns.lastQty) {
      columns.lastQty = {
        visible: true
      };
    }

    if (!columns.change) {
      columns.change = {
        visible: true
      };
    }

    if (!columns.percentageChange) {
      columns.percentageChange = {
        visible: true
      };
    }

    if (!columns.priorSettle) {
      columns.priorSettle = {
        visible: false
      };
    }

    if (!columns.open) {
      columns.open = {
        visible: false
      };
    }

    if (!columns.close) {
      columns.close = {
        visible: false
      };
    }

    if (!columns.high) {
      columns.high = {
        visible: false
      };
    }

    if (!columns.low) {
      columns.low = {
        visible: false
      };
    }

    return ui_settings;
  };

  _exports.setDefaultUiSettingsForColumns = setDefaultUiSettingsForColumns;
});