define("incubex/grpc_web/api/health/v1/health_api_grpc_web_pb", [], function () {
  "use strict";

  /**
   * @fileoverview gRPC-Web generated client stub for api.health.v1
   * @enhanceable
   * @public
   */
  // Code generated by protoc-gen-grpc-web. DO NOT EDIT.
  // versions:
  // 	protoc-gen-grpc-web v1.4.2
  // 	protoc              v0.0.0
  // source: api/health/v1/health_api.proto

  /* eslint-disable */
  // @ts-nocheck
  const grpc = {};
  grpc.web = require("grpc-web");
  const proto = {};
  proto.api = {};
  proto.api.health = {};
  proto.api.health.v1 = require("incubex/grpc_web/api/health/v1/health_api_pb.js");
  /**
   * @param {string} hostname
   * @param {?Object} credentials
   * @param {?grpc.web.ClientOptions} options
   * @constructor
   * @struct
   * @final
   */

  proto.api.health.v1.HealthAPIClient = function (hostname, credentials, options) {
    if (!options) options = {};
    options.format = 'text';
    /**
     * @private @const {!grpc.web.GrpcWebClientBase} The client
     */

    this.client_ = new grpc.web.GrpcWebClientBase(options);
    /**
     * @private @const {string} The hostname
     */

    this.hostname_ = hostname.replace(/\/+$/, '');
  };
  /**
   * @param {string} hostname
   * @param {?Object} credentials
   * @param {?grpc.web.ClientOptions} options
   * @constructor
   * @struct
   * @final
   */


  proto.api.health.v1.HealthAPIPromiseClient = function (hostname, credentials, options) {
    if (!options) options = {};
    options.format = 'text';
    /**
     * @private @const {!grpc.web.GrpcWebClientBase} The client
     */

    this.client_ = new grpc.web.GrpcWebClientBase(options);
    /**
     * @private @const {string} The hostname
     */

    this.hostname_ = hostname.replace(/\/+$/, '');
  };
  /**
   * @const
   * @type {!grpc.web.MethodDescriptor<
   *   !proto.api.health.v1.HealthCheckRequest,
   *   !proto.api.health.v1.HealthCheckResponse>}
   */


  const methodDescriptor_HealthAPI_Check = new grpc.web.MethodDescriptor('/api.health.v1.HealthAPI/Check', grpc.web.MethodType.UNARY, proto.api.health.v1.HealthCheckRequest, proto.api.health.v1.HealthCheckResponse,
  /**
   * @param {!proto.api.health.v1.HealthCheckRequest} request
   * @return {!Uint8Array}
   */
  function (request) {
    return request.serializeBinary();
  }, proto.api.health.v1.HealthCheckResponse.deserializeBinary);
  /**
   * @param {!proto.api.health.v1.HealthCheckRequest} request The
   *     request proto
   * @param {?Object<string, string>} metadata User defined
   *     call metadata
   * @param {function(?grpc.web.RpcError, ?proto.api.health.v1.HealthCheckResponse)}
   *     callback The callback function(error, response)
   * @return {!grpc.web.ClientReadableStream<!proto.api.health.v1.HealthCheckResponse>|undefined}
   *     The XHR Node Readable Stream
   */

  proto.api.health.v1.HealthAPIClient.prototype.check = function (request, metadata, callback) {
    return this.client_.rpcCall(this.hostname_ + '/api.health.v1.HealthAPI/Check', request, metadata || {}, methodDescriptor_HealthAPI_Check, callback);
  };
  /**
   * @param {!proto.api.health.v1.HealthCheckRequest} request The
   *     request proto
   * @param {?Object<string, string>=} metadata User defined
   *     call metadata
   * @return {!Promise<!proto.api.health.v1.HealthCheckResponse>}
   *     Promise that resolves to the response
   */


  proto.api.health.v1.HealthAPIPromiseClient.prototype.check = function (request, metadata) {
    return this.client_.unaryCall(this.hostname_ + '/api.health.v1.HealthAPI/Check', request, metadata || {}, methodDescriptor_HealthAPI_Check);
  };
  /**
   * @const
   * @type {!grpc.web.MethodDescriptor<
   *   !proto.api.health.v1.HealthCheckRequest,
   *   !proto.api.health.v1.HealthCheckResponse>}
   */


  const methodDescriptor_HealthAPI_Watch = new grpc.web.MethodDescriptor('/api.health.v1.HealthAPI/Watch', grpc.web.MethodType.SERVER_STREAMING, proto.api.health.v1.HealthCheckRequest, proto.api.health.v1.HealthCheckResponse,
  /**
   * @param {!proto.api.health.v1.HealthCheckRequest} request
   * @return {!Uint8Array}
   */
  function (request) {
    return request.serializeBinary();
  }, proto.api.health.v1.HealthCheckResponse.deserializeBinary);
  /**
   * @param {!proto.api.health.v1.HealthCheckRequest} request The request proto
   * @param {?Object<string, string>=} metadata User defined
   *     call metadata
   * @return {!grpc.web.ClientReadableStream<!proto.api.health.v1.HealthCheckResponse>}
   *     The XHR Node Readable Stream
   */

  proto.api.health.v1.HealthAPIClient.prototype.watch = function (request, metadata) {
    return this.client_.serverStreaming(this.hostname_ + '/api.health.v1.HealthAPI/Watch', request, metadata || {}, methodDescriptor_HealthAPI_Watch);
  };
  /**
   * @param {!proto.api.health.v1.HealthCheckRequest} request The request proto
   * @param {?Object<string, string>=} metadata User defined
   *     call metadata
   * @return {!grpc.web.ClientReadableStream<!proto.api.health.v1.HealthCheckResponse>}
   *     The XHR Node Readable Stream
   */


  proto.api.health.v1.HealthAPIPromiseClient.prototype.watch = function (request, metadata) {
    return this.client_.serverStreaming(this.hostname_ + '/api.health.v1.HealthAPI/Watch', request, metadata || {}, methodDescriptor_HealthAPI_Watch);
  };

  module.exports = proto.api.health.v1;
});