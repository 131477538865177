define("incubex/tailwind/config", [], function () {
  "use strict";

  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  /*global module*/

  /*global require*/
  module.exports = {
    prefix: '',
    important: false,
    separator: ':',
    theme: {
      // Vars located at /styles/setup.css
      colors: {
        transparent: 'transparent',
        black: 'var(--black)',
        white: 'var(--white)',
        // Light Mode: Blacks
        // Dark Mode: Whites
        base: {
          default: 'var(--base)',
          '5a': 'var(--base-5a)',
          '10a': 'var(--base-10a)',
          '15a': 'var(--base-15a)',
          '25a': 'var(--base-25a)',
          '50a': 'var(--base-50a)'
        },
        // Light Mode: Whites
        // Dark Mode: Blacks
        inverse: {
          default: 'var(--inverse)',
          '5a': 'var(--inverse-5a)',
          '10a': 'var(--inverse-10a)',
          '15a': 'var(--inverse-15a)',
          '25a': 'var(--inverse-25a)',
          '50a': 'var(--inverse-50a)'
        },
        gray: {
          '300': 'var(--gray-300)',
          '400': 'var(--gray-400)',
          '500': 'var(--gray-500)',
          '600': 'var(--gray-600)',
          '700': 'var(--gray-700)',
          '800': 'var(--gray-800)'
        },
        // red: {},
        // orange: {},
        // yellow: {},
        green: {
          '500': 'var(--green-500)'
        },
        // blue: {},
        // indigo: {},
        system: {
          error: 'var(--system-error)',
          success: 'var(--system-success)'
        }
      },
      spacing: {
        '0': '0',
        '1': '1px',
        '2': '0.2rem',
        '4': '0.4rem',
        '8': '0.8rem',
        '12': '1.2rem',
        '16': '1.6rem',
        '24': '2.4em',
        '32': '3.2em',
        '40': '4em',
        '20': '2em'
      },
      backgroundColor: theme => theme('colors'),
      borderColor: theme => theme('colors'),
      borderRadius: {
        none: '0',
        sm: '0.2rem',
        default: '0.4rem',
        lg: '0.6rem',
        full: '9999px'
      },
      borderWidth: {
        default: '1px',
        '0': '0',
        '2': '0.2rem',
        '4': '0.4rem',
        '8': '0.8rem'
      },
      boxShadow: {
        default: '0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06)',
        md: '0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)',
        lg: '0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05)',
        xl: '0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04)',
        '2xl': '0 25px 50px -12px rgba(0, 0, 0, 0.25)',
        inner: 'inset 0 2px 4px 0 rgba(0, 0, 0, 0.06)',
        outline: '0 0 0 3px rgba(66, 153, 225, 0.5)',
        none: 'none'
      },
      cursor: {
        auto: 'auto',
        default: 'default',
        pointer: 'pointer'
      },
      fill: theme => theme('colors'),
      flex: {
        '1': '1 1 0%',
        auto: '1 1 auto',
        initial: '0 1 auto',
        none: 'none'
      },
      flexGrow: {
        '0': '0',
        default: '1'
      },
      flexShrink: {
        '0': '0',
        default: '1'
      },
      fontFamily: {
        primary: '"Source Sans Pro", sans-serif',
        secondary: '"Open Sans", sans-serif'
      },
      fontSize: {
        lg: '1.8rem',
        md: '1.6rem',
        sm: '1.4rem',
        xs: '1.2rem'
      },
      fontWeight: {
        bold: '700',
        medium: '600',
        normal: '400'
      },
      height: theme => _objectSpread({
        auto: 'auto',
        full: '100%',
        '100vh': '100vh',
        '50vh': '50vh'
      }, theme('spacing')),
      inset: (theme, {
        negative
      }) => _objectSpread({
        auto: 'auto',
        offscreen: '-999rem',
        '0': '0'
      }, theme('spacing'), {}, negative(theme('spacing'))),
      listStyleType: {
        none: 'none',
        disc: 'disc',
        decimal: 'decimal'
      },
      lineHeight: theme => _objectSpread({}, theme('spacing')),
      margin: (theme, {
        negative
      }) => _objectSpread({
        auto: 'auto'
      }, theme('spacing'), {}, negative(theme('spacing'))),
      maxHeight: {
        full: '100%',
        screen: '100vh'
      },
      maxWidth: {
        full: '100%'
      },
      minHeight: {
        '0': '0',
        full: '100%',
        screen: '100vh'
      },
      minWidth: {
        '0': '0',
        full: '100%',
        '1-4': '25%'
      },
      opacity: {
        '0': '0',
        '25': '0.25',
        '50': '0.5',
        '75': '0.75',
        '100': '1'
      },
      order: {
        first: '-9999',
        last: '9999',
        none: '0',
        '1': '1',
        '2': '2',
        '3': '3'
      },
      padding: theme => _objectSpread({
        auto: 'auto',
        '0': '0'
      }, theme('spacing')),
      stroke: theme => theme('colors'),
      textColor: theme => _objectSpread({
        txt: 'var(--txt)'
      }, theme('colors')),
      width: theme => _objectSpread({
        auto: 'auto',
        full: '100%',
        '3-4': '75%',
        '1-2': '50%',
        '1-4': '25%',
        '100vw': '100vw',
        '50vw': '50vw',
        '1-3': '33%'
      }, theme('spacing')),
      zIndex: {
        auto: 'auto',
        '0': '0',
        '10': '10',
        '20': '20',
        '30': '30',
        '40': '40',
        '50': '50'
      }
    },
    variants: {
      alignContent: ['responsive'],
      alignItems: ['responsive'],
      alignSelf: ['responsive'],
      backgroundColor: ['hover', 'focus'],
      borderCollapse: [],
      borderColor: [],
      borderRadius: [],
      borderStyle: [],
      borderWidth: [],
      boxShadow: [],
      cursor: [],
      display: ['responsive'],
      fill: ['hover', 'active'],
      flex: ['responsive'],
      flexDirection: ['responsive'],
      flexGrow: ['responsive'],
      flexShrink: ['responsive'],
      flexWrap: ['responsive'],
      float: ['responsive'],
      fontFamily: [],
      fontSize: ['responsive'],
      fontSmoothing: [],
      fontStyle: [],
      fontWeight: [],
      height: ['responsive'],
      inset: ['responsive'],
      justifyContent: ['responsive'],
      lineHeight: [],
      listStyleType: [],
      margin: ['responsive'],
      maxHeight: ['responsive'],
      maxWidth: ['responsive'],
      minHeight: ['responsive'],
      minWidth: ['responsive'],
      opacity: [],
      order: ['responsive'],
      outline: ['focus'],
      overflow: ['responsive'],
      padding: ['responsive'],
      position: ['responsive'],
      stroke: [],
      tableLayout: [],
      textAlign: ['responsive'],
      textColor: ['hover', 'focus'],
      textDecoration: ['hover', 'focus'],
      textTransform: [],
      userSelect: [],
      verticalAlign: ['responsive'],
      visibility: ['responsive'],
      whitespace: ['responsive'],
      width: ['responsive'],
      wordBreak: ['responsive'],
      zIndex: []
    },
    // White-listed plugins
    corePlugins: ['alignContent', 'alignItems', 'alignSelf', 'backgroundColor', 'borderCollapse', 'borderColor', 'borderRadius', 'borderStyle', 'borderWidth', 'boxShadow', 'cursor', 'display', 'fill', 'flex', 'flexDirection', 'flexGrow', 'flexShrink', 'flexWrap', 'float', 'fontFamily', 'fontSize', 'fontSmoothing', 'fontStyle', 'fontWeight', 'height', 'inset', 'justifyContent', 'lineHeight', 'listStyleType', 'margin', 'maxHeight', 'maxWidth', 'minHeight', 'minWidth', 'opacity', 'order', 'outline', 'overflow', 'padding', 'position', 'preflight', 'stroke', 'tableLayout', 'textAlign', 'textColor', 'textDecoration', 'textTransform', 'userSelect', 'verticalAlign', 'visibility', 'whitespace', 'width', 'wordBreak', 'zIndex'],
    plugins: [// components
    require("incubex/tailwind/plugins/components/button")(), require("incubex/tailwind/plugins/components/divider")(), require("incubex/tailwind/plugins/components/dropdown")(), require("incubex/tailwind/plugins/components/heading")(), require("incubex/tailwind/plugins/components/input-checkbox")(), require("incubex/tailwind/plugins/components/input-radio")(), require("incubex/tailwind/plugins/components/input-textbox")(), require("incubex/tailwind/plugins/components/link")(), require("incubex/tailwind/plugins/components/notification")(), require("incubex/tailwind/plugins/components/toggle")(), // utilities
    require("incubex/tailwind/plugins/utilities/box-sizing")(), require("incubex/tailwind/plugins/utilities/screen-hidden")(), require("incubex/tailwind/plugins/utilities/transition")()]
  };
});