define("incubex/templates/authenticated/settings", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "nGPuPH2p",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"id\",\"layout-bisection\"],[8],[0,\"\\n  \"],[7,\"section\",true],[10,\"class\",\"left-container\"],[8],[0,\"\\n    \"],[7,\"h1\",true],[10,\"class\",\"heading-1 text-txt\"],[8],[0,\"Settings\"],[9],[0,\"\\n\\n    \"],[7,\"form\",true],[10,\"data-role\",\"update-settings-form\"],[11,\"onsubmit\",[28,\"action\",[[23,0,[]],\"onUpdateSettings\"],null]],[8],[0,\"\\n      \"],[7,\"div\",true],[10,\"class\",\"flex justify-between items-center mb-24\"],[8],[0,\"\\n        Order Confirmation\\n        \"],[5,\"toggle\",[],[[\"@checked\",\"@label\",\"@role\"],[[23,0,[\"model\",\"orderConfirmation\"]],\"orderConfirmation\",\"order-confirmation\"]]],[0,\"\\n      \"],[9],[0,\"\\n\\n      \"],[7,\"div\",true],[10,\"class\",\"flex justify-between items-center mb-24\"],[8],[0,\"\\n        Order Cancellation Confirmation\\n        \"],[5,\"toggle\",[],[[\"@checked\",\"@label\",\"@role\"],[[23,0,[\"model\",\"orderCancellationConfirmation\"]],\"orderCancelConfirmation\",\"order-cancel-confirmation\"]]],[0,\"\\n      \"],[9],[0,\"\\n\\n      \"],[5,\"dropdown\",[],[[\"@class\",\"@role\",\"@label\",\"@default\",\"@options\",\"@value\"],[\"w-full\",\"timezone\",\"Timezone\",\"Select Timezone\",[23,0,[\"timezoneOptions\"]],[23,0,[\"model\",\"timezone\"]]]]],[0,\"\\n\\n      \"],[7,\"div\",true],[10,\"class\",\"flex justify-between\"],[8],[0,\"\\n        \"],[5,\"button\",[],[[\"@role\",\"@class\",\"@onClick\",\"@style\"],[\"cancel-settings\",\"mt-40\",[23,0,[\"onCancel\"]],\"link\"]],{\"statements\":[[0,\"\\n          Back\\n        \"]],\"parameters\":[]}],[0,\"\\n\\n        \"],[5,\"button\",[],[[\"@role\",\"@type\",\"@class\",\"@disabled\"],[\"update-settings\",\"submit\",\"mt-40\",[22,\"isUpdateDisabled\"]]],{\"statements\":[[0,\"\\n          Update Settings\\n        \"]],\"parameters\":[]}],[0,\"\\n      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "incubex/templates/authenticated/settings.hbs"
    }
  });

  _exports.default = _default;
});