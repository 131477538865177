define("incubex/components/list-control", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class, _descriptor, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{#vertical-collection @items renderAll=false estimateHeight=40 as |item|}}
    {{#draggable-object-target itemId=item.id action=(action "onProductRowDrop") }}
      {{#draggable-object content=item }}
        <div class="flex flex-row">
          <span title="{{item.label}}" class="flex-auto inline-block ml-8 mr-8 p-2 whitespace-no-wrap">{{item.label}}</span>
          <Button
            @style="link"
            @class="mr-8 ml-8"
            @onClick={{action this.removeItem item.id}}
          >
            {{svg-jar "close" height="12" width="12" class="ml-8 font-bold"}}
          </Button>
        </div>
      {{/draggable-object}}
    {{/draggable-object-target}}
  {{/vertical-collection}}
  */
  {
    id: "ihJ2/nZb",
    block: "{\"symbols\":[\"item\",\"@items\"],\"statements\":[[4,\"vertical-collection\",[[23,2,[]]],[[\"renderAll\",\"estimateHeight\"],[false,40]],{\"statements\":[[4,\"draggable-object-target\",null,[[\"itemId\",\"action\"],[[23,1,[\"id\"]],[28,\"action\",[[23,0,[]],\"onProductRowDrop\"],null]]],{\"statements\":[[4,\"draggable-object\",null,[[\"content\"],[[23,1,[]]]],{\"statements\":[[0,\"      \"],[7,\"div\",true],[10,\"class\",\"flex flex-row\"],[8],[0,\"\\n        \"],[7,\"span\",true],[11,\"title\",[29,[[23,1,[\"label\"]]]]],[10,\"class\",\"flex-auto inline-block ml-8 mr-8 p-2 whitespace-no-wrap\"],[8],[1,[23,1,[\"label\"]],false],[9],[0,\"\\n        \"],[5,\"button\",[],[[\"@style\",\"@class\",\"@onClick\"],[\"link\",\"mr-8 ml-8\",[28,\"action\",[[23,0,[]],[23,0,[\"removeItem\"]],[23,1,[\"id\"]]],null]]],{\"statements\":[[0,\"\\n          \"],[1,[28,\"svg-jar\",[\"close\"],[[\"height\",\"width\",\"class\"],[\"12\",\"12\",\"ml-8 font-bold\"]]],false],[0,\"\\n        \"]],\"parameters\":[]}],[0,\"\\n      \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null]],\"parameters\":[1]},null]],\"hasEval\":false}",
    meta: {
      moduleName: "incubex/components/list-control.hbs"
    }
  });

  let ListControlComponent = (_class = (_temp = class ListControlComponent extends _component.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "items", _descriptor, this);
    }

    removeItem(id) {
      this.args.onRemove(id);
    }

    onProductRowDrop(dragRow, dropRow) {
      this.items = [...this.args.items];
      let dragIndex = this.items.findIndex(ele => ele.id === dragRow.id);
      let dropIndex = this.items.findIndex(ele => ele.id === dropRow.target.itemId);
      this.args.updateProductOrder(dragIndex, dropIndex);
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "items", [Ember._tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return [];
    }
  }), _applyDecoratedDescriptor(_class.prototype, "removeItem", [Ember._action], Object.getOwnPropertyDescriptor(_class.prototype, "removeItem"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onProductRowDrop", [Ember._action], Object.getOwnPropertyDescriptor(_class.prototype, "onProductRowDrop"), _class.prototype)), _class);
  _exports.default = ListControlComponent;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, ListControlComponent);
});