define("incubex/utils/order-enums", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.getParkedOrderStatus = _exports.orderTypes = _exports.orderDurations = _exports.toTimeInForce = _exports.toSide = _exports.toStatus = _exports.toType = _exports.side = void 0;

  const side = () => {
    return {
      buy: "BUY",
      sell: "SELL"
    };
  };

  _exports.side = side;

  const toType = type => {
    if (!type) return '';

    switch (type.toString()) {
      case '1':
        return 'Market';

      case '2':
        return 'Limit';

      default:
        return null;
    }
  };

  _exports.toType = toType;

  const toStatus = (status, execType = null) => {
    if (status == null) return "";

    if (execType == "AC") {
      return "Admin Cancelled";
    }

    let parkedOrderStatus = getParkedOrderStatus(); //-1 ---

    switch (status.toString()) {
      case '0':
        return 'New';

      case '1':
        return 'Partially Filled';

      case '2':
        return 'Filled';

      case '4':
        return 'Cancelled';

      case '6':
        return 'Pending Cancel';

      case '9':
        return 'Suspended';

      case '100':
        return 'Rejected';

      case parkedOrderStatus:
        return 'Parked';

      default:
        return null;
    }
  };

  _exports.toStatus = toStatus;

  const toSide = side => {
    if (!side) return '';

    switch (side.toString()) {
      case '1':
        return 'Buy';

      case '2':
        return 'Sell';

      default:
        return null;
    }
  };

  _exports.toSide = toSide;

  const toTimeInForce = timeInForce => {
    if (timeInForce == null) return '';

    switch (timeInForce.toString()) {
      case '0':
        return 'DAY (Good For Day)';

      case '1':
        return 'GTC (Good Till Cancelled)';

      case '3':
        return 'IOC (Immediate or Cancel)';

      case '6':
        return 'GTD (Good Till Date)';

      default:
        return null;
    }
  };

  _exports.toTimeInForce = toTimeInForce;

  const orderDurations = () => {
    return [{
      value: '0',
      label: 'DAY (Good For Day)'
    }, {
      value: '1',
      label: 'GTC (Good Till Cancelled)'
    }, {
      value: '3',
      label: 'IOC (Immediate or Cancel)'
    }, {
      value: '6',
      label: 'GTD (Good Till Date)'
    }];
  };

  _exports.orderDurations = orderDurations;

  const orderTypes = () => {
    return [{
      value: '1',
      label: 'Market'
    }, {
      value: '2',
      label: 'Limit'
    }];
  };

  _exports.orderTypes = orderTypes;

  const getParkedOrderStatus = () => {
    return "-1";
  };

  _exports.getParkedOrderStatus = getParkedOrderStatus;
});