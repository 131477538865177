define("incubex/types/login-steps", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    DEFAULT: 'DEFAULT',
    AUTH_CODE: 'AUTH_CODE',
    RECOVERY_CODE: 'RECOVERY_CODE'
  };
  _exports.default = _default;
});