define("incubex/types/onboarding-status", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    PASSWORD: 'PASSWORD',
    CREDENTIALS: 'CREDENTIALS',
    DONE: 'DONE'
  };
  _exports.default = _default;
});