define("incubex/types/socket", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // https://developer.mozilla.org/en-US/docs/Web/API/WebSocket
  class Socket {
    constructor(url, subs) {
      if (this._sock) {
        this._sock.close();
      }

      this._sock = new WebSocket(url, subs);
    }

    on(eventType, callback) {
      this._sock.addEventListener(eventType, callback);
    }

    off(eventType, callback) {
      this._sock.removeEventListener(eventType, callback);
    }

    close() {
      this._sock.close();
    }

    send(data) {
      this._sock.send(data);
    }

    get listeners() {
      return this._sock.listeners;
    }

    get protocol() {
      return this._sock.protocol;
    }

    get readyState() {
      return this._sock.readyState;
    }

  }

  _exports.default = Socket;
});