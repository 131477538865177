define("incubex/components/x-tabs-tab", ["exports", "incubex/templates/components/x-tabs-tab"], function (_exports, _xTabsTab) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _xTabsTab.default,
    tagName: 'li',
    classNameBindings: ['isActive:tab-current'],
    ownerName: undefined,
    data: undefined,
    name: undefined,

    init() {
      this._super(...arguments);

      let register = this.get('api.registerAction');

      if (register) {
        register(this.get('name'), this.get('data'));
      }
    },

    tabName: Ember.computed('ownerName', 'name', {
      get() {
        return "".concat(this.get('ownerName'), "-").concat(this.get('name'));
      }

    }),
    isActive: Ember.computed('name', 'api.activeName', {
      get() {
        return this.get('name') === this.get('api.activeName');
      }

    })
  });

  _exports.default = _default;
});