define("incubex/components/notification/message", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class, _descriptor, _descriptor2, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <li {{class "__message" @message.type is-closing=this.isClosing }} data-ui-component="notification-message">
    <div class="__wrapper">
      <p class="__message-text" data-role="message-text">
        {{@message.text}}
      </p>
    </div>
  
    <button
      class="__close-button"
      onclick={{action "onPop"}}
      data-role={{concat "close-" @message.type "-message"}}
      >
        {{svg-jar "close" class="fill-white" width="20" height="20"}}
      <span class="screen-hidden">Close</span>
    </button>
  
    {{#if @message.options.countdown}}
      <div class="__countdown" data-role="notification-countdown" />
    {{/if}}
  </li>
  
  */
  {
    id: "W4RwjWWQ",
    block: "{\"symbols\":[\"@message\"],\"statements\":[[7,\"li\",false],[12,\"data-ui-component\",\"notification-message\"],[3,\"class\",[\"__message\",[23,1,[\"type\"]]],[[\"is-closing\"],[[23,0,[\"isClosing\"]]]]],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"__wrapper\"],[8],[0,\"\\n    \"],[7,\"p\",true],[10,\"class\",\"__message-text\"],[10,\"data-role\",\"message-text\"],[8],[0,\"\\n      \"],[1,[23,1,[\"text\"]],false],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\\n  \"],[7,\"button\",true],[10,\"class\",\"__close-button\"],[11,\"onclick\",[28,\"action\",[[23,0,[]],\"onPop\"],null]],[11,\"data-role\",[28,\"concat\",[\"close-\",[23,1,[\"type\"]],\"-message\"],null]],[8],[0,\"\\n      \"],[1,[28,\"svg-jar\",[\"close\"],[[\"class\",\"width\",\"height\"],[\"fill-white\",\"20\",\"20\"]]],false],[0,\"\\n    \"],[7,\"span\",true],[10,\"class\",\"screen-hidden\"],[8],[0,\"Close\"],[9],[0,\"\\n  \"],[9],[0,\"\\n\\n\"],[4,\"if\",[[23,1,[\"options\",\"countdown\"]]],null,{\"statements\":[[0,\"    \"],[7,\"div\",true],[10,\"class\",\"__countdown\"],[10,\"data-role\",\"notification-countdown\"],[8],[9],[0,\"\\n\"]],\"parameters\":[]},null],[9],[0,\"\\n\"]],\"hasEval\":false}",
    meta: {
      moduleName: "incubex/components/notification/message.hbs"
    }
  });

  let NotificationMessageComponent = (_class = (_temp = class NotificationMessageComponent extends _component.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "notification", _descriptor, this);

      _initializerDefineProperty(this, "isClosing", _descriptor2, this);

      if (this.args.message.options.countdown) {
        this.timer = this.autoPop();
      }
    }

    pop() {
      if (!this.isClosing) {
        this.isClosing = true;
        Ember.run.later(() => {
          this.notification.removeMessage(this.args.message);

          if (this.args.message.options.closeHandler) {
            this.args.message.options.closeHandler(this.args.message.options.context);
          }
        }, this.notification.delay.close);
      }
    }

    autoPop() {
      return setTimeout(() => this.pop(), this.notification.delay.auto);
    }

    onPop() {
      if (this.timer) {
        clearTimeout(this.timer);
      }

      this.pop();
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "notification", [Ember.inject.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "isClosing", [Ember._tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return false;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "onPop", [Ember._action], Object.getOwnPropertyDescriptor(_class.prototype, "onPop"), _class.prototype)), _class);
  _exports.default = NotificationMessageComponent;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, NotificationMessageComponent);
});