define("incubex/utils/common", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.isMarketSegmentIdEp3 = _exports.isEp3Instrument = _exports.parsePrice = _exports.decimalsToScale = _exports.sortTradableInstruments = _exports.matcher = _exports.numberWithCommas = void 0;

  const numberWithCommas = x => {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  };

  _exports.numberWithCommas = numberWithCommas;

  const matcher = (product, term) => {
    let words = term.split(' ');
    words = words.filter(item => item);
    let found = 1;
    let searchIndex = 0;
    words.forEach(x => {
      searchIndex = product.label.toLowerCase().indexOf(x.toLowerCase(), searchIndex);

      if (searchIndex < 0) {
        found = -1;
        return false;
      }
    });
    return found;
  };

  _exports.matcher = matcher;

  const sortTradableInstruments = (tradable_instruments, tradable_instrument_ids) => {
    let instruments = [];

    if (tradable_instrument_ids) {
      let ids = tradable_instrument_ids.split(',');
      ids.forEach(id => {
        let instrument = tradable_instruments.find(ins => ins.id == id);
        instruments.push(instrument);
      });
    } else {
      instruments = tradable_instruments || [];
    }

    return instruments;
  };

  _exports.sortTradableInstruments = sortTradableInstruments;

  const decimalsToScale = decimalCount => {
    if (decimalCount) {
      return Number("1" + "".padEnd(Number(decimalCount), "0"));
    }

    return 1;
  };

  _exports.decimalsToScale = decimalsToScale;

  const parsePrice = (number, scale) => {
    let decimalPlaces = 0;
    let temp = scale;

    for (decimalPlaces = 0; temp % 10 === 0; temp /= 10) {
      decimalPlaces++;
    }

    return (parseFloat(number) / parseFloat(scale)).toFixed(decimalPlaces);
  };

  _exports.parsePrice = parsePrice;

  const isEp3Instrument = instrument => {
    return instrument && instrument.market_segment_id === "EP3";
  };

  _exports.isEp3Instrument = isEp3Instrument;

  const isMarketSegmentIdEp3 = marketSegmentId => {
    return marketSegmentId === "EP3";
  };

  _exports.isMarketSegmentIdEp3 = isMarketSegmentIdEp3;
});