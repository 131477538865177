define("incubex/controllers/authenticated/time-sales-tab", ["exports", "incubex/types/url"], function (_exports, _url) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _descriptor13, _descriptor14, _descriptor15, _descriptor16, _descriptor17, _descriptor18, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let AuthenticatedTimeSalesTabController = (_class = (_temp = class AuthenticatedTimeSalesTabController extends Ember.Controller {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "queryParams", ['editTabId', 'copyTabId']);

      _defineProperty(this, "editTabId", null);

      _defineProperty(this, "copyTabId", null);

      _defineProperty(this, "_productTypes", [{
        label: 'All',
        value: 'All'
      }, {
        label: 'Futures',
        value: 'Future'
      }, {
        label: 'Options',
        value: 'Options'
      }]);

      _defineProperty(this, "_productGroups", [{
        label: 'All',
        value: 'All'
      }, {
        label: 'Environmental',
        value: 'Environmental'
      }, {
        label: 'Power and Gas',
        value: 'Power and Gas'
      }, {
        label: 'Trucking Freight',
        value: 'Trucking Freight'
      }]);

      _defineProperty(this, "_tradeTypes", [{
        label: 'All',
        value: 'All'
      }, {
        label: 'Screen',
        value: 'Screen'
      }, {
        label: 'Block',
        value: 'Block'
      }]);

      _initializerDefineProperty(this, "sessionApi", _descriptor, this);

      _initializerDefineProperty(this, "notification", _descriptor2, this);

      _initializerDefineProperty(this, "portfolioTabsService", _descriptor3, this);

      _initializerDefineProperty(this, "headerName", _descriptor4, this);

      _initializerDefineProperty(this, "portfolioName", _descriptor5, this);

      _initializerDefineProperty(this, "showDeleteDialog", _descriptor6, this);

      _initializerDefineProperty(this, "tabData", _descriptor7, this);

      _initializerDefineProperty(this, "mode", _descriptor8, this);

      _initializerDefineProperty(this, "productGroups", _descriptor9, this);

      _initializerDefineProperty(this, "productTypes", _descriptor10, this);

      _initializerDefineProperty(this, "tradeTypes", _descriptor11, this);

      _initializerDefineProperty(this, "productGroup", _descriptor12, this);

      _initializerDefineProperty(this, "productType", _descriptor13, this);

      _initializerDefineProperty(this, "tradeType", _descriptor14, this);

      _initializerDefineProperty(this, "activeOnly", _descriptor15, this);

      _initializerDefineProperty(this, "searchSecurityValue", _descriptor16, this);

      _initializerDefineProperty(this, "searchedSecurities", _descriptor17, this);

      _initializerDefineProperty(this, "selectedInstruments", _descriptor18, this);
    }

    async onAdd() {
      let data = this.getPayload();

      try {
        let location;

        if (this.mode == 'new') {
          location = _url.default.TIME_AND_SALES_TABS;
        }

        if (this.mode == 'copy') {
          location = "".concat(_url.default.TIME_AND_SALES_TABS, "/").concat(this.tabData.id);
        }

        let newTab = await this.sessionApi.post(location, data);
        this.portfolioTabsService.saveSelectedTnSIdToLocalStorage(newTab.id);
        this.notification.success("Tab ".concat(this.portfolioName, " added successfully."));
        this.cleanupAndRedirect('authenticated.index');
      } catch (e) {
        this.notification.error(e);
      }
    }

    async onEdit() {
      let data = this.getPayload();

      try {
        await this.sessionApi.put("".concat(_url.default.TIME_AND_SALES_TABS, "/").concat(this.tabData.id), data);
        this.notification.success("Tab updated successfully.");
        this.cleanupAndRedirect('authenticated.index');
      } catch (e) {
        this.notification.error(e);
      }
    }

    getPayload() {
      var _this$selectedInstrum, _this$selectedInstrum2;

      let data = {
        name: this.portfolioName,
        filter_active_portfolio: this.activeOnly,
        trade_type: this.tradeType || '',
        product_type: this.productType || '',
        product_group: this.productGroup || '',
        instrument_ids: (_this$selectedInstrum = this.selectedInstruments) === null || _this$selectedInstrum === void 0 ? void 0 : (_this$selectedInstrum2 = _this$selectedInstrum.map(inst => inst.id)) === null || _this$selectedInstrum2 === void 0 ? void 0 : _this$selectedInstrum2.join(",")
      };
      return data;
    }

    clearData() {
      this.selectedInstruments = [];
    }

    cleanupAndRedirect(url) {
      this.clearData();
      this.transitionToRoute(url);
    }

    onCancel() {
      this.cleanupAndRedirect('authenticated.index');
    }

    onShowDeleteDailog() {
      this.showDeleteDialog = true;
    }

    async onDelete() {
      try {
        this.showDeleteDialog = false;
        await this.sessionApi.delete("".concat(_url.default.TIME_AND_SALES_TABS, "/").concat(this.tabData.id));
        this.notification.success('Tab deleted successfully.');
        this.cleanupAndRedirect('authenticated.index');
      } catch (e) {
        this.notification.error(e);
      }
    }

    onCancelDelete() {
      this.showDeleteDialog = false;
    }

    async onDuplicate() {
      try {
        this.transitionToRoute('authenticated.time-sales-tab', {
          queryParams: {
            copyTabId: this.tabData.id,
            editTabId: null
          }
        });
      } catch (e) {
        this.notification.error(e);
      }
    }

    onSelectInstrument(instruments) {
      this.selectedInstruments = instruments;
    }

    get editMode() {
      if (this.mode == 'edit') return true;
      return false;
    }

    get addMode() {
      if (this.mode == 'new') return true;
      return false;
    }

    get copyMode() {
      if (this.mode == 'copy') return true;
      return false;
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "sessionApi", [Ember.inject.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "notification", [Ember.inject.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "portfolioTabsService", [Ember.inject.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "headerName", [Ember._tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return 'New Time & Sales Tab';
    }
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "portfolioName", [Ember._tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "showDeleteDialog", [Ember._tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return false;
    }
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "tabData", [Ember._tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "mode", [Ember._tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return 'new';
    }
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "productGroups", [Ember._tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return [...this._productGroups];
    }
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "productTypes", [Ember._tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return [...this._productTypes];
    }
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "tradeTypes", [Ember._tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return [...this._tradeTypes];
    }
  }), _descriptor12 = _applyDecoratedDescriptor(_class.prototype, "productGroup", [Ember._tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return 'All';
    }
  }), _descriptor13 = _applyDecoratedDescriptor(_class.prototype, "productType", [Ember._tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  }), _descriptor14 = _applyDecoratedDescriptor(_class.prototype, "tradeType", [Ember._tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return 'All';
    }
  }), _descriptor15 = _applyDecoratedDescriptor(_class.prototype, "activeOnly", [Ember._tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return false;
    }
  }), _descriptor16 = _applyDecoratedDescriptor(_class.prototype, "searchSecurityValue", [Ember._tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return '';
    }
  }), _descriptor17 = _applyDecoratedDescriptor(_class.prototype, "searchedSecurities", [Ember._tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return [];
    }
  }), _descriptor18 = _applyDecoratedDescriptor(_class.prototype, "selectedInstruments", [Ember._tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return [];
    }
  }), _applyDecoratedDescriptor(_class.prototype, "onAdd", [Ember._action], Object.getOwnPropertyDescriptor(_class.prototype, "onAdd"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onEdit", [Ember._action], Object.getOwnPropertyDescriptor(_class.prototype, "onEdit"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onCancel", [Ember._action], Object.getOwnPropertyDescriptor(_class.prototype, "onCancel"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onShowDeleteDailog", [Ember._action], Object.getOwnPropertyDescriptor(_class.prototype, "onShowDeleteDailog"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onDelete", [Ember._action], Object.getOwnPropertyDescriptor(_class.prototype, "onDelete"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onCancelDelete", [Ember._action], Object.getOwnPropertyDescriptor(_class.prototype, "onCancelDelete"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onDuplicate", [Ember._action], Object.getOwnPropertyDescriptor(_class.prototype, "onDuplicate"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onSelectInstrument", [Ember._action], Object.getOwnPropertyDescriptor(_class.prototype, "onSelectInstrument"), _class.prototype)), _class);
  _exports.default = AuthenticatedTimeSalesTabController;
});