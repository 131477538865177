define("incubex/templates/onboard/two-factor/verify", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "OKIGC1nI",
    "block": "{\"symbols\":[],\"statements\":[[5,\"layout/logged-out\",[],[[\"@pageTitle\"],[\"Two-Factor Authentication\"]],{\"statements\":[[0,\"\\n  \"],[7,\"div\",true],[8],[0,\"\\n    \"],[7,\"p\",true],[10,\"class\",\"mt-16 text-base \"],[8],[0,\"Verify your phone number\"],[9],[0,\"\\n    \"],[7,\"p\",true],[10,\"class\",\"mt-24\"],[8],[0,\"An authentication code has been sent to \"],[1,[23,0,[\"phoneNumber\"]],false],[9],[0,\"\\n\\n    \"],[5,\"link-to\",[],[[\"@route\",\"@class\"],[\"onboard.two-factor\",\"text-sm text-base\"]],{\"statements\":[[0,\"\\n      Edit phone number\\n    \"]],\"parameters\":[]}],[0,\"\\n  \"],[9],[0,\"\\n  \"],[5,\"input/textbox\",[],[[\"@class\",\"@label\",\"@name\",\"@value\"],[\"w-full mt-16\",\"Authentication Code\",\"authentication-code\",[23,0,[\"authenticationCode\"]]]]],[0,\"\\n\\n  \"],[7,\"div\",true],[10,\"class\",\"flex justify-between items-center mt-24\"],[8],[0,\"\\n    \"],[5,\"button\",[],[[\"@route\",\"@style\",\"@onClick\"],[\"onboard/two-factor\",\"link\",[28,\"action\",[[23,0,[]],[23,0,[\"resendCode\"]]],null]]],{\"statements\":[[0,\"\\n      Resend Code\\n    \"]],\"parameters\":[]}],[0,\"\\n\\n    \"],[5,\"button\",[],[[\"@role\",\"@type\",\"@disabled\",\"@onClick\"],[\"two-factor-verify\",\"submit\",[28,\"not\",[[23,0,[\"authenticationCode\"]]],null],[28,\"action\",[[23,0,[]],[23,0,[\"verifyAuthenticationCode\"]]],null]]],{\"statements\":[[0,\"\\n      Continue\\n    \"]],\"parameters\":[]}],[0,\"\\n  \"],[9],[0,\"\\n\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "incubex/templates/onboard/two-factor/verify.hbs"
    }
  });

  _exports.default = _default;
});