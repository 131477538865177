define("incubex/services/portfolio-tabs-service", ["exports", "incubex/types/url", "moment"], function (_exports, _url, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class, _descriptor, _descriptor2, _temp;

  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const PORTFOLIO_ID_SELECTED = 'portfolio_id_selected';
  const TNS_ID_SELECTED = 'tns_id_selected';
  const DEFAULT_QUERY = 'q=&group=&type=Future&sub_group=&only_new=false';
  let PortfolioTabsServiceService = (_class = (_temp = class PortfolioTabsServiceService extends Ember.Service {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "sessionApi", _descriptor, this);

      _initializerDefineProperty(this, "cachedDefaultSearchedResults", _descriptor2, this);

      this.activeTabListeners = [];
      this.activeTnSTabListeners = [];
      this.tabTradableInstruments = {};
    }

    async getTradableInstruments(tabId) {
      let instruments = this.tabTradableInstruments[tabId];

      if (!instruments) {
        instruments = await this.sessionApi.get("".concat(_url.default.PORTFOLIO_TAB, "/").concat(tabId, "/tradable_instruments"));

        if (instruments) {
          this.tabTradableInstruments[tabId] = instruments;
        }
      }

      return instruments;
    }

    invalidateTabTradableInstruments(tabId) {
      delete this.tabTradableInstruments[tabId];
    }

    async getSearchedResults(query = DEFAULT_QUERY) {
      if (query === DEFAULT_QUERY) {
        if (this.cachedDefaultSearchedResults && this.cachedDefaultSearchedResults.length > 0) {
          return this.cachedDefaultSearchedResults;
        }
      }

      let searchedResult = await this.sessionApi.get("".concat(_url.default.SEARCH_INSTRUMENTS, "?").concat(query));
      searchedResult = searchedResult.map(result => {
        return _objectSpread({
          name: result.short_name || "Short Name Unknown! (ID: ".concat(result.id, ")"),
          isSelected: false,
          isVisible: true,
          isDisabled: this.nodeDisabled(result),
          level: 0,
          children: [{
            id: -1,
            name: 'Loading..',
            isVisible: true
          }],
          isExpanded: false,
          title: this.secDefTooltip(result)
        }, result);
      });

      if (searchedResult && query === DEFAULT_QUERY) {
        this.cachedDefaultSearchedResults = searchedResult;
      }

      return searchedResult;
    }

    nodeDisabled(product) {
      return product.source === "OVERRIDES" && product.launch_date && (0, _moment.default)(product.launch_date).startOf('day') <= (0, _moment.default)().startOf('day');
    }

    secDefTooltip(product) {
      if (product.source != "OVERRIDES") return null;

      if (product.launch_date && (0, _moment.default)(product.launch_date).startOf('day') > (0, _moment.default)().startOf('day')) {
        return product.short_name + " launches on " + new Date(product.launch_date).toDateString();
      } else {
        return product.short_name + " is not currently tradable";
      }
    }

    addListener(listener) {
      this.activeTabListeners.push(listener);
    }

    addListenerTns(listener) {
      this.activeTnSTabListeners.push(listener);
    }

    onChangeActiveTab(tab, id) {
      this.saveSelectedPortfolioIdToLocalStorage(id);
      this.activeTabListeners.forEach(callback => {
        if (callback) {
          callback(tab);
        }
      });
    }

    onChangeActiveTabTnS(tab, id) {
      this.saveSelectedTnSIdToLocalStorage(id);
      this.activeTnSTabListeners.forEach(callback => {
        if (callback) {
          callback(id);
        }
      });
    }

    saveSelectedPortfolioIdToLocalStorage(id) {
      localStorage.setItem(PORTFOLIO_ID_SELECTED, id);
    }

    getSelectedPortfolioIdFromLocalStorage() {
      return localStorage.getItem(PORTFOLIO_ID_SELECTED);
    }

    saveSelectedTnSIdToLocalStorage(id) {
      localStorage.setItem(TNS_ID_SELECTED, id);
    }

    getSelectedTnSIdFromLocalStorage() {
      return localStorage.getItem(TNS_ID_SELECTED);
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "sessionApi", [Ember.inject.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "cachedDefaultSearchedResults", [Ember._tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  })), _class);
  _exports.default = PortfolioTabsServiceService;
});