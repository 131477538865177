define("incubex/templates/components/x-tab", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "+9czlnui",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[0,\"  \"],[14,1,[[28,\"hash\",null,[[\"pane\",\"activeId\",\"selectAction\"],[[28,\"component\",[\"x-tab/pane\"],[[\"parent\",\"activeId\"],[[23,0,[]],[24,[\"isActiveId\"]]]]],[24,[\"isActiveId\"]],[28,\"action\",[[23,0,[]],\"select\"],null]]]]]],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "incubex/templates/components/x-tab.hbs"
    }
  });

  _exports.default = _default;
});