define("incubex/tests/mirage/mirage.lint-test", [], function () {
  "use strict";

  QUnit.module('ESLint | mirage');
  QUnit.test('mirage/config.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/config.js should pass ESLint\n\n');
  });
  QUnit.test('mirage/factories/user.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/factories/user.js should pass ESLint\n\n');
  });
  QUnit.test('mirage/fixtures/onboarding-data.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/fixtures/onboarding-data.js should pass ESLint\n\n');
  });
  QUnit.test('mirage/fixtures/session.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/fixtures/session.js should pass ESLint\n\n');
  });
  QUnit.test('mirage/models/setting.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/models/setting.js should pass ESLint\n\n');
  });
  QUnit.test('mirage/models/user.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/models/user.js should pass ESLint\n\n');
  });
  QUnit.test('mirage/scenarios/default.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/scenarios/default.js should pass ESLint\n\n');
  });
  QUnit.test('mirage/serializers/application.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/serializers/application.js should pass ESLint\n\n');
  });
});