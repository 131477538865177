define("incubex/components/x-tree-checkbox", ["exports", "incubex/components/x-tree-checkbox"], function (_exports, _xTreeCheckbox) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <span role="button" onclick={{action toggleCheck}}>
    {{input
      type="checkbox"
      checked=model.isChecked
      disabled=model.isDisabled
      indeterminate=model.isIndeterminate
      change=(action "noop")
     class="treecheckbox"
    }}
  </span>
  
  */
  {
    id: "Eg6yX4/5",
    block: "{\"symbols\":[],\"statements\":[[7,\"span\",true],[10,\"role\",\"button\"],[11,\"onclick\",[28,\"action\",[[23,0,[]],[24,[\"toggleCheck\"]]],null]],[8],[0,\"\\n  \"],[1,[28,\"input\",null,[[\"type\",\"checked\",\"disabled\",\"indeterminate\",\"change\",\"class\"],[\"checkbox\",[24,[\"model\",\"isChecked\"]],[24,[\"model\",\"isDisabled\"]],[24,[\"model\",\"isIndeterminate\"]],[28,\"action\",[[23,0,[]],\"noop\"],null],\"treecheckbox\"]]],false],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    meta: {
      moduleName: "incubex/components/x-tree-checkbox.hbs"
    }
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember.Component.extend({
    layout: _xTreeCheckbox.default,
    tagName: 'span',
    classNames: ['tree-checkbox'],
    actions: {
      // IE requires a change action to be set or it will
      // attempt to mutate the checked attribute
      noop() {}

    }
  }));

  _exports.default = _default;
});