define("incubex/grpc_web/api/admin/v1/instrument_admin_api_pb", [], function () {
  "use strict";

  // source: api/admin/v1/instrument_admin_api.proto

  /**
   * @fileoverview
   * @enhanceable
   * @suppress {missingRequire} reports error on implicit type usages.
   * @suppress {messageConventions} JS Compiler reports an error if a variable or
   *     field starts with 'MSG_' and isn't a translatable message.
   * @public
   */
  // GENERATED CODE -- DO NOT EDIT!

  /* eslint-disable */
  // @ts-nocheck
  var jspb = require("google-protobuf");

  var goog = jspb;

  var global = typeof globalThis !== 'undefined' && globalThis || typeof window !== 'undefined' && window || typeof global !== 'undefined' && global || typeof self !== 'undefined' && self || function () {
    return this;
  }.call(null) || Function('return this')();

  var api_admin_v1_admin_pb = require("incubex/grpc_web/api/admin/v1/admin_pb.js");

  goog.object.extend(proto, api_admin_v1_admin_pb);
  goog.exportSymbol('proto.api.admin.v1.GetMarketDepthRequest', null, global);
  goog.exportSymbol('proto.api.admin.v1.GetMarketDepthResponse', null, global);
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */

  proto.api.admin.v1.GetMarketDepthRequest = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, proto.api.admin.v1.GetMarketDepthRequest.repeatedFields_, null);
  };

  goog.inherits(proto.api.admin.v1.GetMarketDepthRequest, jspb.Message);

  if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.api.admin.v1.GetMarketDepthRequest.displayName = 'proto.api.admin.v1.GetMarketDepthRequest';
  }
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */


  proto.api.admin.v1.GetMarketDepthResponse = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, proto.api.admin.v1.GetMarketDepthResponse.repeatedFields_, null);
  };

  goog.inherits(proto.api.admin.v1.GetMarketDepthResponse, jspb.Message);

  if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.api.admin.v1.GetMarketDepthResponse.displayName = 'proto.api.admin.v1.GetMarketDepthResponse';
  }
  /**
   * List of repeated fields within this message type.
   * @private {!Array<number>}
   * @const
   */


  proto.api.admin.v1.GetMarketDepthRequest.repeatedFields_ = [1];

  if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.api.admin.v1.GetMarketDepthRequest.prototype.toObject = function (opt_includeInstance) {
      return proto.api.admin.v1.GetMarketDepthRequest.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.api.admin.v1.GetMarketDepthRequest} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */


    proto.api.admin.v1.GetMarketDepthRequest.toObject = function (includeInstance, msg) {
      var f,
          obj = {
        marketSegmentIdsList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f
      };

      if (includeInstance) {
        obj.$jspbMessageInstance = msg;
      }

      return obj;
    };
  }
  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!proto.api.admin.v1.GetMarketDepthRequest}
   */


  proto.api.admin.v1.GetMarketDepthRequest.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.api.admin.v1.GetMarketDepthRequest();
    return proto.api.admin.v1.GetMarketDepthRequest.deserializeBinaryFromReader(msg, reader);
  };
  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!proto.api.admin.v1.GetMarketDepthRequest} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!proto.api.admin.v1.GetMarketDepthRequest}
   */


  proto.api.admin.v1.GetMarketDepthRequest.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }

      var field = reader.getFieldNumber();

      switch (field) {
        case 1:
          var values =
          /** @type {!Array<number>} */
          reader.isDelimited() ? reader.readPackedUint64() : [reader.readUint64()];

          for (var i = 0; i < values.length; i++) {
            msg.addMarketSegmentIds(values[i]);
          }

          break;

        default:
          reader.skipField();
          break;
      }
    }

    return msg;
  };
  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */


  proto.api.admin.v1.GetMarketDepthRequest.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.api.admin.v1.GetMarketDepthRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  };
  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!proto.api.admin.v1.GetMarketDepthRequest} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */


  proto.api.admin.v1.GetMarketDepthRequest.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getMarketSegmentIdsList();

    if (f.length > 0) {
      writer.writePackedUint64(1, f);
    }
  };
  /**
   * repeated uint64 market_segment_ids = 1;
   * @return {!Array<number>}
   */


  proto.api.admin.v1.GetMarketDepthRequest.prototype.getMarketSegmentIdsList = function () {
    return (
      /** @type {!Array<number>} */
      jspb.Message.getRepeatedField(this, 1)
    );
  };
  /**
   * @param {!Array<number>} value
   * @return {!proto.api.admin.v1.GetMarketDepthRequest} returns this
   */


  proto.api.admin.v1.GetMarketDepthRequest.prototype.setMarketSegmentIdsList = function (value) {
    return jspb.Message.setField(this, 1, value || []);
  };
  /**
   * @param {number} value
   * @param {number=} opt_index
   * @return {!proto.api.admin.v1.GetMarketDepthRequest} returns this
   */


  proto.api.admin.v1.GetMarketDepthRequest.prototype.addMarketSegmentIds = function (value, opt_index) {
    return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
  };
  /**
   * Clears the list making it empty but non-null.
   * @return {!proto.api.admin.v1.GetMarketDepthRequest} returns this
   */


  proto.api.admin.v1.GetMarketDepthRequest.prototype.clearMarketSegmentIdsList = function () {
    return this.setMarketSegmentIdsList([]);
  };
  /**
   * List of repeated fields within this message type.
   * @private {!Array<number>}
   * @const
   */


  proto.api.admin.v1.GetMarketDepthResponse.repeatedFields_ = [1];

  if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.api.admin.v1.GetMarketDepthResponse.prototype.toObject = function (opt_includeInstance) {
      return proto.api.admin.v1.GetMarketDepthResponse.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.api.admin.v1.GetMarketDepthResponse} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */


    proto.api.admin.v1.GetMarketDepthResponse.toObject = function (includeInstance, msg) {
      var f,
          obj = {
        marketDepthsList: jspb.Message.toObjectList(msg.getMarketDepthsList(), api_admin_v1_admin_pb.MarketDepth.toObject, includeInstance)
      };

      if (includeInstance) {
        obj.$jspbMessageInstance = msg;
      }

      return obj;
    };
  }
  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!proto.api.admin.v1.GetMarketDepthResponse}
   */


  proto.api.admin.v1.GetMarketDepthResponse.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.api.admin.v1.GetMarketDepthResponse();
    return proto.api.admin.v1.GetMarketDepthResponse.deserializeBinaryFromReader(msg, reader);
  };
  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!proto.api.admin.v1.GetMarketDepthResponse} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!proto.api.admin.v1.GetMarketDepthResponse}
   */


  proto.api.admin.v1.GetMarketDepthResponse.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }

      var field = reader.getFieldNumber();

      switch (field) {
        case 1:
          var value = new api_admin_v1_admin_pb.MarketDepth();
          reader.readMessage(value, api_admin_v1_admin_pb.MarketDepth.deserializeBinaryFromReader);
          msg.addMarketDepths(value);
          break;

        default:
          reader.skipField();
          break;
      }
    }

    return msg;
  };
  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */


  proto.api.admin.v1.GetMarketDepthResponse.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.api.admin.v1.GetMarketDepthResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  };
  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!proto.api.admin.v1.GetMarketDepthResponse} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */


  proto.api.admin.v1.GetMarketDepthResponse.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getMarketDepthsList();

    if (f.length > 0) {
      writer.writeRepeatedMessage(1, f, api_admin_v1_admin_pb.MarketDepth.serializeBinaryToWriter);
    }
  };
  /**
   * repeated MarketDepth market_depths = 1;
   * @return {!Array<!proto.api.admin.v1.MarketDepth>}
   */


  proto.api.admin.v1.GetMarketDepthResponse.prototype.getMarketDepthsList = function () {
    return (
      /** @type{!Array<!proto.api.admin.v1.MarketDepth>} */
      jspb.Message.getRepeatedWrapperField(this, api_admin_v1_admin_pb.MarketDepth, 1)
    );
  };
  /**
   * @param {!Array<!proto.api.admin.v1.MarketDepth>} value
   * @return {!proto.api.admin.v1.GetMarketDepthResponse} returns this
  */


  proto.api.admin.v1.GetMarketDepthResponse.prototype.setMarketDepthsList = function (value) {
    return jspb.Message.setRepeatedWrapperField(this, 1, value);
  };
  /**
   * @param {!proto.api.admin.v1.MarketDepth=} opt_value
   * @param {number=} opt_index
   * @return {!proto.api.admin.v1.MarketDepth}
   */


  proto.api.admin.v1.GetMarketDepthResponse.prototype.addMarketDepths = function (opt_value, opt_index) {
    return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.api.admin.v1.MarketDepth, opt_index);
  };
  /**
   * Clears the list making it empty but non-null.
   * @return {!proto.api.admin.v1.GetMarketDepthResponse} returns this
   */


  proto.api.admin.v1.GetMarketDepthResponse.prototype.clearMarketDepthsList = function () {
    return this.setMarketDepthsList([]);
  };

  goog.object.extend(exports, proto.api.admin.v1);
});