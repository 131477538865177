define("incubex/components/copy-risk-limits-confirmation", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{#modal-dialog}}
    <div class="copy-risk-confirmation-main">
      <div>
        <h1 class="text-lg mt-8 mb-8 font-bold text-base">Confirmation</h1>
      </div>
      <label class="text-base">Are you sure you want to copy the following from {{this.args.fromAccount.label}} and apply them to {{this.args.toAccount.t7_account}}?
      </label>
      <ul class="text-base mt-8 ml-16 list-disc">
        <li>
          Risk Limits
        </li>
        {{#if this.args.copyCurrentDayPoistionAlertSettings}}
          <li>
            Current Day Position Alert Settings
          </li>
        {{/if}}
      </ul>
  
      <div class="mt-16 flex flex-row justify-end">
        <Button
          @style="link"
          @class="m-8 text-base font-bold mr-24"
          @onClick={{this.args.onClose}}
          @id="close"
          @size="small"
        >
          No, go back
        </Button>
        <Button
          @class="m-8"
          @id="copy-risk-limits"
          @onClick={{this.args.onCopyRiskLimits}}
          @size="small"
        >
          Yes, copy
        </Button>
      </div>
    </div>
  {{/modal-dialog}}
  */
  {
    id: "GV5/vgq8",
    block: "{\"symbols\":[],\"statements\":[[4,\"modal-dialog\",null,null,{\"statements\":[[0,\"  \"],[7,\"div\",true],[10,\"class\",\"copy-risk-confirmation-main\"],[8],[0,\"\\n    \"],[7,\"div\",true],[8],[0,\"\\n      \"],[7,\"h1\",true],[10,\"class\",\"text-lg mt-8 mb-8 font-bold text-base\"],[8],[0,\"Confirmation\"],[9],[0,\"\\n    \"],[9],[0,\"\\n    \"],[7,\"label\",true],[10,\"class\",\"text-base\"],[8],[0,\"Are you sure you want to copy the following from \"],[1,[23,0,[\"args\",\"fromAccount\",\"label\"]],false],[0,\" and apply them to \"],[1,[23,0,[\"args\",\"toAccount\",\"t7_account\"]],false],[0,\"?\\n    \"],[9],[0,\"\\n    \"],[7,\"ul\",true],[10,\"class\",\"text-base mt-8 ml-16 list-disc\"],[8],[0,\"\\n      \"],[7,\"li\",true],[8],[0,\"\\n        Risk Limits\\n      \"],[9],[0,\"\\n\"],[4,\"if\",[[23,0,[\"args\",\"copyCurrentDayPoistionAlertSettings\"]]],null,{\"statements\":[[0,\"        \"],[7,\"li\",true],[8],[0,\"\\n          Current Day Position Alert Settings\\n        \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"    \"],[9],[0,\"\\n\\n    \"],[7,\"div\",true],[10,\"class\",\"mt-16 flex flex-row justify-end\"],[8],[0,\"\\n      \"],[5,\"button\",[],[[\"@style\",\"@class\",\"@onClick\",\"@id\",\"@size\"],[\"link\",\"m-8 text-base font-bold mr-24\",[23,0,[\"args\",\"onClose\"]],\"close\",\"small\"]],{\"statements\":[[0,\"\\n        No, go back\\n      \"]],\"parameters\":[]}],[0,\"\\n      \"],[5,\"button\",[],[[\"@class\",\"@id\",\"@onClick\",\"@size\"],[\"m-8\",\"copy-risk-limits\",[23,0,[\"args\",\"onCopyRiskLimits\"]],\"small\"]],{\"statements\":[[0,\"\\n        Yes, copy\\n      \"]],\"parameters\":[]}],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    meta: {
      moduleName: "incubex/components/copy-risk-limits-confirmation.hbs"
    }
  });

  class CopyRiskLimitsConfirmationComponent extends _component.default {}

  _exports.default = CopyRiskLimitsConfirmationComponent;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, CopyRiskLimitsConfirmationComponent);
});