define("incubex/components/x-tabs-tabs", ["exports", "incubex/templates/components/x-tabs-tabs"], function (_exports, _xTabsTabs) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _xTabsTabs.default,
    tagName: 'nav'
  });

  _exports.default = _default;
});