define("incubex/templates/components/x-tab/nav-wrap", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "HO2fj3CI",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"x-tab/nav-item\",null,[[\"paneId\",\"active\",\"selectAction\"],[[24,[\"paneId\"]],[28,\"eq\",[[24,[\"tab\",\"activeId\"]],[24,[\"paneId\"]]],null],[24,[\"tab\",\"selectAction\"]]]],{\"statements\":[[0,\"  \"],[14,1],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "incubex/templates/components/x-tab/nav-wrap.hbs"
    }
  });

  _exports.default = _default;
});