define("incubex/mirage/factories/user", ["exports", "ember-cli-mirage"], function (_exports, _emberCliMirage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberCliMirage.Factory.extend({
    afterCreate(user, server) {
      server.create('setting', {
        user,
        order_confirmation: true,
        order_cancellation_confirmation: true,
        timezone: 'America/Chicago'
      });
    }

  });

  _exports.default = _default;
});