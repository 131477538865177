define("incubex/components/layout/logged-out", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div id="layout-logged-out">
    <div class="left-container">
      <header class="pt-40">
        <h1 role="banner">
          <img src="/img/logo.png" width="200">
          <span class="screen-hidden">IncubEx - Building Tomorrow's Markets</span>
        </h1>
      </header>
  
      <main>
        <h2 class="heading-3 text-green-500">
          {{@pageTitle}}
        </h2>
        {{yield}}
      </main>
  
      <footer class="pb-40">
        <Copyright />
      </footer>
    </div>
  
    <div class="right-container" />
  </div>
  
  */
  {
    id: "bSvisoZ8",
    block: "{\"symbols\":[\"@pageTitle\",\"&default\"],\"statements\":[[7,\"div\",true],[10,\"id\",\"layout-logged-out\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"left-container\"],[8],[0,\"\\n    \"],[7,\"header\",true],[10,\"class\",\"pt-40\"],[8],[0,\"\\n      \"],[7,\"h1\",true],[10,\"role\",\"banner\"],[8],[0,\"\\n        \"],[7,\"img\",true],[10,\"src\",\"/img/logo.png\"],[10,\"width\",\"200\"],[8],[9],[0,\"\\n        \"],[7,\"span\",true],[10,\"class\",\"screen-hidden\"],[8],[0,\"IncubEx - Building Tomorrow's Markets\"],[9],[0,\"\\n      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n\\n    \"],[7,\"main\",true],[8],[0,\"\\n      \"],[7,\"h2\",true],[10,\"class\",\"heading-3 text-green-500\"],[8],[0,\"\\n        \"],[1,[23,1,[]],false],[0,\"\\n      \"],[9],[0,\"\\n      \"],[14,2],[0,\"\\n    \"],[9],[0,\"\\n\\n    \"],[7,\"footer\",true],[10,\"class\",\"pb-40\"],[8],[0,\"\\n      \"],[5,\"copyright\",[],[[],[]]],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\\n  \"],[7,\"div\",true],[10,\"class\",\"right-container\"],[8],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    meta: {
      moduleName: "incubex/components/layout/logged-out.hbs"
    }
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});