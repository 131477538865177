define("incubex/templates/password-reset", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "WOQiicxn",
    "block": "{\"symbols\":[],\"statements\":[[5,\"layout/logged-out\",[],[[\"@pageTitle\"],[\"Password reset\"]],{\"statements\":[[0,\"\\n\"],[4,\"if\",[[24,[\"isResetSentSuccess\"]]],null,{\"statements\":[[0,\"    \"],[7,\"div\",true],[10,\"data-role\",\"password-reset-success\"],[8],[0,\"\\n      \"],[7,\"p\",true],[10,\"class\",\"heading-6\"],[8],[0,\"Thanks for providing your email address.\"],[9],[0,\"\\n      \"],[7,\"p\",true],[10,\"class\",\"text-lg\"],[8],[0,\"\\n        Check your inbox for password reset instructions.\\n        If the email doesn't show up soon, check your SPAM folder.\\n      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[7,\"form\",true],[10,\"data-role\",\"password-reset-form\"],[11,\"onsubmit\",[28,\"action\",[[23,0,[]],\"onReset\"],null]],[8],[0,\"\\n      \"],[5,\"input/textbox\",[],[[\"@class\",\"@value\",\"@label\",\"@name\",\"@error\",\"@message\",\"@onFocusOut\"],[\"w-full mb-16\",[23,0,[\"email\"]],\"Email Address\",\"email\",[23,0,[\"emailError\"]],\"Please include a valid email address.\",[28,\"action\",[[23,0,[]],\"onValidateEmail\"],null]]]],[0,\"\\n\\n      \"],[7,\"div\",true],[10,\"class\",\"flex justify-between items-center\"],[8],[0,\"\\n        \"],[5,\"link-to\",[],[[\"@route\"],[\"login\"]],{\"statements\":[[0,\"\\n          Back to login\\n        \"]],\"parameters\":[]}],[0,\"\\n\\n        \"],[5,\"button\",[],[[\"@role\",\"@disabled\",\"@type\"],[\"reset-password\",[23,0,[\"isResetDisabled\"]],\"submit\"]],{\"statements\":[[0,\"\\n          Reset Password\\n        \"]],\"parameters\":[]}],[0,\"\\n      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[]}],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "incubex/templates/password-reset.hbs"
    }
  });

  _exports.default = _default;
});