define("incubex/templates/onboard/two-factor/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "h3LKAgoz",
    "block": "{\"symbols\":[],\"statements\":[[5,\"layout/logged-out\",[],[[\"@pageTitle\"],[\"Two-Factor Authentication\"]],{\"statements\":[[0,\"\\n  \"],[7,\"p\",true],[10,\"class\",\"mt-16 text-base \"],[8],[0,\"Enter your phone number\"],[9],[0,\"\\n  \"],[7,\"p\",true],[10,\"class\",\"mt-24\"],[8],[0,\"Please enter the phone number you will use to receive your two-factor authentication verification code.\"],[9],[0,\"\\n  \"],[7,\"p\",true],[10,\"class\",\"mt-16 text-base text-sm\"],[8],[0,\"Example US Number: 5550001234\"],[9],[0,\"\\n  \"],[7,\"p\",true],[10,\"class\",\"mt-8 text-base text-sm\"],[8],[0,\"Example International Number: +445550001234\"],[9],[0,\"\\n  \"],[5,\"input/textbox\",[],[[\"@class\",\"@error\",\"@label\",\"@message\",\"@name\",\"@type\",\"@value\",\"@onFocusOut\"],[\"w-full mt-24 lg:mr-8\",[23,0,[\"phoneError\"]],\"Two-Factor Authentication Phone Number\",\"Phone number is invalid.\",\"two-factor-phone\",\"tel\",[23,0,[\"phoneNumber\"]],[28,\"action\",[[23,0,[]],\"onValidatePhoneNumber\"],null]]]],[0,\"\\n  \"],[5,\"button\",[],[[\"@role\",\"@disabled\",\"@class\",\"@onClick\"],[\"two-factor-phone\",[28,\"not\",[[23,0,[\"isValidPhoneNumber\"]]],null],\"block mt-32\",[28,\"action\",[[23,0,[]],[23,0,[\"sendAuthorizationCode\"]]],null]]],{\"statements\":[[0,\"\\n    Continue\\n  \"]],\"parameters\":[]}],[0,\"\\n\\n\"]],\"parameters\":[]}],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "incubex/templates/onboard/two-factor/index.hbs"
    }
  });

  _exports.default = _default;
});