define("incubex/components/tab-content-time-sale", ["exports", "@glimmer/component", "incubex/utils/common", "lodash"], function (_exports, _component, _common, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _temp;

  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class="mt-8">
    <TimeSalesDataTable @tab={{this.tab}} @columns={{this.columns}} @rows={{this.filteredRows}} @refreshObject={{this.refreshObject}}></TimeSalesDataTable>
  </div>
  
  */
  {
    id: "NrCEZIO+",
    block: "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"mt-8\"],[8],[0,\"\\n  \"],[5,\"time-sales-data-table\",[],[[\"@tab\",\"@columns\",\"@rows\",\"@refreshObject\"],[[23,0,[\"tab\"]],[23,0,[\"columns\"]],[23,0,[\"filteredRows\"]],[23,0,[\"refreshObject\"]]]],{\"statements\":[],\"parameters\":[]}],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    meta: {
      moduleName: "incubex/components/tab-content-time-sale.hbs"
    }
  });

  let TabContentTimeSaleComponent = (_class = (_temp = class TabContentTimeSaleComponent extends _component.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "tab", _descriptor, this);

      _initializerDefineProperty(this, "columns", _descriptor2, this);

      _initializerDefineProperty(this, "rows", _descriptor3, this);

      _initializerDefineProperty(this, "activeOnly", _descriptor4, this);

      _initializerDefineProperty(this, "trades", _descriptor5, this);

      _initializerDefineProperty(this, "portfolioTab", _descriptor6, this);

      _initializerDefineProperty(this, "time", _descriptor7, this);

      _initializerDefineProperty(this, "instrument", _descriptor8, this);

      _initializerDefineProperty(this, "portfolioTabsService", _descriptor9, this);

      _initializerDefineProperty(this, "marketDataSocket", _descriptor10, this);

      _initializerDefineProperty(this, "blockTradeSocket", _descriptor11, this);

      if (this.marketDataSocket) {
        this.marketDataSocket.onTradeMessage(this.onScreenTrades.bind(this));
      }

      if (this.blockTradeSocket) {
        this.blockTradeSocket.onTradeMessage(this.onBlockTrades.bind(this));
      }

      this.portfolioTabsService.addListener(this.onChangeActiveTab.bind(this));
      this.portfolioTabsService.addListenerTns(this.onChangeActiveTabTnS.bind(this));
      this.refreshTrades();
    }

    _columns() {
      return [{
        name: 'Product Name',
        valuePath: 'short_name'
      }, {
        name: 'Expiration',
        valuePath: 'display_expiration'
      }, {
        name: 'Price',
        valuePath: 'price'
      }, {
        name: 'Volume',
        valuePath: 'volume'
      }, {
        name: 'Time',
        valuePath: 'time'
      }, {
        name: 'Type',
        valuePath: 'type'
      }];
    }

    get filteredRows() {
      let _rows = [];
      this.trades.forEach(trade => {
        _rows.push({
          short_name: trade.short_name || trade.product.short_name,
          display_expiration: trade.display_expiration ? trade.display_expiration.replace(' ', '') : null,
          instrument: trade.instrument_description,
          price: trade.price,
          volume: (0, _common.numberWithCommas)(trade.size),
          time: trade.time,
          type: (0, _lodash.startCase)((0, _lodash.lowerCase)(trade.trade_type))
        });
      });
      this.rows = [..._rows];
      return this.rows;
    }

    matchCriteria(tab, trade) {
      let instIds = [];

      if (tab.filter_active_portfolio) {
        var _this$portfolioTab, _this$portfolioTab$tr;

        let portfolioInsts = (_this$portfolioTab = this.portfolioTab) === null || _this$portfolioTab === void 0 ? void 0 : (_this$portfolioTab$tr = _this$portfolioTab.tradable_instrument_ids) === null || _this$portfolioTab$tr === void 0 ? void 0 : _this$portfolioTab$tr.split(',');

        if (Array.isArray(portfolioInsts)) {
          instIds.push(...portfolioInsts);
        }
      }

      if (Array.isArray(tab.instrumentIds)) {
        instIds.push(...tab.instrumentIds);
      }

      if ((tab.product_type.toUpperCase() == '' || tab.product_type.toUpperCase() == 'ALL' || tab.product_type.toUpperCase() == (trade.product_type ? trade.product_type.toUpperCase() : trade.product.product_type.toUpperCase())) && (tab.product_group.toUpperCase() == '' || tab.product_group.toUpperCase() == 'ALL' || tab.product_group.toUpperCase() == (trade.group ? trade.group.toUpperCase() : trade.product.group.toUpperCase())) && (tab.trade_type.toUpperCase() == '' || tab.trade_type.toUpperCase() == 'ALL' || tab.trade_type.toUpperCase() == trade.trade_type.toUpperCase()) && (instIds.length == 0 ? true : instIds.indexOf(String(trade.id)) >= 0)) {
        return true;
      }

      return false;
    }

    matchCriteriaBlockTrade(tab, trade) {
      if ((tab.product_type.toUpperCase() == '' || tab.product_type.toUpperCase() == 'ALL' || tab.product_type.toUpperCase() == trade.product_type.toUpperCase()) && (tab.product_group.toUpperCase() == '' || tab.product_group.toUpperCase() == 'ALL' || tab.product_group.toUpperCase() == trade.group.toUpperCase()) && (tab.trade_type.toUpperCase() == '' || tab.trade_type.toUpperCase() == 'ALL' || tab.trade_type.toUpperCase() == trade.trade_type.toUpperCase()) && (!tab.filter_active_portfolio || this.ActivePortfolioHasSameInstrument(trade))) {
        return true;
      }

      return false;
    }

    onScreenTrades(data, isRefresh = false) {
      let updates = data.filter(update => {
        return update.trade;
      });
      updates.forEach((update, index) => {
        this.updateScreenTrade(update.trade, isRefresh ? false : index == updates.length - 1);
      });
    }

    onBlockTrades(trades, isRefresh = false) {
      trades.forEach((trade, index) => {
        this.updateBlockTrade(trade, isRefresh ? false : index == trades.length - 1);
      });
    }

    async updateBlockTrade(trade, updateGrid = true) {
      if (!this.isActiveTab()) {
        return false;
      }

      if (this.matchCriteriaBlockTrade(this.args.tab, trade)) {
        // TODO: Verify if trade really needs to be broken below
        this.trades.splice(0, 0, _objectSpread({}, trade));

        if (updateGrid) {
          this.trades = [...this.trades];
        }
      }
    }

    ActivePortfolioHasSameInstrument(trade) {
      let instrumentIndex = -1;

      if (!!this.portfolioTab && Array.isArray(this.portfolioTab.tradable_instruments)) {
        instrumentIndex = this.getInstrument(this.portfolioTab.tradable_instruments, trade.marketSegmentId, trade.securityId);
      } else {
        var _this$portfolioTab2;

        if (typeof ((_this$portfolioTab2 = this.portfolioTab) === null || _this$portfolioTab2 === void 0 ? void 0 : _this$portfolioTab2.tradable_instrument_ids) === 'string') {
          instrumentIndex = this.portfolioTab.tradable_instrument_ids.split(',').indexOf("".concat(trade.id));
        }
      }

      if (instrumentIndex > -1) return true;
      return false;
    }

    async updateScreenTrade(trade, updateGrid = true) {
      if (!this.isActiveTab()) {
        return false;
      }

      if (this.matchCriteria(this.args.tab, trade)) {
        this.trades.splice(0, 0, _objectSpread({}, trade));
      }

      if (updateGrid) {
        this.trades = [...this.trades];
      }
    }

    getInstrument(instruments, marketSegmentId, securityId) {
      let index = (instruments || []).findIndex(_item => _item.market_segment_id == marketSegmentId && _item.security_id == securityId);
      return index;
    }

    onChangeActiveTab(tab) {
      this.portfolioTab = tab;
      this.refreshTrades();
    }

    onChangeActiveTabTnS() {
      this.refreshTrades();
    }

    refreshTrades() {
      this.trades = [];

      if (!this.isActiveTab()) {
        return;
      }

      if (this.marketDataSocket) {
        this.onScreenTrades(this.marketDataSocket.trades, true);
      }

      if (this.blockTradeSocket) {
        this.onBlockTrades(this.blockTradeSocket.trades, true);
      }

      this.trades = [...this.trades];
    }

    isActiveTab() {
      if (this.args.tab.id == this.args.activeName) {
        return true;
      }

      return false;
    }

    getReferenceValue(reference, referenceField, feedValue, fallbackValue) {
      if (reference && reference[referenceField]) {
        return reference[referenceField];
      } else {
        return feedValue || fallbackValue || '';
      }
    }

    refreshObject() {
      this.rows = [...this.rows];
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "tab", [Ember._tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return this.args.tab;
    }
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "columns", [Ember._tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return this._columns();
    }
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "rows", [Ember._tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return [];
    }
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "activeOnly", [Ember._tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return this.tab.filter_active_portfolio;
    }
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "trades", [Ember._tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return [];
    }
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "portfolioTab", [Ember._tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "time", [Ember.inject.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "instrument", [Ember.inject.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "portfolioTabsService", [Ember.inject.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "marketDataSocket", [Ember.inject.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "blockTradeSocket", [Ember.inject.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "refreshObject", [Ember._action], Object.getOwnPropertyDescriptor(_class.prototype, "refreshObject"), _class.prototype)), _class);
  _exports.default = TabContentTimeSaleComponent;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, TabContentTimeSaleComponent);
});