define("incubex/routes/password-reset", ["exports", "incubex/routes/unauthenticated"], function (_exports, _unauthenticated) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class PasswordResetRoute extends _unauthenticated.default {}

  _exports.default = PasswordResetRoute;
});