define("incubex/grpc_web/api/health/v1/health_api_pb", [], function () {
  "use strict";

  // source: api/health/v1/health_api.proto

  /**
   * @fileoverview
   * @enhanceable
   * @suppress {missingRequire} reports error on implicit type usages.
   * @suppress {messageConventions} JS Compiler reports an error if a variable or
   *     field starts with 'MSG_' and isn't a translatable message.
   * @public
   */
  // GENERATED CODE -- DO NOT EDIT!

  /* eslint-disable */
  // @ts-nocheck
  var jspb = require("google-protobuf");

  var goog = jspb;

  var global = typeof globalThis !== 'undefined' && globalThis || typeof window !== 'undefined' && window || typeof global !== 'undefined' && global || typeof self !== 'undefined' && self || function () {
    return this;
  }.call(null) || Function('return this')();

  goog.exportSymbol('proto.api.health.v1.HealthCheckRequest', null, global);
  goog.exportSymbol('proto.api.health.v1.HealthCheckResponse', null, global);
  goog.exportSymbol('proto.api.health.v1.HealthCheckResponse.ServingStatus', null, global);
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */

  proto.api.health.v1.HealthCheckRequest = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  };

  goog.inherits(proto.api.health.v1.HealthCheckRequest, jspb.Message);

  if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.api.health.v1.HealthCheckRequest.displayName = 'proto.api.health.v1.HealthCheckRequest';
  }
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */


  proto.api.health.v1.HealthCheckResponse = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  };

  goog.inherits(proto.api.health.v1.HealthCheckResponse, jspb.Message);

  if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.api.health.v1.HealthCheckResponse.displayName = 'proto.api.health.v1.HealthCheckResponse';
  }

  if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.api.health.v1.HealthCheckRequest.prototype.toObject = function (opt_includeInstance) {
      return proto.api.health.v1.HealthCheckRequest.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.api.health.v1.HealthCheckRequest} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */


    proto.api.health.v1.HealthCheckRequest.toObject = function (includeInstance, msg) {
      var f,
          obj = {
        service: jspb.Message.getFieldWithDefault(msg, 1, "")
      };

      if (includeInstance) {
        obj.$jspbMessageInstance = msg;
      }

      return obj;
    };
  }
  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!proto.api.health.v1.HealthCheckRequest}
   */


  proto.api.health.v1.HealthCheckRequest.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.api.health.v1.HealthCheckRequest();
    return proto.api.health.v1.HealthCheckRequest.deserializeBinaryFromReader(msg, reader);
  };
  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!proto.api.health.v1.HealthCheckRequest} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!proto.api.health.v1.HealthCheckRequest}
   */


  proto.api.health.v1.HealthCheckRequest.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }

      var field = reader.getFieldNumber();

      switch (field) {
        case 1:
          var value =
          /** @type {string} */
          reader.readString();
          msg.setService(value);
          break;

        default:
          reader.skipField();
          break;
      }
    }

    return msg;
  };
  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */


  proto.api.health.v1.HealthCheckRequest.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.api.health.v1.HealthCheckRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  };
  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!proto.api.health.v1.HealthCheckRequest} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */


  proto.api.health.v1.HealthCheckRequest.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getService();

    if (f.length > 0) {
      writer.writeString(1, f);
    }
  };
  /**
   * optional string service = 1;
   * @return {string}
   */


  proto.api.health.v1.HealthCheckRequest.prototype.getService = function () {
    return (
      /** @type {string} */
      jspb.Message.getFieldWithDefault(this, 1, "")
    );
  };
  /**
   * @param {string} value
   * @return {!proto.api.health.v1.HealthCheckRequest} returns this
   */


  proto.api.health.v1.HealthCheckRequest.prototype.setService = function (value) {
    return jspb.Message.setProto3StringField(this, 1, value);
  };

  if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.api.health.v1.HealthCheckResponse.prototype.toObject = function (opt_includeInstance) {
      return proto.api.health.v1.HealthCheckResponse.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.api.health.v1.HealthCheckResponse} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */


    proto.api.health.v1.HealthCheckResponse.toObject = function (includeInstance, msg) {
      var f,
          obj = {
        status: jspb.Message.getFieldWithDefault(msg, 1, 0)
      };

      if (includeInstance) {
        obj.$jspbMessageInstance = msg;
      }

      return obj;
    };
  }
  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!proto.api.health.v1.HealthCheckResponse}
   */


  proto.api.health.v1.HealthCheckResponse.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.api.health.v1.HealthCheckResponse();
    return proto.api.health.v1.HealthCheckResponse.deserializeBinaryFromReader(msg, reader);
  };
  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!proto.api.health.v1.HealthCheckResponse} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!proto.api.health.v1.HealthCheckResponse}
   */


  proto.api.health.v1.HealthCheckResponse.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }

      var field = reader.getFieldNumber();

      switch (field) {
        case 1:
          var value =
          /** @type {!proto.api.health.v1.HealthCheckResponse.ServingStatus} */
          reader.readEnum();
          msg.setStatus(value);
          break;

        default:
          reader.skipField();
          break;
      }
    }

    return msg;
  };
  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */


  proto.api.health.v1.HealthCheckResponse.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.api.health.v1.HealthCheckResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  };
  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!proto.api.health.v1.HealthCheckResponse} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */


  proto.api.health.v1.HealthCheckResponse.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getStatus();

    if (f !== 0.0) {
      writer.writeEnum(1, f);
    }
  };
  /**
   * @enum {number}
   */


  proto.api.health.v1.HealthCheckResponse.ServingStatus = {
    SERVING_STATUS_INVALID: 0,
    SERVING_STATUS_UNKNOWN: 1,
    SERVING_STATUS_OK: 2,
    SERVING_STATUS_NOT_SERVING: 3
  };
  /**
   * optional ServingStatus status = 1;
   * @return {!proto.api.health.v1.HealthCheckResponse.ServingStatus}
   */

  proto.api.health.v1.HealthCheckResponse.prototype.getStatus = function () {
    return (
      /** @type {!proto.api.health.v1.HealthCheckResponse.ServingStatus} */
      jspb.Message.getFieldWithDefault(this, 1, 0)
    );
  };
  /**
   * @param {!proto.api.health.v1.HealthCheckResponse.ServingStatus} value
   * @return {!proto.api.health.v1.HealthCheckResponse} returns this
   */


  proto.api.health.v1.HealthCheckResponse.prototype.setStatus = function (value) {
    return jspb.Message.setProto3EnumField(this, 1, value);
  };

  goog.object.extend(exports, proto.api.health.v1);
});