define("incubex/components/button", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <button
    {{class
      (concat "button-component-" this.style)
      @class
      disabled=@disabled
      large=this.isLarge
      small=this.isSmall
      with-icon=@icon
    }}
    id={{@id}}
    data-role={{this.role}}
    disabled={{@disabled}}
    type={{this.type}}
  
    onclick={{action "onClick"}}
  >
    {{#if this.isSecondaryIcon}}
      <div class="__icon-box">
        {{svg-jar @icon class="__icon"}}
      </div>
    {{/if}}
  
    {{yield}}
  
    {{#if this.isPrimaryIcon}}
      <div class="__icon-box">
        {{svg-jar @icon class="__icon"}}
      </div>
    {{/if}}
  </button>
  
  */
  {
    id: "HUm0H6lE",
    block: "{\"symbols\":[\"@icon\",\"@id\",\"@disabled\",\"@class\",\"&default\"],\"statements\":[[7,\"button\",false],[12,\"id\",[23,2,[]]],[12,\"data-role\",[23,0,[\"role\"]]],[12,\"disabled\",[23,3,[]]],[12,\"onclick\",[28,\"action\",[[23,0,[]],\"onClick\"],null]],[12,\"type\",[23,0,[\"type\"]]],[3,\"class\",[[28,\"concat\",[\"button-component-\",[23,0,[\"style\"]]],null],[23,4,[]]],[[\"disabled\",\"large\",\"small\",\"with-icon\"],[[23,3,[]],[23,0,[\"isLarge\"]],[23,0,[\"isSmall\"]],[23,1,[]]]]],[8],[0,\"\\n\"],[4,\"if\",[[23,0,[\"isSecondaryIcon\"]]],null,{\"statements\":[[0,\"    \"],[7,\"div\",true],[10,\"class\",\"__icon-box\"],[8],[0,\"\\n      \"],[1,[28,\"svg-jar\",[[23,1,[]]],[[\"class\"],[\"__icon\"]]],false],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n  \"],[14,5],[0,\"\\n\\n\"],[4,\"if\",[[23,0,[\"isPrimaryIcon\"]]],null,{\"statements\":[[0,\"    \"],[7,\"div\",true],[10,\"class\",\"__icon-box\"],[8],[0,\"\\n      \"],[1,[28,\"svg-jar\",[[23,1,[]]],[[\"class\"],[\"__icon\"]]],false],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[9],[0,\"\\n\"]],\"hasEval\":false}",
    meta: {
      moduleName: "incubex/components/button.hbs"
    }
  });

  const style = Object.freeze({
    LINK: 'link',
    PRIMARY: 'primary',
    SECONDARY: 'secondary'
  });
  const size = Object.freeze({
    LARGE: 'large',
    SMALL: 'small'
  });
  let ButtonComponent = (_class = class ButtonComponent extends _component.default {
    get bubbles() {
      return this.args.bubbles ? true : false;
    }

    get isLarge() {
      return this.args.size === size.LARGE;
    }

    get isSmall() {
      return this.args.size === size.SMALL;
    }

    get isPrimaryIcon() {
      return this.args.icon && this.style === style.PRIMARY;
    }

    get isSecondaryIcon() {
      return this.args.icon && this.style === style.SECONDARY;
    }

    get role() {
      return this.args.role || 'button';
    }

    get style() {
      return this.args.style || 'primary';
    }

    get type() {
      return this.args.type || 'button';
    }

    onClick(event) {
      if (!this.bubbles) {
        event.stopPropagation();
      }

      if (this.args.onClick) {
        this.args.onClick();
      }
    }

  }, (_applyDecoratedDescriptor(_class.prototype, "onClick", [Ember._action], Object.getOwnPropertyDescriptor(_class.prototype, "onClick"), _class.prototype)), _class);
  _exports.default = ButtonComponent;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, ButtonComponent);
});