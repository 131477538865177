define("incubex/services/onboarding", ["exports", "incubex/types/url", "incubex/types/user-role"], function (_exports, _url, _userRole) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class, _descriptor, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const ONBOARDING_TOKEN = 'onboarding_token';
  const ONBOARDING_AUTH_PHONE = 'onboarding_auth_phone';
  const ONBOARDING_AUTH_RECOVERYCODES = 'onboarding_auth_recoverycodes';
  let OnboardUserService = (_class = (_temp = class OnboardUserService extends Ember.Service {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "api", _descriptor, this);
    }

    loadToken() {
      let params = new URLSearchParams(document.location.search);
      this.token = params.get(ONBOARDING_TOKEN) || this.token;
    }

    get token() {
      return localStorage.getItem(ONBOARDING_TOKEN);
    }

    set token(token) {
      // Test if token is present, or this will
      // save as the string 'null'
      if (Ember.isPresent(token)) {
        return localStorage.setItem(ONBOARDING_TOKEN, token);
      }
    }

    get headers() {
      return {
        Authentication: this.token
      };
    }

    get isViewOnlyUser() {
      return this.data && this.data.type === _userRole.default.VIEW_ONLY || this.data.type === _userRole.default.ADMIN;
    }

    get isClearedUser() {
      return this.data && this.data.type === _userRole.default.CLEARED || this.data.type === _userRole.default.ADMIN;
    }

    get status() {
      return this.data && this.data.onboardingStatus;
    }

    clearToken() {
      localStorage.removeItem(ONBOARDING_TOKEN);
    }

    async _send(url, data) {
      try {
        return await this.api.post(url, data, this.headers);
      } catch (error) {
        throw Error(error);
      }
    }

    sendPassword(data) {
      return this._send(_url.default.ONBOARD_USER_PASSWORD, data);
    }

    sendCredentials(data) {
      return this._send(_url.default.ONBOARD_USER_CREDENTIALS, data);
    }

    async load() {
      this.data = await this.api.get(_url.default.ONBOARD_USER, this.headers);
    }

    sendAuthCode(data) {
      return this._send(_url.default.ONBOARD_MFA, data);
    }

    verifyAuthCode(data) {
      return this._send(_url.default.ONBOARD_MFA_VERIFY, data);
    }

    getRecoveryCodes(data) {
      return this._send(_url.default.ONBOARD_MFA_RECOVERY_CODES, data);
    }

    completeTwoFactorAtuh() {
      return this._send(_url.default.ONBOARD_MFA_CONFIRM);
    }

    savePhoneToStorage(phoneNumber) {
      localStorage.setItem(ONBOARDING_AUTH_PHONE, phoneNumber);
    }

    getPhoneFromStorage() {
      let phoneNumber = localStorage.getItem(ONBOARDING_AUTH_PHONE);
      localStorage.removeItem(ONBOARDING_AUTH_PHONE);
      return phoneNumber;
    }

    saveRecoveryCodesToStorage(codes) {
      localStorage.setItem(ONBOARDING_AUTH_RECOVERYCODES, codes);
    }

    getRecoveryCodesFromStorage() {
      let codes = localStorage.getItem(ONBOARDING_AUTH_RECOVERYCODES);
      localStorage.removeItem(ONBOARDING_AUTH_RECOVERYCODES);
      return codes;
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "api", [Ember.inject.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class);
  _exports.default = OnboardUserService;
});