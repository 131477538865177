define("incubex/mirage/fixtures/onboarding-data", ["exports", "incubex/types/onboarding-status", "incubex/types/user-role"], function (_exports, _onboardingStatus, _userRole) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    email: 'admin@example.com',
    fax: null,
    first_name: 'Admin',
    last_name: 'Connamara',
    work_phone: null,
    onboarding_status: _onboardingStatus.default.PASSWORD,
    type: _userRole.default.CLEARED,
    company_name: 'Smiley Trading Co',
    company_address: '123 Sesame St.',
    company_city: 'New York',
    company_state: 'NY',
    company_zipcode: '10101',
    business_unit: {
      name: 'Unit Five',
      clearing_accounts: [{
        id: 1,
        t7_account: 'Clearing Account #1'
      }, {
        id: 1,
        t7_account: 'Clearing Account #2'
      }]
    }
  };
  _exports.default = _default;
});