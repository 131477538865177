define("incubex/components/x-tabs-pane", ["exports", "incubex/templates/components/x-tabs-pane"], function (_exports, _xTabsPane) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _xTabsPane.default,
    classNameBindings: ['isActive:content-current'],
    tagName: 'section',
    name: undefined,
    isActive: Ember.computed('name', 'api.activeName', {
      get() {
        return this.get('name') === this.get('api.activeName');
      }

    })
  });

  _exports.default = _default;
});