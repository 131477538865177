define("incubex/components/input/radio", ["exports", "@glimmer/component", "ember-cli-guid"], function (_exports, _component, _emberCliGuid) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <label
    {{class
      "input-radio-component"
      @class
      checked=this.checked
      disabled=@disabled
    }}
    data-ui-component="input-radio"
    for={{this.guid}}
  >
    <div class="__radio-box">
      <div class="__radio" />
    </div>
    {{#if hasBlock}}
      {{yield}}
    {{else}}
      {{@label}}
    {{/if}}
    <Input
      checked={{this.checked}}
      @disabled={{@disabled}}
      @id={{this.guid}}
      @name={{@name}}
      @type="radio"
      @value={{@value}}
  
      @change={{fn (mut @groupValue) @value}}
    />
  </label>
  
  */
  {
    id: "SRB7P1rv",
    block: "{\"symbols\":[\"@label\",\"&default\",\"@disabled\",\"@class\",\"@name\",\"@value\",\"@groupValue\"],\"statements\":[[7,\"label\",false],[12,\"data-ui-component\",\"input-radio\"],[12,\"for\",[23,0,[\"guid\"]]],[3,\"class\",[\"input-radio-component\",[23,4,[]]],[[\"checked\",\"disabled\"],[[23,0,[\"checked\"]],[23,3,[]]]]],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"__radio-box\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"__radio\"],[8],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[4,\"if\",[[25,2]],null,{\"statements\":[[0,\"    \"],[14,2],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[1,[23,1,[]],false],[0,\"\\n\"]],\"parameters\":[]}],[0,\"  \"],[5,\"input\",[[12,\"checked\",[23,0,[\"checked\"]]]],[[\"@disabled\",\"@id\",\"@name\",\"@type\",\"@value\",\"@change\"],[[23,3,[]],[23,0,[\"guid\"]],[23,5,[]],\"radio\",[23,6,[]],[28,\"fn\",[[28,\"mut\",[[23,7,[]]],null],[23,6,[]]],null]]]],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    meta: {
      moduleName: "incubex/components/input/radio.hbs"
    }
  });

  class InputRadioComponent extends _component.default {
    constructor() {
      super(...arguments);
      (true && !(this.args.name) && Ember.assert('<RadioGroup> requires a name', this.args.name));
      (true && !(this.args.value) && Ember.assert('<Radio> requires a value', this.args.value));
    }

    get checked() {
      return this.args.groupValue === this.args.value;
    }

    get guid() {
      let guid = _emberCliGuid.default.create();

      return _emberCliGuid.default.compact(guid);
    }

  }

  _exports.default = InputRadioComponent;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, InputRadioComponent);
});