define("incubex/components/filter-button", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class, _descriptor, _descriptor2, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <Button @icon={{this.icon}} @class={{this.class}} @id={{this.id}} @name={{this.name}} @onClick={{action this.onClick}} >
    {{yield}}
  </Button>
  */
  {
    id: "C6/0P/eK",
    block: "{\"symbols\":[\"&default\"],\"statements\":[[5,\"button\",[],[[\"@icon\",\"@class\",\"@id\",\"@name\",\"@onClick\"],[[23,0,[\"icon\"]],[23,0,[\"class\"]],[23,0,[\"id\"]],[23,0,[\"name\"]],[28,\"action\",[[23,0,[]],[23,0,[\"onClick\"]]],null]]],{\"statements\":[[0,\"\\n  \"],[14,1],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    meta: {
      moduleName: "incubex/components/filter-button.hbs"
    }
  });

  let FilterButtonComponent = (_class = (_temp = class FilterButtonComponent extends _component.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "isOpen", _descriptor, this);

      _initializerDefineProperty(this, "filterApplied", _descriptor2, this);

      this.id = this.args.id;
      this.name = this.args.name;
    }

    onClick() {
      if (this.args.onClick) {
        this.args.onClick();
      }
    }

    get class() {
      let response = 'filter-btn';

      if (this.args.isOpen) {
        response = response + ' filter-btn-isOpen';
      }

      if (this.args.filterApplied) {
        response = response + ' filter-btn-filterApplied';
      }

      if (this.args.class) {
        response = response + " ".concat(this.args.class);
      }

      return response;
    }

    get icon() {
      if (this.args.filterApplied) {
        return 'close-36';
      }

      return 'arrow-down';
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "isOpen", [Ember._tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return false;
    }
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "filterApplied", [Ember._tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return false;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "onClick", [Ember._action], Object.getOwnPropertyDescriptor(_class.prototype, "onClick"), _class.prototype)), _class);
  _exports.default = FilterButtonComponent;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, FilterButtonComponent);
});