define("incubex/templates/components/x-tabs-pane-data", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "2pMBC7kQ",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[7,\"section\",true],[10,\"class\",\"content-current\"],[8],[0,\"\\n  \"],[14,1,[[28,\"hash\",null,[[\"api\",\"data\"],[[24,[\"api\"]],[24,[\"api\",\"activeData\"]]]]]]],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "incubex/templates/components/x-tabs-pane-data.hbs"
    }
  });

  _exports.default = _default;
});