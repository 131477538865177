define("incubex/components/x-tree-branch", ["exports", "incubex/components/x-tree-branch"], function (_exports, _xTreeBranch) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div>
  {{#vertical-collection model renderAll=false estimateHeight=270 as |child|}}
    {{#if child.isVisible}}
      {{#if hasBlock}}
        {{#x-tree-children
          checkable=checkable
          recursiveCheck=recursiveCheck
          updateCheckbox=updateCheckbox
          chosenId=chosenId
          onCheck=onCheck
          onContextMenu=onContextMenu
          onSelect=onSelect
          onHover=onHover
          onHoverOut=onHoverOut
          model=child
          expandedIcon=expandedIcon
          collapsedIcon=collapsedIcon
          onToggle=onToggle
          as |node|
        }}
          {{yield node}}
        {{/x-tree-children}}
      {{else}}
        {{x-tree-children
          model=child
          checkable=checkable
          recursiveCheck=recursiveCheck
          updateCheckbox=updateCheckbox
          chosenId=chosenId
          onCheck=onCheck
          onContextMenu=onContextMenu
          onSelect=onSelect
          onHover=onHover
          onHoverOut=onHoverOut
          expandedIcon=expandedIcon
          collapsedIcon=collapsedIcon
          onToggle=onToggle
        }}
      {{/if}}
    {{/if}}
  {{/vertical-collection}}
  </div>
  */
  {
    id: "7dnBatoo",
    block: "{\"symbols\":[\"child\",\"node\",\"&default\"],\"statements\":[[7,\"div\",true],[8],[0,\"\\n\"],[4,\"vertical-collection\",[[24,[\"model\"]]],[[\"renderAll\",\"estimateHeight\"],[false,270]],{\"statements\":[[4,\"if\",[[23,1,[\"isVisible\"]]],null,{\"statements\":[[4,\"if\",[[25,3]],null,{\"statements\":[[4,\"x-tree-children\",null,[[\"checkable\",\"recursiveCheck\",\"updateCheckbox\",\"chosenId\",\"onCheck\",\"onContextMenu\",\"onSelect\",\"onHover\",\"onHoverOut\",\"model\",\"expandedIcon\",\"collapsedIcon\",\"onToggle\"],[[24,[\"checkable\"]],[24,[\"recursiveCheck\"]],[24,[\"updateCheckbox\"]],[24,[\"chosenId\"]],[24,[\"onCheck\"]],[24,[\"onContextMenu\"]],[24,[\"onSelect\"]],[24,[\"onHover\"]],[24,[\"onHoverOut\"]],[23,1,[]],[24,[\"expandedIcon\"]],[24,[\"collapsedIcon\"]],[24,[\"onToggle\"]]]],{\"statements\":[[0,\"        \"],[14,3,[[23,2,[]]]],[0,\"\\n\"]],\"parameters\":[2]},null]],\"parameters\":[]},{\"statements\":[[0,\"      \"],[1,[28,\"x-tree-children\",null,[[\"model\",\"checkable\",\"recursiveCheck\",\"updateCheckbox\",\"chosenId\",\"onCheck\",\"onContextMenu\",\"onSelect\",\"onHover\",\"onHoverOut\",\"expandedIcon\",\"collapsedIcon\",\"onToggle\"],[[23,1,[]],[24,[\"checkable\"]],[24,[\"recursiveCheck\"]],[24,[\"updateCheckbox\"]],[24,[\"chosenId\"]],[24,[\"onCheck\"]],[24,[\"onContextMenu\"]],[24,[\"onSelect\"]],[24,[\"onHover\"]],[24,[\"onHoverOut\"]],[24,[\"expandedIcon\"]],[24,[\"collapsedIcon\"]],[24,[\"onToggle\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[]},null]],\"parameters\":[1]},null],[9]],\"hasEval\":false}",
    meta: {
      moduleName: "incubex/components/x-tree-branch.hbs"
    }
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember.Component.extend({
    layout: _xTreeBranch.default,
    tagName: 'ul',
    classNames: ['tree-branch']
  }));

  _exports.default = _default;
});