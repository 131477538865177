define("incubex/controllers/authenticated/risk-positions", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _descriptor13, _descriptor14, _descriptor15, _descriptor16, _descriptor17, _descriptor18, _descriptor19, _descriptor20, _descriptor21, _descriptor22, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let AuthenticatedRiskPositionsController = (_class = (_temp = class AuthenticatedRiskPositionsController extends Ember.Controller {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "currentUser", _descriptor, this);

      _initializerDefineProperty(this, "sessionApi", _descriptor2, this);

      _initializerDefineProperty(this, "notification", _descriptor3, this);

      _initializerDefineProperty(this, "instrument", _descriptor4, this);

      _initializerDefineProperty(this, "time", _descriptor5, this);

      _initializerDefineProperty(this, "api", _descriptor6, this);

      _initializerDefineProperty(this, "riskPositions", _descriptor7, this);

      _initializerDefineProperty(this, "businessUnit", _descriptor8, this);

      _initializerDefineProperty(this, "selectedClearingAccount", _descriptor9, this);

      _initializerDefineProperty(this, "startDate", _descriptor10, this);

      _initializerDefineProperty(this, "endDate", _descriptor11, this);

      _initializerDefineProperty(this, "rows", _descriptor12, this);

      _initializerDefineProperty(this, "requestInProgress", _descriptor13, this);

      _initializerDefineProperty(this, "clearingAccounts", _descriptor14, this);

      _initializerDefineProperty(this, "productFilter", _descriptor15, this);

      _initializerDefineProperty(this, "productGroupFilter", _descriptor16, this);

      _initializerDefineProperty(this, "productSubGroupFilter", _descriptor17, this);

      _initializerDefineProperty(this, "execTypesFilter", _descriptor18, this);

      _initializerDefineProperty(this, "orderIdFilter", _descriptor19, this);

      _initializerDefineProperty(this, "paginationData", _descriptor20, this);

      _initializerDefineProperty(this, "previousRequest", _descriptor21, this);

      _initializerDefineProperty(this, "filteredRows", _descriptor22, this);

      _defineProperty(this, "showBreachedLimitsOnly", false);
    }

    get exchanges() {
      return [{
        label: 'NODAL',
        value: 1
      }];
    }

    get execTypes() {
      return this.currentUser.ordersMetaData.exec_types.map(item => {
        return {
          label: item,
          value: item
        };
      });
    }

    get products() {
      return this.currentUser.productsMetaData.ids.map(item => {
        return {
          label: item[0],
          value: item[2]
        };
      });
    }

    get productGroups() {
      return this.currentUser.productsMetaData.groups.map(item => {
        return {
          label: item,
          value: item
        };
      });
    }

    get productSubGroups() {
      return this.currentUser.productsMetaData.sub_groups.map(item => {
        return {
          label: item,
          value: item
        };
      });
    }

    get isClearingAdmin() {
      this.showBreachedLimitsOnly = false;
      return this.currentUser.user.isClearingAdmin;
    }

    get businessUnits() {
      return this.currentUser.businessUnits || [];
    }

    async onBusinessUnitSelect(businessUnit) {
      this.businessUnit = businessUnit;
      this.selectedClearingAccount = null;
    }

    async onClearingAccountSelect(account) {
      this.selectedClearingAccount = account;
    }

    get clearingAccount() {
      if (!this.selectedClearingAccount) return null;
      let account = this.clearingAccounts.find(acc => acc.id === this.selectedClearingAccount.value);
      return account;
    }

    get breachLimitToggle() {
      return this.showBreachedLimitsOnly;
    }

    set breachLimitToggle(value) {
      this.showBreachedLimitsOnly = value;
      this.onRequestHistory();
    }

    async onRequestHistory() {
      try {
        let business_unit = this.businessUnits.find(unit => unit.id === this.selectedClearingAccount.business_unit_id).t7_business_unit_id;
        this.requestInProgress = true;
        let risk_positions = await this.riskPositions.fetch(business_unit, this.showBreachedLimitsOnly);
        this.rows = risk_positions;
        this.filter();
      } catch (e) {
        this.notification.error('could not fetch risk positions'); // eslint-disable-next-line no-console

        console.error(e);
      } finally {
        this.requestInProgress = false;
      }
    }

    filter() {
      let filteredRows = [...this.rows];

      if (this.productFilter && this.productFilter.length) {
        filteredRows = filteredRows.filter(row => this.productFilter.find(filter => filter.value == row.id.market_segment_id));
      }

      if (this.productGroupFilter && this.productGroupFilter.length) {
        filteredRows = filteredRows.filter(row => this.productGroupFilter.find(filter => filter.value.toUpperCase() === (row.group || '').toUpperCase()));
      }

      if (this.productSubGroupFilter && this.productSubGroupFilter.length) {
        filteredRows = filteredRows.filter(row => this.productSubGroupFilter.find(filter => filter.value.toUpperCase() === (row.sub_group || '').toUpperCase()));
      }

      this.filteredRows = [...filteredRows];
    }

    onBackClick() {
      this.transitionToRoute('authenticated.index');
    }

    onProductFilterApply(values) {
      this.productFilter = values;
      this.filter();
    }

    onProductGroupFilterApply(values) {
      this.productGroupFilter = values;
      this.filter();
    }

    onProductSubGroupFilterApply(values) {
      this.productSubGroupFilter = values;
      this.filter();
    }

    get isBtnDisabled() {
      return !this.selectedClearingAccount || this.requestInProgress;
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "currentUser", [Ember.inject.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "sessionApi", [Ember.inject.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "notification", [Ember.inject.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "instrument", [Ember.inject.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "time", [Ember.inject.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "api", [Ember.inject.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "riskPositions", [Ember.inject.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "businessUnit", [Ember._tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "selectedClearingAccount", [Ember._tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "startDate", [Ember._tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "endDate", [Ember._tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor12 = _applyDecoratedDescriptor(_class.prototype, "rows", [Ember._tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return [];
    }
  }), _descriptor13 = _applyDecoratedDescriptor(_class.prototype, "requestInProgress", [Ember._tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return false;
    }
  }), _descriptor14 = _applyDecoratedDescriptor(_class.prototype, "clearingAccounts", [Ember._tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor15 = _applyDecoratedDescriptor(_class.prototype, "productFilter", [Ember._tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor16 = _applyDecoratedDescriptor(_class.prototype, "productGroupFilter", [Ember._tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor17 = _applyDecoratedDescriptor(_class.prototype, "productSubGroupFilter", [Ember._tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor18 = _applyDecoratedDescriptor(_class.prototype, "execTypesFilter", [Ember._tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor19 = _applyDecoratedDescriptor(_class.prototype, "orderIdFilter", [Ember._tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor20 = _applyDecoratedDescriptor(_class.prototype, "paginationData", [Ember._tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor21 = _applyDecoratedDescriptor(_class.prototype, "previousRequest", [Ember._tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  }), _descriptor22 = _applyDecoratedDescriptor(_class.prototype, "filteredRows", [Ember._tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return [];
    }
  }), _applyDecoratedDescriptor(_class.prototype, "onBusinessUnitSelect", [Ember._action], Object.getOwnPropertyDescriptor(_class.prototype, "onBusinessUnitSelect"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onClearingAccountSelect", [Ember._action], Object.getOwnPropertyDescriptor(_class.prototype, "onClearingAccountSelect"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onRequestHistory", [Ember._action], Object.getOwnPropertyDescriptor(_class.prototype, "onRequestHistory"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onBackClick", [Ember._action], Object.getOwnPropertyDescriptor(_class.prototype, "onBackClick"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onProductFilterApply", [Ember._action], Object.getOwnPropertyDescriptor(_class.prototype, "onProductFilterApply"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onProductGroupFilterApply", [Ember._action], Object.getOwnPropertyDescriptor(_class.prototype, "onProductGroupFilterApply"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onProductSubGroupFilterApply", [Ember._action], Object.getOwnPropertyDescriptor(_class.prototype, "onProductSubGroupFilterApply"), _class.prototype)), _class);
  _exports.default = AuthenticatedRiskPositionsController;
});