define("incubex/components/x-tree-expanded-icon", ["exports", "incubex/components/x-tree-expanded-icon"], function (_exports, _xTreeExpandedIcon) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{svg-jar "extended" class="collapse-icon" width="14" height="14" font-weight="bold"}}
  */
  {
    id: "t8H3G1bb",
    block: "{\"symbols\":[],\"statements\":[[1,[28,\"svg-jar\",[\"extended\"],[[\"class\",\"width\",\"height\",\"font-weight\"],[\"collapse-icon\",\"14\",\"14\",\"bold\"]]],false]],\"hasEval\":false}",
    meta: {
      moduleName: "incubex/components/x-tree-expanded-icon.hbs"
    }
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember.Component.extend({
    layout: _xTreeExpandedIcon.default
  }));

  _exports.default = _default;
});