define("incubex/mirage/fixtures/session", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    isAuthenticated: true,
    data: {
      authenticated: {
        tokenData: {
          sub: 1
        }
      }
    }
  };
  _exports.default = _default;
});