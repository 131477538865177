define("incubex/authenticators/jwt", ["exports", "ember-simple-auth-token/authenticators/jwt"], function (_exports, _jwt) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _jwt.default.extend({
    api: Ember.inject.service(),

    async authenticate(credentials, headers) {
      let response = await this.makeRequest(this.serverTokenEndpoint, credentials, Ember.assign({}, this.headers, headers));
      return this.handleAuthResponse(response);
    },

    async makeRequest(url, data, headers) {
      return await this.api.post(url, data, headers, {
        camelize: false
      });
    },

    handleAuthResponse(response) {
      let token = Ember.get(response, this.tokenPropertyName);

      if (Ember.isEmpty(token) && Ember.isEmpty(response.errors)) {
        throw Error('Token is empty. Please check your backend response.');
      }

      if (response.errors) {
        throw Error(response.errors);
      }

      let tokenData = this.getTokenData(token);
      let expiresAt = Ember.get(tokenData, this.tokenExpireName);
      let tokenExpireData = {};
      tokenExpireData[this.tokenExpireName] = expiresAt;

      if (this.tokenExpirationInvalidateSession) {
        this.scheduleAccessTokenExpiration(expiresAt);
      }

      if (this.refreshAccessTokens) {
        let refreshToken = Ember.get(response, this.refreshTokenPropertyName);

        if (Ember.isEmpty(refreshToken)) {
          throw Error('Refresh token is empty. Please check your backend response.');
        }

        this.scheduleAccessTokenRefresh(expiresAt, refreshToken);
      }

      return Ember.assign(response, tokenExpireData, {
        tokenData
      });
    }

  });

  _exports.default = _default;
});