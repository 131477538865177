define("incubex/services/orders-service", ["exports", "moment", "incubex/utils/order-enums"], function (_exports, _moment, _orderEnums) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class, _descriptor, _descriptor2, _descriptor3, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let OrdersServiceService = (_class = (_temp = class OrdersServiceService extends Ember.Service {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "notification", _descriptor, this);

      _initializerDefineProperty(this, "ordersSocket", _descriptor2, this);

      _initializerDefineProperty(this, "currentUser", _descriptor3, this);

      this.requestedParkedOrders = {};
      this.requestedActivateOrders = {};
      this.sentOrders = {};
      this.lastClorId = null;
    }

    storeSentOrder(order) {
      this.sentOrders[order.clOrdId] = order;
      return this.sentOrders;
    }

    sendOrder(order, onSuccessMessage) {
      if (!onSuccessMessage) {
        onSuccessMessage = 'Order has been submitted successfully.';
      }

      try {
        if (order.productComplex == 1 || order.marketSegmentId === "EP3") {
          order.type = 'NEW_ORDER_SINGLE';
        } else {
          order.type = 'NEW_ORDER_MULTI_LEG';
        }

        this.storeSentOrder(order);
        this.ordersSocket.send(JSON.stringify({
          orderRequest: order
        }));
        this.notification.success(onSuccessMessage);
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error(e);
        delete this.sentOrders[parseInt(order.clOrdId)];
        this.notification.error("Error occured while placing order. Reason: " + e);
      }
    }

    modifyOrder(order) {
      try {
        if (order.productComplex == 1 || order.marketSegmentId === "EP3") {
          order.type = 'CANCEL_REPLACE_REQUEST';
        } else {
          order.type = 'MULTI_LEG_CANCEL_REPLACE_REQUEST';
        }

        this.ordersSocket.send(JSON.stringify({
          orderRequest: order
        }));
        this.notification.success('Order modification has been submitted successfully.');
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error(e);
        this.notification.error("Error occured while placing order modification request. Reason: " + e);
      }
    }

    cancelAllOrders(showNotification = true) {
      try {
        this.ordersSocket.send(JSON.stringify({
          orderRequest: {
            type: 'CANCEL_ALL'
          }
        }));

        if (showNotification) {
          this.notification.success('Request to cancel all working orders has been submitted successfully.');
        }
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error(e);
        this.notification.error("Error occured while placing request to cancel all working orders. Reason: " + e);
      }
    }

    cancelOrder(order, displayNotification = true) {
      try {
        let cancel = {
          clOrdId: this.getClorId(),
          orderId: order.orderId,
          origClOrdId: order.clOrdId,
          marketSegmentId: order.marketSegmentId.toString(),
          securityId: order.securityId.toString(),
          productComplex: parseInt(order.productComplex)
        };

        if (order.productComplex == 1 || order.marketSegmentId === "EP3") {
          cancel.type = 'CANCEL_REQUEST';
        } else {
          cancel.type = 'MULTI_LEG_CANCEL_REQUEST';
        }

        this.ordersSocket.send(JSON.stringify({
          orderRequest: cancel
        }));
        if (displayNotification) this.notification.success('Request to cancel order has been submitted successfully.');
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error(e);
        this.notification.error("Error occured while placing request to cancel order. Reason: " + e);
        throw e;
      }
    }

    addToRequestedParkedOrders(order) {
      this.requestedParkedOrders[order.orderId] = order;
    }

    removeFromRequestedParkedOrders(orderId) {
      delete this.requestedParkedOrders[orderId];
    }

    addToRequestedActivateOrders(clOrdId, order) {
      this.requestedActivateOrders[clOrdId] = order;
    }

    removeFromRequestedActivateOrders(clorId) {
      delete this.requestedActivateOrders[clorId];
    }

    isOrderActiveRequested(clorId) {
      return this.requestedActivateOrders[clorId] ? true : false;
    }

    getRequestedActivatedOrder(clorId) {
      return this.requestedActivateOrders[clorId];
    }

    isOrderParkedRequested(clorId) {
      return this.requestedParkedOrders[clorId] ? true : false;
    }

    getOrderId() {
      return this.generateUniqueId();
    }

    getClorId() {
      return this.generateUniqueId();
    }

    generateUniqueId() {
      if (!this.lastClorId) {
        this.lastClorId = (this.currentUser.user.id.toString() + Date.now().toString().substring(2, 12)).substring(0, 16);
      } else {
        ++this.lastClorId;
      }

      return this.lastClorId.toString();
    }

    transformToServerFormat(order) {
      return {
        order_qty: order.ordQty,
        price: order.price,
        clord_id: order.clOrdId,
        order_id: order.orderId,
        product_complex: order.productComplex,
        market_segment_id: order.marketSegmentId,
        security_id: order.securityId,
        side: order.side,
        ord_type: order.ordType,
        time_in_force: order.timeInForce,
        expire_date: order.expireDate,
        position_effect: order.positionEffect,
        clearing_account: order.clearingAccount
      };
    }

    transformToFEFormat(order) {
      return {
        ordQty: order.order_qty,
        price: order.price,
        clOrdId: order.clord_id,
        orderId: order.id,
        productComplex: parseInt(order.product_complex),
        marketSegmentId: order.market_segment_id,
        securityId: order.security_id,
        side: order.side,
        ordType: order.ord_type,
        timeInForce: order.time_in_force,
        expireDate: order.expire_date,
        positionEffect: order.position_effect,
        clearingAccount: order.clearing_account,
        cumQty: 0,
        ordStatus: (0, _orderEnums.getParkedOrderStatus)(),
        trdRegTsEntryTime: Date.parse(order.created_at)
      };
    }

    makeWorkingOrderFromOrder(order) {
      return this.makeWorkingOrder(order.ordQty, order.price, order.marketSegmentId, order.securityId, order.side, order.ordType, order.timeInForce, order.expireDate, order.positionEffect, order.clearingAccount, order.productComplex);
    }

    makeWorkingOrder(ordQty, price, marketSegmentId, securityId, side, ordType, timeInForce, expiryDate, positionEffect, clearingAccount, productComplex) {
      let order = {};
      order.orderQty = ordQty.toString();
      order.price = price;
      order.clOrdId = this.getClorId();
      order.marketSegmentId = marketSegmentId.toString();
      order.securityId = securityId.toString();
      order.side = parseInt(side);
      order.ordType = parseInt(ordType);
      order.timeInForce = parseInt(timeInForce);
      order.expireDate = expiryDate ? (0, _moment.default)(expiryDate).format('YYYY/MM/DD') : '';
      order.positionEffect = positionEffect;
      order.clearingAccount = clearingAccount;
      order.productComplex = parseInt(productComplex);
      return order;
    }

    orderConfirmationUI(_order, item, instrument) {
      _order.ui = {};
      _order.ui.product = item;
      _order.ui.instrument = instrument;
      return _order;
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "notification", [Ember.inject.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "ordersSocket", [Ember.inject.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "currentUser", [Ember.inject.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class);
  _exports.default = OrdersServiceService;
});