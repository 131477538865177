define("incubex/components/business-units-and-accounts", ["exports", "@glimmer/component", "incubex/utils/common"], function (_exports, _component, _common) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class, _descriptor, _descriptor2, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div role="select-account">
    <div class="flex justify-between">
      <div class="general-dd">
        <div class="mb-8">
          <label>1. Select Business Unit (Optional)</label>
        </div>
        <PowerSelect
          @ariaDescribedBy="business-unit"
          @class="general-dd"
          @searchEnabled={{true}}
          @matcher={{fn this.matcher}}
          @options={{this.businessUnits}}
          @selected={{this.businessUnit}}
          @searchField="label"
          @placeholder="Choose Business Unit..."
          @allowClear=true
          @onChange={{fn this.onBusinessUnitSelect}} as |businessUnit|>
          {{businessUnit.label}}
        </PowerSelect>
      </div>
      <div class="general-dd ml-24">
        <div class="mb-8">
          <label>2. Select Clearing Account (Required)</label>
        </div>
        <PowerSelect
          @ariaDescribedBy="clearing-account"
          @class="general-dd"
          @searchEnabled={{true}}
          @matcher={{fn this.matcher}}
          @options={{this.clearingAccountsOptions}}
          @selected={{this.selectedClearingAccount}}
          @searchField="label"
          @placeholder="Choose Clearing Account"
          @allowClear=true
          @onChange={{fn this.onClearingAccountSelect}} as |account|>
          {{account.label}}
        </PowerSelect>
      </div>
    </div>
  </div>
  */
  {
    id: "SJ1ht0wg",
    block: "{\"symbols\":[\"account\",\"businessUnit\"],\"statements\":[[7,\"div\",true],[10,\"role\",\"select-account\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"flex justify-between\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"general-dd\"],[8],[0,\"\\n      \"],[7,\"div\",true],[10,\"class\",\"mb-8\"],[8],[0,\"\\n        \"],[7,\"label\",true],[8],[0,\"1. Select Business Unit (Optional)\"],[9],[0,\"\\n      \"],[9],[0,\"\\n      \"],[5,\"power-select\",[],[[\"@ariaDescribedBy\",\"@class\",\"@searchEnabled\",\"@matcher\",\"@options\",\"@selected\",\"@searchField\",\"@placeholder\",\"@allowClear\",\"@onChange\"],[\"business-unit\",\"general-dd\",true,[28,\"fn\",[[23,0,[\"matcher\"]]],null],[23,0,[\"businessUnits\"]],[23,0,[\"businessUnit\"]],\"label\",\"Choose Business Unit...\",\"true\",[28,\"fn\",[[23,0,[\"onBusinessUnitSelect\"]]],null]]],{\"statements\":[[0,\"\\n        \"],[1,[23,2,[\"label\"]],false],[0,\"\\n      \"]],\"parameters\":[2]}],[0,\"\\n    \"],[9],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"general-dd ml-24\"],[8],[0,\"\\n      \"],[7,\"div\",true],[10,\"class\",\"mb-8\"],[8],[0,\"\\n        \"],[7,\"label\",true],[8],[0,\"2. Select Clearing Account (Required)\"],[9],[0,\"\\n      \"],[9],[0,\"\\n      \"],[5,\"power-select\",[],[[\"@ariaDescribedBy\",\"@class\",\"@searchEnabled\",\"@matcher\",\"@options\",\"@selected\",\"@searchField\",\"@placeholder\",\"@allowClear\",\"@onChange\"],[\"clearing-account\",\"general-dd\",true,[28,\"fn\",[[23,0,[\"matcher\"]]],null],[23,0,[\"clearingAccountsOptions\"]],[23,0,[\"selectedClearingAccount\"]],\"label\",\"Choose Clearing Account\",\"true\",[28,\"fn\",[[23,0,[\"onClearingAccountSelect\"]]],null]]],{\"statements\":[[0,\"\\n        \"],[1,[23,1,[\"label\"]],false],[0,\"\\n      \"]],\"parameters\":[1]}],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[9]],\"hasEval\":false}",
    meta: {
      moduleName: "incubex/components/business-units-and-accounts.hbs"
    }
  });

  let BusinessUnitsAndAccountsComponent = (_class = (_temp = class BusinessUnitsAndAccountsComponent extends _component.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "businessUnit", _descriptor, this);

      _initializerDefineProperty(this, "selectedClearingAccount", _descriptor2, this);
    }

    get businessUnits() {
      let businessUnits = this.args.businessUnits || [];
      let units = businessUnits.map(unit => {
        return {
          label: unit.name,
          value: unit.id,
          t7_business_unit: unit.t7_business_unit_id
        };
      });
      return units;
    }

    get clearingAccountsOptions() {
      let accounts = [];

      if (!this.businessUnit) {
        accounts = this.args.clearingAccounts || [];
      } else accounts = this.args.clearingAccounts.filter(account => account.business_unit_id === this.businessUnit.value);

      let _accounts = accounts.map(account => {
        return {
          label: account.t7_account,
          value: account.id,
          business_unit_id: account.business_unit_id
        };
      });

      return _accounts;
    }

    async onBusinessUnitSelect(businessUnit) {
      this.businessUnit = businessUnit;
      this.selectedClearingAccount = null;

      if (this.args.onBusinessUnitChange) {
        this.args.onBusinessUnitChange(businessUnit);
      }
    }

    async onClearingAccountSelect(account) {
      this.selectedClearingAccount = account;

      if (this.args.onClearingAccountChange) {
        this.args.onClearingAccountChange(account);
      }
    }

    matcher(product, term) {
      return (0, _common.matcher)(product, term);
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "businessUnit", [Ember._tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "selectedClearingAccount", [Ember._tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "onBusinessUnitSelect", [Ember._action], Object.getOwnPropertyDescriptor(_class.prototype, "onBusinessUnitSelect"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onClearingAccountSelect", [Ember._action], Object.getOwnPropertyDescriptor(_class.prototype, "onClearingAccountSelect"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "matcher", [Ember._action], Object.getOwnPropertyDescriptor(_class.prototype, "matcher"), _class.prototype)), _class);
  _exports.default = BusinessUnitsAndAccountsComponent;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, BusinessUnitsAndAccountsComponent);
});