define("incubex/components/positions-table", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <EmberTable @class="positionsTable" as |t|  >
    <t.head @columns={{this.columns}} @onReorder={{action handleColumnReOrder}} as |h|>
       <h.row as |r|>
        <r.cell as |column columnMeta|>
          <div class="table-header">
            {{column.name}}
          </div>
          <EmberTh::ResizeHandle @columnMeta={{columnMeta}} />
        </r.cell>
      </h.row>
    </t.head>
    <t.body @class="fill-table" @staticHeight={{true}} @estimateRowHeight=40 @renderAll= {{true}} @rows={{this.rows}} as |b|>
       <b.row as |r|>
        <r.cell as |value column cell column columnMeta|>
          <div>
           {{value}}
          </div>
        </r.cell>
      </b.row>
    </t.body>
  </EmberTable>
  */
  {
    id: "2ouGEF/4",
    block: "{\"symbols\":[\"t\",\"b\",\"r\",\"value\",\"column\",\"cell\",\"column\",\"columnMeta\",\"h\",\"r\",\"column\",\"columnMeta\"],\"statements\":[[5,\"ember-table\",[],[[\"@class\"],[\"positionsTable\"]],{\"statements\":[[0,\"\\n  \"],[6,[23,1,[\"head\"]],[],[[\"@columns\",\"@onReorder\"],[[23,0,[\"columns\"]],[28,\"action\",[[23,0,[]],[24,[\"handleColumnReOrder\"]]],null]]],{\"statements\":[[0,\"\\n     \"],[6,[23,9,[\"row\"]],[],[[],[]],{\"statements\":[[0,\"\\n      \"],[6,[23,10,[\"cell\"]],[],[[],[]],{\"statements\":[[0,\"\\n        \"],[7,\"div\",true],[10,\"class\",\"table-header\"],[8],[0,\"\\n          \"],[1,[23,11,[\"name\"]],false],[0,\"\\n        \"],[9],[0,\"\\n        \"],[5,\"ember-th/resize-handle\",[],[[\"@columnMeta\"],[[23,12,[]]]]],[0,\"\\n      \"]],\"parameters\":[11,12]}],[0,\"\\n    \"]],\"parameters\":[10]}],[0,\"\\n  \"]],\"parameters\":[9]}],[0,\"\\n  \"],[6,[23,1,[\"body\"]],[],[[\"@class\",\"@staticHeight\",\"@estimateRowHeight\",\"@renderAll\",\"@rows\"],[\"fill-table\",true,\"40\",true,[23,0,[\"rows\"]]]],{\"statements\":[[0,\"\\n     \"],[6,[23,2,[\"row\"]],[],[[],[]],{\"statements\":[[0,\"\\n      \"],[6,[23,3,[\"cell\"]],[],[[],[]],{\"statements\":[[0,\"\\n        \"],[7,\"div\",true],[8],[0,\"\\n         \"],[1,[23,4,[]],false],[0,\"\\n        \"],[9],[0,\"\\n      \"]],\"parameters\":[4,5,6,7,8]}],[0,\"\\n    \"]],\"parameters\":[3]}],[0,\"\\n  \"]],\"parameters\":[2]}],[0,\"\\n\"]],\"parameters\":[1]}]],\"hasEval\":false}",
    meta: {
      moduleName: "incubex/components/positions-table.hbs"
    }
  });

  let PositionsTableComponent = (_class = (_temp = class PositionsTableComponent extends _component.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "viewFillDetails", _descriptor, this);

      _initializerDefineProperty(this, "viewFill", _descriptor2, this);

      _initializerDefineProperty(this, "rowsList", _descriptor3, this);

      _initializerDefineProperty(this, "time", _descriptor4, this);

      _initializerDefineProperty(this, "columns", _descriptor5, this);
    }

    get rows() {
      this.rowsList = this.args.positions.map(position => {
        return {
          short_name: position.short_name,
          display_expiration: position.display_expiration,
          position: parseInt(position.position)
        };
      });
      return this.rowsList = [...this.rowsList];
    }

    handleColumnReOrder() {
      this.rowsList = [...this.rowsList];
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "viewFillDetails", [Ember._tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return false;
    }
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "viewFill", [Ember._tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "rowsList", [Ember._tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return [];
    }
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "time", [Ember.inject.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "columns", [Ember._tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return [{
        name: 'Product Name',
        valuePath: 'short_name',
        width: 220
      }, {
        name: 'Expiration',
        valuePath: 'display_expiration',
        width: 100
      }, {
        name: 'Position',
        valuePath: 'position',
        width: 70
      }];
    }
  }), _applyDecoratedDescriptor(_class.prototype, "handleColumnReOrder", [Ember._action], Object.getOwnPropertyDescriptor(_class.prototype, "handleColumnReOrder"), _class.prototype)), _class);
  _exports.default = PositionsTableComponent;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, PositionsTableComponent);
});