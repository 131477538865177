define("incubex/components/notification/notification-user-alert", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class, _descriptor, _descriptor2, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <span data-role="tool-tip">
    {{#if this.serviceMessages}}
      <div class="tooltip user-info-icon">
        {{svg-jar "exclamation-circle" height="18" width="18" class="error"}}
        <div class="tooltip-content">
          <div>
            <h1 class="header">Notifications</h1>
            <ul class="message-text">
              {{#each this.serviceMessages as |message|}}
                <li>{{message.text}}</li>
              {{/each}}
            </ul>
            <i></i>
          </div>
        </div>
      </div>   
    {{/if}}
  </span>
  */
  {
    id: "/o3hhbwb",
    block: "{\"symbols\":[\"message\"],\"statements\":[[7,\"span\",true],[10,\"data-role\",\"tool-tip\"],[8],[0,\"\\n\"],[4,\"if\",[[23,0,[\"serviceMessages\"]]],null,{\"statements\":[[0,\"    \"],[7,\"div\",true],[10,\"class\",\"tooltip user-info-icon\"],[8],[0,\"\\n      \"],[1,[28,\"svg-jar\",[\"exclamation-circle\"],[[\"height\",\"width\",\"class\"],[\"18\",\"18\",\"error\"]]],false],[0,\"\\n      \"],[7,\"div\",true],[10,\"class\",\"tooltip-content\"],[8],[0,\"\\n        \"],[7,\"div\",true],[8],[0,\"\\n          \"],[7,\"h1\",true],[10,\"class\",\"header\"],[8],[0,\"Notifications\"],[9],[0,\"\\n          \"],[7,\"ul\",true],[10,\"class\",\"message-text\"],[8],[0,\"\\n\"],[4,\"each\",[[23,0,[\"serviceMessages\"]]],null,{\"statements\":[[0,\"              \"],[7,\"li\",true],[8],[1,[23,1,[\"text\"]],false],[9],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"          \"],[9],[0,\"\\n          \"],[7,\"i\",true],[8],[9],[0,\"\\n        \"],[9],[0,\"\\n      \"],[9],[0,\"\\n    \"],[9],[0,\"   \\n\"]],\"parameters\":[]},null],[9]],\"hasEval\":false}",
    meta: {
      moduleName: "incubex/components/notification/notification-user-alert.hbs"
    }
  });

  let NotificationUserAlertComponent = (_class = (_temp = class NotificationUserAlertComponent extends _component.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "notification", _descriptor, this);

      _initializerDefineProperty(this, "messages", _descriptor2, this);
    }

    get serviceMessages() {
      const filteredMsgs = this.messages.filter(msg => (msg === null || msg === void 0 ? void 0 : msg.source) === "MARKET_DATA" || (msg === null || msg === void 0 ? void 0 : msg.source) === "ORDER_DATA");
      return filteredMsgs;
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "notification", [Ember.inject.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "messages", [Ember._tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return this.notification.messages;
    }
  })), _class);
  _exports.default = NotificationUserAlertComponent;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, NotificationUserAlertComponent);
});