define("incubex/components/x-tabs-pane-data", ["exports", "incubex/templates/components/x-tabs-pane-data"], function (_exports, _xTabsPaneData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _xTabsPaneData.default,
    classNames: ['content-wrap']
  });

  _exports.default = _default;
});