define("incubex/services/risk-positions", ["exports", "risk_admin_api_grpc_web_pb", "risk_admin_api_pb", "ember-get-config"], function (_exports, _risk_admin_api_grpc_web_pb, _risk_admin_api_pb, _emberGetConfig) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class, _descriptor, _descriptor2, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const {
    T7grpcHost
  } = _emberGetConfig.default;
  const client = new _risk_admin_api_grpc_web_pb.default.RiskAdminAPIPromiseClient("".concat(T7grpcHost, "/t7ordergateway/admin"), null, null);
  let RiskPositionsService = (_class = (_temp = class RiskPositionsService extends Ember.Service {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "sessionApi", _descriptor, this);

      _initializerDefineProperty(this, "instrument", _descriptor2, this);
    }

    async fetch(t7_business_unit, breachedOnly) {
      let data = [];

      try {
        let request = new _risk_admin_api_pb.default.GetAccountRiskPositionsRequest();
        request.setT7Account(t7_business_unit);
        request.setBreachedOnly(breachedOnly);
        data = await client.getAccountRiskPositions(request, {});
        data = data.getRiskPositionsList();
      } catch (err) {
        let errorMsg = 'Unexpected error for getAccountRiskPositions';

        if (err) {
          errorMsg = errorMsg + ": code = ".concat(err.code) + ", message = \"".concat(err.message, "\"");
        }

        console.error(errorMsg);
      }

      let mapPromises = await this.map(data);
      return await Promise.all(mapPromises);
    }

    async map(risk_positions = []) {
      return risk_positions.map(async position => {
        position = position.toObject();
        let _position = {};
        _position.id = position.id;
        _position.id.market_segment_id = position.id.marketSegmentId;
        _position.actual_position = position.actualPosition;
        _position.short_position = position.shortPosition;
        _position.long_position = position.longPosition;
        _position.short_working_order_count = position.shortWorkingOrderCount;
        _position.long_working_order_count = position.longWorkingOrderCount;
        _position.short_risk_limit = position.shortRiskLimit;
        _position.long_risk_limit = position.longRiskLimit;
        _position.short_position_risk_limit_percentage = position.shortPositionRiskLimitPercentage;
        _position.long_position_risk_limit_percentage = position.longPositionRiskLimitPercentage;
        _position.alert_threshold1_percentage = position.alertThreshold1Percentage;
        _position.alert_threshold2_percentage = position.alertThreshold2Percentage;
        let product = await this.instrument.product(position.id.marketSegmentId);

        if (product) {
          _position.instrument_description = product.short_name;
          _position.exchange = product.exchange || 'Nodal';
          _position.product_type = product.product_type;
          _position.group = product.group;
          _position.sub_group = product.sub_group;
        }

        return _position;
      });
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "sessionApi", [Ember.inject.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "instrument", [Ember.inject.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class);
  _exports.default = RiskPositionsService;
});