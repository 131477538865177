define("incubex/components/input/radio-group", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div
    {{class "input-radio-group-component" @class}}
    data-ui-component="input-radio-group"
  >
    {{yield (
      hash Radio=(
        component
        "input/radio"
        disabled=@disabled
        groupValue=@value
        name=@name
        onCheck=@onCheck
        readonly=@readonly
      )
    )}}
  </div>
  
  */
  {
    id: "0rU3KH8E",
    block: "{\"symbols\":[\"@class\",\"@readonly\",\"@onCheck\",\"@name\",\"@value\",\"@disabled\",\"&default\"],\"statements\":[[7,\"div\",false],[12,\"data-ui-component\",\"input-radio-group\"],[3,\"class\",[\"input-radio-group-component\",[23,1,[]]]],[8],[0,\"\\n  \"],[14,7,[[28,\"hash\",null,[[\"Radio\"],[[28,\"component\",[\"input/radio\"],[[\"disabled\",\"groupValue\",\"name\",\"onCheck\",\"readonly\"],[[23,6,[]],[23,5,[]],[23,4,[]],[23,3,[]],[23,2,[]]]]]]]]]],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    meta: {
      moduleName: "incubex/components/input/radio-group.hbs"
    }
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});