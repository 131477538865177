define("incubex/templates/components/x-tabs", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "uWj9lvxJ",
    "block": "{\"symbols\":[\"api\",\"&default\"],\"statements\":[[4,\"with\",[[28,\"hash\",null,[[\"selectAction\",\"registerAction\",\"activeName\",\"name\",\"activeData\"],[[28,\"action\",[[23,0,[]],\"select\"],null],[28,\"action\",[[23,0,[]],\"register\"],null],[24,[\"activeName\"]],[24,[\"name\"]],[24,[\"activeData\"]]]]]],null,{\"statements\":[[0,\"  \"],[14,2,[[28,\"hash\",null,[[\"tabs\",\"panes\",\"dataPane\",\"api\"],[[28,\"component\",[\"x-tabs-tabs\"],[[\"api\",\"ownerName\"],[[23,1,[]],[24,[\"name\"]]]]],[28,\"component\",[\"x-tabs-panes\"],[[\"api\"],[[23,1,[]]]]],[28,\"component\",[\"x-tabs-pane-data\"],[[\"api\"],[[23,1,[]]]]],[23,1,[]]]]]]],[0,\"\\n\"]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "incubex/templates/components/x-tabs.hbs"
    }
  });

  _exports.default = _default;
});