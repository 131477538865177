define("incubex/components/tooltip", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{#modal-dialog tetherTarget=this.args.tetherTarget  targetAttachment='middle right'
      attachment='middle left'  containerClass="clear-account-help"  onClickOverlay= (action this.args.onClose ) }}
      <div class="p-8">
        <p>{{{this.args.text}}}</p>
      </div>
    
  {{/modal-dialog}}
  */
  {
    id: "Q7x1wvaP",
    block: "{\"symbols\":[],\"statements\":[[4,\"modal-dialog\",null,[[\"tetherTarget\",\"targetAttachment\",\"attachment\",\"containerClass\",\"onClickOverlay\"],[[23,0,[\"args\",\"tetherTarget\"]],\"middle right\",\"middle left\",\"clear-account-help\",[28,\"action\",[[23,0,[]],[23,0,[\"args\",\"onClose\"]]],null]]],{\"statements\":[[0,\"    \"],[7,\"div\",true],[10,\"class\",\"p-8\"],[8],[0,\"\\n      \"],[7,\"p\",true],[8],[1,[23,0,[\"args\",\"text\"]],true],[9],[0,\"\\n    \"],[9],[0,\"\\n  \\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    meta: {
      moduleName: "incubex/components/tooltip.hbs"
    }
  });

  class TooltipComponent extends _component.default {}

  _exports.default = TooltipComponent;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, TooltipComponent);
});