define("incubex/templates/components/x-tabs-tab", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "iHdVIUjX",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[7,\"a\",true],[10,\"role\",\"tab\"],[11,\"aria-controls\",[22,\"tabName\"]],[11,\"onclick\",[28,\"action\",[[23,0,[]],[24,[\"api\",\"selectAction\"]],[24,[\"name\"]],[24,[\"data\"]]],null]],[8],[0,\"\\n  \"],[14,1],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "incubex/templates/components/x-tabs-tab.hbs"
    }
  });

  _exports.default = _default;
});