define("incubex/services/system-notifications-socket", ["exports", "incubex/types/socket", "ember-get-config"], function (_exports, _socket, _emberGetConfig) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const {
    blockTradeSocketHost
  } = _emberGetConfig.default;
  let SystemNotificationsSocketService = (_class = (_temp = class SystemNotificationsSocketService extends Ember.Service {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "sessionApi", _descriptor, this);

      _initializerDefineProperty(this, "notification", _descriptor2, this);

      _initializerDefineProperty(this, "time", _descriptor3, this);

      _initializerDefineProperty(this, "instrument", _descriptor4, this);

      _initializerDefineProperty(this, "currentUser", _descriptor5, this);

      this.socket = null;
      this.connections = [];
      this.notificationHandlers = [];
    }

    async connect(url = blockTradeSocketHost, subs) {
      if (!blockTradeSocketHost) {
        return;
      }

      url = url + '?access_token=' + encodeURI(this.sessionApi.sessionAuthToken);
      this.socket = new _socket.default(url, subs);
      this.connections.pushObject(this.socket);
      this.socket.on('open', () => {
        console.log('System-Notifications socket created');
        const msg = {
          command: 'subscribe',
          identifier: JSON.stringify({
            channel: 'SystemNotificationsChannel'
          })
        };
        this.socket.send(JSON.stringify(msg));
      });
      this.socket.on('close', () => {
        console.log('System-Notifications socket closed');
      });
      this.socket.on('error', error => {
        console.error(error); //TODO: Enable when System-Notifications are working
        // this.notification.error(`Error occured on System-Notifications socket`);
      });
      this.socket.on('message', this.onSystemNotificationsData.bind(this));
    }

    disconnect() {
      if (!blockTradeSocketHost) {
        return;
      }

      this.socket.close();
      this.connections.removeObject(this.socket);
    }

    on(eventType, callback) {
      this.socket.on(eventType, callback);
    }

    subscribe(callback) {
      this.handlers.push(callback);
    }

    onSystemNotificationsMessage(callback) {
      this.notificationHandlers.push(callback);
    }

    async onSystemNotificationsData(msg) {
      console.log('System-Notification Msg:', msg);
      let data = JSON.parse(msg.data);

      if (data) {
        let msgType = data.type ? data.type : data.message ? data.message.type : '';

        switch (msgType.toUpperCase()) {
          case 'PING':
          case 'WELCOME':
            // Ignore
            break;

          case 'SYSTEMNOTIFICATIONS':
            {
              this.processNotification(data);
              break;
            }

          case 'DISABLEUSER':
            if (data.message.message.email == this.currentUser.user.email) {
              this.notification.error('Your account is currently disabled. Please contact IncubEx support for assistance.');
              this.currentUser.logout();
            }

            break;

          default:
            console.log('Unknown system-notification message', msg);
        }
      } else {
        console.log('Invalid system-notification message', msg);
      }
    }

    async processNotification(data) {
      let updates = Array.isArray(data) ? data : [data];

      for (let i = 0; i < updates.length; i++) {
        this.notificationHandlers.forEach(async callback => {
          if (callback) {
            await callback(updates[i]);
          }
        });
      }
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "sessionApi", [Ember.inject.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "notification", [Ember.inject.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "time", [Ember.inject.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "instrument", [Ember.inject.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "currentUser", [Ember.inject.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class);
  _exports.default = SystemNotificationsSocketService;
});