define("incubex/grpc_web/api/t7/admin/v1/order_admin_api_pb", [], function () {
  "use strict";

  // source: api/t7/admin/v1/order_admin_api.proto

  /**
   * @fileoverview
   * @enhanceable
   * @suppress {missingRequire} reports error on implicit type usages.
   * @suppress {messageConventions} JS Compiler reports an error if a variable or
   *     field starts with 'MSG_' and isn't a translatable message.
   * @public
   */
  // GENERATED CODE -- DO NOT EDIT!

  /* eslint-disable */
  // @ts-nocheck
  var jspb = require("google-protobuf");

  var goog = jspb;

  var global = typeof globalThis !== 'undefined' && globalThis || typeof window !== 'undefined' && window || typeof global !== 'undefined' && global || typeof self !== 'undefined' && self || function () {
    return this;
  }.call(null) || Function('return this')();

  goog.exportSymbol('proto.api.t7.admin.v1.CancelAllOrdersRequest', null, global);
  goog.exportSymbol('proto.api.t7.admin.v1.CancelAllOrdersResponse', null, global);
  goog.exportSymbol('proto.api.t7.admin.v1.CancelOrderRequest', null, global);
  goog.exportSymbol('proto.api.t7.admin.v1.CancelOrderResponse', null, global);
  goog.exportSymbol('proto.api.t7.admin.v1.UsersWorkingOrderCountRequest', null, global);
  goog.exportSymbol('proto.api.t7.admin.v1.UsersWorkingOrderCountResponse', null, global);
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */

  proto.api.t7.admin.v1.CancelOrderRequest = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  };

  goog.inherits(proto.api.t7.admin.v1.CancelOrderRequest, jspb.Message);

  if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.api.t7.admin.v1.CancelOrderRequest.displayName = 'proto.api.t7.admin.v1.CancelOrderRequest';
  }
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */


  proto.api.t7.admin.v1.CancelOrderResponse = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  };

  goog.inherits(proto.api.t7.admin.v1.CancelOrderResponse, jspb.Message);

  if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.api.t7.admin.v1.CancelOrderResponse.displayName = 'proto.api.t7.admin.v1.CancelOrderResponse';
  }
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */


  proto.api.t7.admin.v1.CancelAllOrdersRequest = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  };

  goog.inherits(proto.api.t7.admin.v1.CancelAllOrdersRequest, jspb.Message);

  if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.api.t7.admin.v1.CancelAllOrdersRequest.displayName = 'proto.api.t7.admin.v1.CancelAllOrdersRequest';
  }
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */


  proto.api.t7.admin.v1.CancelAllOrdersResponse = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  };

  goog.inherits(proto.api.t7.admin.v1.CancelAllOrdersResponse, jspb.Message);

  if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.api.t7.admin.v1.CancelAllOrdersResponse.displayName = 'proto.api.t7.admin.v1.CancelAllOrdersResponse';
  }
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */


  proto.api.t7.admin.v1.UsersWorkingOrderCountRequest = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  };

  goog.inherits(proto.api.t7.admin.v1.UsersWorkingOrderCountRequest, jspb.Message);

  if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.api.t7.admin.v1.UsersWorkingOrderCountRequest.displayName = 'proto.api.t7.admin.v1.UsersWorkingOrderCountRequest';
  }
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */


  proto.api.t7.admin.v1.UsersWorkingOrderCountResponse = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  };

  goog.inherits(proto.api.t7.admin.v1.UsersWorkingOrderCountResponse, jspb.Message);

  if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.api.t7.admin.v1.UsersWorkingOrderCountResponse.displayName = 'proto.api.t7.admin.v1.UsersWorkingOrderCountResponse';
  }

  if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.api.t7.admin.v1.CancelOrderRequest.prototype.toObject = function (opt_includeInstance) {
      return proto.api.t7.admin.v1.CancelOrderRequest.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.api.t7.admin.v1.CancelOrderRequest} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */


    proto.api.t7.admin.v1.CancelOrderRequest.toObject = function (includeInstance, msg) {
      var f,
          obj = {
        orderId: jspb.Message.getFieldWithDefault(msg, 1, 0),
        t7Account: jspb.Message.getFieldWithDefault(msg, 2, "")
      };

      if (includeInstance) {
        obj.$jspbMessageInstance = msg;
      }

      return obj;
    };
  }
  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!proto.api.t7.admin.v1.CancelOrderRequest}
   */


  proto.api.t7.admin.v1.CancelOrderRequest.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.api.t7.admin.v1.CancelOrderRequest();
    return proto.api.t7.admin.v1.CancelOrderRequest.deserializeBinaryFromReader(msg, reader);
  };
  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!proto.api.t7.admin.v1.CancelOrderRequest} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!proto.api.t7.admin.v1.CancelOrderRequest}
   */


  proto.api.t7.admin.v1.CancelOrderRequest.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }

      var field = reader.getFieldNumber();

      switch (field) {
        case 1:
          var value =
          /** @type {number} */
          reader.readUint64();
          msg.setOrderId(value);
          break;

        case 2:
          var value =
          /** @type {string} */
          reader.readString();
          msg.setT7Account(value);
          break;

        default:
          reader.skipField();
          break;
      }
    }

    return msg;
  };
  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */


  proto.api.t7.admin.v1.CancelOrderRequest.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.api.t7.admin.v1.CancelOrderRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  };
  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!proto.api.t7.admin.v1.CancelOrderRequest} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */


  proto.api.t7.admin.v1.CancelOrderRequest.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getOrderId();

    if (f !== 0) {
      writer.writeUint64(1, f);
    }

    f = message.getT7Account();

    if (f.length > 0) {
      writer.writeString(2, f);
    }
  };
  /**
   * optional uint64 order_id = 1;
   * @return {number}
   */


  proto.api.t7.admin.v1.CancelOrderRequest.prototype.getOrderId = function () {
    return (
      /** @type {number} */
      jspb.Message.getFieldWithDefault(this, 1, 0)
    );
  };
  /**
   * @param {number} value
   * @return {!proto.api.t7.admin.v1.CancelOrderRequest} returns this
   */


  proto.api.t7.admin.v1.CancelOrderRequest.prototype.setOrderId = function (value) {
    return jspb.Message.setProto3IntField(this, 1, value);
  };
  /**
   * optional string t7_account = 2;
   * @return {string}
   */


  proto.api.t7.admin.v1.CancelOrderRequest.prototype.getT7Account = function () {
    return (
      /** @type {string} */
      jspb.Message.getFieldWithDefault(this, 2, "")
    );
  };
  /**
   * @param {string} value
   * @return {!proto.api.t7.admin.v1.CancelOrderRequest} returns this
   */


  proto.api.t7.admin.v1.CancelOrderRequest.prototype.setT7Account = function (value) {
    return jspb.Message.setProto3StringField(this, 2, value);
  };

  if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.api.t7.admin.v1.CancelOrderResponse.prototype.toObject = function (opt_includeInstance) {
      return proto.api.t7.admin.v1.CancelOrderResponse.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.api.t7.admin.v1.CancelOrderResponse} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */


    proto.api.t7.admin.v1.CancelOrderResponse.toObject = function (includeInstance, msg) {
      var f,
          obj = {};

      if (includeInstance) {
        obj.$jspbMessageInstance = msg;
      }

      return obj;
    };
  }
  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!proto.api.t7.admin.v1.CancelOrderResponse}
   */


  proto.api.t7.admin.v1.CancelOrderResponse.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.api.t7.admin.v1.CancelOrderResponse();
    return proto.api.t7.admin.v1.CancelOrderResponse.deserializeBinaryFromReader(msg, reader);
  };
  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!proto.api.t7.admin.v1.CancelOrderResponse} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!proto.api.t7.admin.v1.CancelOrderResponse}
   */


  proto.api.t7.admin.v1.CancelOrderResponse.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }

      var field = reader.getFieldNumber();

      switch (field) {
        default:
          reader.skipField();
          break;
      }
    }

    return msg;
  };
  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */


  proto.api.t7.admin.v1.CancelOrderResponse.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.api.t7.admin.v1.CancelOrderResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  };
  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!proto.api.t7.admin.v1.CancelOrderResponse} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */


  proto.api.t7.admin.v1.CancelOrderResponse.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
  };

  if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.api.t7.admin.v1.CancelAllOrdersRequest.prototype.toObject = function (opt_includeInstance) {
      return proto.api.t7.admin.v1.CancelAllOrdersRequest.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.api.t7.admin.v1.CancelAllOrdersRequest} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */


    proto.api.t7.admin.v1.CancelAllOrdersRequest.toObject = function (includeInstance, msg) {
      var f,
          obj = {
        t7UserId: jspb.Message.getFieldWithDefault(msg, 1, 0),
        t7UserPassword: jspb.Message.getFieldWithDefault(msg, 2, ""),
        t7BusinessUnit: jspb.Message.getFieldWithDefault(msg, 3, "")
      };

      if (includeInstance) {
        obj.$jspbMessageInstance = msg;
      }

      return obj;
    };
  }
  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!proto.api.t7.admin.v1.CancelAllOrdersRequest}
   */


  proto.api.t7.admin.v1.CancelAllOrdersRequest.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.api.t7.admin.v1.CancelAllOrdersRequest();
    return proto.api.t7.admin.v1.CancelAllOrdersRequest.deserializeBinaryFromReader(msg, reader);
  };
  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!proto.api.t7.admin.v1.CancelAllOrdersRequest} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!proto.api.t7.admin.v1.CancelAllOrdersRequest}
   */


  proto.api.t7.admin.v1.CancelAllOrdersRequest.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }

      var field = reader.getFieldNumber();

      switch (field) {
        case 1:
          var value =
          /** @type {number} */
          reader.readUint64();
          msg.setT7UserId(value);
          break;

        case 2:
          var value =
          /** @type {string} */
          reader.readString();
          msg.setT7UserPassword(value);
          break;

        case 3:
          var value =
          /** @type {string} */
          reader.readString();
          msg.setT7BusinessUnit(value);
          break;

        default:
          reader.skipField();
          break;
      }
    }

    return msg;
  };
  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */


  proto.api.t7.admin.v1.CancelAllOrdersRequest.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.api.t7.admin.v1.CancelAllOrdersRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  };
  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!proto.api.t7.admin.v1.CancelAllOrdersRequest} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */


  proto.api.t7.admin.v1.CancelAllOrdersRequest.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getT7UserId();

    if (f !== 0) {
      writer.writeUint64(1, f);
    }

    f = message.getT7UserPassword();

    if (f.length > 0) {
      writer.writeString(2, f);
    }

    f = message.getT7BusinessUnit();

    if (f.length > 0) {
      writer.writeString(3, f);
    }
  };
  /**
   * optional uint64 t7_user_id = 1;
   * @return {number}
   */


  proto.api.t7.admin.v1.CancelAllOrdersRequest.prototype.getT7UserId = function () {
    return (
      /** @type {number} */
      jspb.Message.getFieldWithDefault(this, 1, 0)
    );
  };
  /**
   * @param {number} value
   * @return {!proto.api.t7.admin.v1.CancelAllOrdersRequest} returns this
   */


  proto.api.t7.admin.v1.CancelAllOrdersRequest.prototype.setT7UserId = function (value) {
    return jspb.Message.setProto3IntField(this, 1, value);
  };
  /**
   * optional string t7_user_password = 2;
   * @return {string}
   */


  proto.api.t7.admin.v1.CancelAllOrdersRequest.prototype.getT7UserPassword = function () {
    return (
      /** @type {string} */
      jspb.Message.getFieldWithDefault(this, 2, "")
    );
  };
  /**
   * @param {string} value
   * @return {!proto.api.t7.admin.v1.CancelAllOrdersRequest} returns this
   */


  proto.api.t7.admin.v1.CancelAllOrdersRequest.prototype.setT7UserPassword = function (value) {
    return jspb.Message.setProto3StringField(this, 2, value);
  };
  /**
   * optional string t7_business_unit = 3;
   * @return {string}
   */


  proto.api.t7.admin.v1.CancelAllOrdersRequest.prototype.getT7BusinessUnit = function () {
    return (
      /** @type {string} */
      jspb.Message.getFieldWithDefault(this, 3, "")
    );
  };
  /**
   * @param {string} value
   * @return {!proto.api.t7.admin.v1.CancelAllOrdersRequest} returns this
   */


  proto.api.t7.admin.v1.CancelAllOrdersRequest.prototype.setT7BusinessUnit = function (value) {
    return jspb.Message.setProto3StringField(this, 3, value);
  };

  if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.api.t7.admin.v1.CancelAllOrdersResponse.prototype.toObject = function (opt_includeInstance) {
      return proto.api.t7.admin.v1.CancelAllOrdersResponse.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.api.t7.admin.v1.CancelAllOrdersResponse} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */


    proto.api.t7.admin.v1.CancelAllOrdersResponse.toObject = function (includeInstance, msg) {
      var f,
          obj = {};

      if (includeInstance) {
        obj.$jspbMessageInstance = msg;
      }

      return obj;
    };
  }
  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!proto.api.t7.admin.v1.CancelAllOrdersResponse}
   */


  proto.api.t7.admin.v1.CancelAllOrdersResponse.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.api.t7.admin.v1.CancelAllOrdersResponse();
    return proto.api.t7.admin.v1.CancelAllOrdersResponse.deserializeBinaryFromReader(msg, reader);
  };
  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!proto.api.t7.admin.v1.CancelAllOrdersResponse} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!proto.api.t7.admin.v1.CancelAllOrdersResponse}
   */


  proto.api.t7.admin.v1.CancelAllOrdersResponse.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }

      var field = reader.getFieldNumber();

      switch (field) {
        default:
          reader.skipField();
          break;
      }
    }

    return msg;
  };
  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */


  proto.api.t7.admin.v1.CancelAllOrdersResponse.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.api.t7.admin.v1.CancelAllOrdersResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  };
  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!proto.api.t7.admin.v1.CancelAllOrdersResponse} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */


  proto.api.t7.admin.v1.CancelAllOrdersResponse.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
  };

  if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.api.t7.admin.v1.UsersWorkingOrderCountRequest.prototype.toObject = function (opt_includeInstance) {
      return proto.api.t7.admin.v1.UsersWorkingOrderCountRequest.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.api.t7.admin.v1.UsersWorkingOrderCountRequest} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */


    proto.api.t7.admin.v1.UsersWorkingOrderCountRequest.toObject = function (includeInstance, msg) {
      var f,
          obj = {
        t7UserId: jspb.Message.getFieldWithDefault(msg, 1, 0),
        t7UserPassword: jspb.Message.getFieldWithDefault(msg, 2, ""),
        t7BusinessUnit: jspb.Message.getFieldWithDefault(msg, 3, "")
      };

      if (includeInstance) {
        obj.$jspbMessageInstance = msg;
      }

      return obj;
    };
  }
  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!proto.api.t7.admin.v1.UsersWorkingOrderCountRequest}
   */


  proto.api.t7.admin.v1.UsersWorkingOrderCountRequest.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.api.t7.admin.v1.UsersWorkingOrderCountRequest();
    return proto.api.t7.admin.v1.UsersWorkingOrderCountRequest.deserializeBinaryFromReader(msg, reader);
  };
  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!proto.api.t7.admin.v1.UsersWorkingOrderCountRequest} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!proto.api.t7.admin.v1.UsersWorkingOrderCountRequest}
   */


  proto.api.t7.admin.v1.UsersWorkingOrderCountRequest.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }

      var field = reader.getFieldNumber();

      switch (field) {
        case 1:
          var value =
          /** @type {number} */
          reader.readUint64();
          msg.setT7UserId(value);
          break;

        case 2:
          var value =
          /** @type {string} */
          reader.readString();
          msg.setT7UserPassword(value);
          break;

        case 3:
          var value =
          /** @type {string} */
          reader.readString();
          msg.setT7BusinessUnit(value);
          break;

        default:
          reader.skipField();
          break;
      }
    }

    return msg;
  };
  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */


  proto.api.t7.admin.v1.UsersWorkingOrderCountRequest.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.api.t7.admin.v1.UsersWorkingOrderCountRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  };
  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!proto.api.t7.admin.v1.UsersWorkingOrderCountRequest} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */


  proto.api.t7.admin.v1.UsersWorkingOrderCountRequest.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getT7UserId();

    if (f !== 0) {
      writer.writeUint64(1, f);
    }

    f = message.getT7UserPassword();

    if (f.length > 0) {
      writer.writeString(2, f);
    }

    f = message.getT7BusinessUnit();

    if (f.length > 0) {
      writer.writeString(3, f);
    }
  };
  /**
   * optional uint64 t7_user_id = 1;
   * @return {number}
   */


  proto.api.t7.admin.v1.UsersWorkingOrderCountRequest.prototype.getT7UserId = function () {
    return (
      /** @type {number} */
      jspb.Message.getFieldWithDefault(this, 1, 0)
    );
  };
  /**
   * @param {number} value
   * @return {!proto.api.t7.admin.v1.UsersWorkingOrderCountRequest} returns this
   */


  proto.api.t7.admin.v1.UsersWorkingOrderCountRequest.prototype.setT7UserId = function (value) {
    return jspb.Message.setProto3IntField(this, 1, value);
  };
  /**
   * optional string t7_user_password = 2;
   * @return {string}
   */


  proto.api.t7.admin.v1.UsersWorkingOrderCountRequest.prototype.getT7UserPassword = function () {
    return (
      /** @type {string} */
      jspb.Message.getFieldWithDefault(this, 2, "")
    );
  };
  /**
   * @param {string} value
   * @return {!proto.api.t7.admin.v1.UsersWorkingOrderCountRequest} returns this
   */


  proto.api.t7.admin.v1.UsersWorkingOrderCountRequest.prototype.setT7UserPassword = function (value) {
    return jspb.Message.setProto3StringField(this, 2, value);
  };
  /**
   * optional string t7_business_unit = 3;
   * @return {string}
   */


  proto.api.t7.admin.v1.UsersWorkingOrderCountRequest.prototype.getT7BusinessUnit = function () {
    return (
      /** @type {string} */
      jspb.Message.getFieldWithDefault(this, 3, "")
    );
  };
  /**
   * @param {string} value
   * @return {!proto.api.t7.admin.v1.UsersWorkingOrderCountRequest} returns this
   */


  proto.api.t7.admin.v1.UsersWorkingOrderCountRequest.prototype.setT7BusinessUnit = function (value) {
    return jspb.Message.setProto3StringField(this, 3, value);
  };

  if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.api.t7.admin.v1.UsersWorkingOrderCountResponse.prototype.toObject = function (opt_includeInstance) {
      return proto.api.t7.admin.v1.UsersWorkingOrderCountResponse.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.api.t7.admin.v1.UsersWorkingOrderCountResponse} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */


    proto.api.t7.admin.v1.UsersWorkingOrderCountResponse.toObject = function (includeInstance, msg) {
      var f,
          obj = {
        count: jspb.Message.getFieldWithDefault(msg, 1, 0)
      };

      if (includeInstance) {
        obj.$jspbMessageInstance = msg;
      }

      return obj;
    };
  }
  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!proto.api.t7.admin.v1.UsersWorkingOrderCountResponse}
   */


  proto.api.t7.admin.v1.UsersWorkingOrderCountResponse.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.api.t7.admin.v1.UsersWorkingOrderCountResponse();
    return proto.api.t7.admin.v1.UsersWorkingOrderCountResponse.deserializeBinaryFromReader(msg, reader);
  };
  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!proto.api.t7.admin.v1.UsersWorkingOrderCountResponse} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!proto.api.t7.admin.v1.UsersWorkingOrderCountResponse}
   */


  proto.api.t7.admin.v1.UsersWorkingOrderCountResponse.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }

      var field = reader.getFieldNumber();

      switch (field) {
        case 1:
          var value =
          /** @type {number} */
          reader.readUint64();
          msg.setCount(value);
          break;

        default:
          reader.skipField();
          break;
      }
    }

    return msg;
  };
  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */


  proto.api.t7.admin.v1.UsersWorkingOrderCountResponse.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.api.t7.admin.v1.UsersWorkingOrderCountResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  };
  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!proto.api.t7.admin.v1.UsersWorkingOrderCountResponse} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */


  proto.api.t7.admin.v1.UsersWorkingOrderCountResponse.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getCount();

    if (f !== 0) {
      writer.writeUint64(1, f);
    }
  };
  /**
   * optional uint64 count = 1;
   * @return {number}
   */


  proto.api.t7.admin.v1.UsersWorkingOrderCountResponse.prototype.getCount = function () {
    return (
      /** @type {number} */
      jspb.Message.getFieldWithDefault(this, 1, 0)
    );
  };
  /**
   * @param {number} value
   * @return {!proto.api.t7.admin.v1.UsersWorkingOrderCountResponse} returns this
   */


  proto.api.t7.admin.v1.UsersWorkingOrderCountResponse.prototype.setCount = function (value) {
    return jspb.Message.setProto3IntField(this, 1, value);
  };

  goog.object.extend(exports, proto.api.t7.admin.v1);
});