define("incubex/components/copyright", ["exports", "@glimmer/component", "moment"], function (_exports, _component, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <p {{class "text-md" @class}}>&copy;{{this.year}}, IncubEx, All Rights Reserved</p>
  
  */
  {
    id: "i1f16TiF",
    block: "{\"symbols\":[\"@class\"],\"statements\":[[7,\"p\",false],[3,\"class\",[\"text-md\",[23,1,[]]]],[8],[0,\"\xA9\"],[1,[23,0,[\"year\"]],false],[0,\", IncubEx, All Rights Reserved\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    meta: {
      moduleName: "incubex/components/copyright.hbs"
    }
  });

  class CopyrightComponent extends _component.default {
    get year() {
      return (0, _moment.default)().format('YYYY');
    }

  }

  _exports.default = CopyrightComponent;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, CopyrightComponent);
});