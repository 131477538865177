define("incubex/controllers/freestyle", ["exports", "ember-freestyle/controllers/freestyle"], function (_exports, _freestyle) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let FreestyleOctaneController = (_class = (_temp = class FreestyleOctaneController extends _freestyle.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "emberFreestyle", _descriptor, this);

      _initializerDefineProperty(this, "notification", _descriptor2, this);

      _initializerDefineProperty(this, "checkboxAltValue", _descriptor3, this);

      _initializerDefineProperty(this, "checkboxValue", _descriptor4, this);

      _initializerDefineProperty(this, "dropdownValue", _descriptor5, this);

      _initializerDefineProperty(this, "radioValue", _descriptor6, this);

      _initializerDefineProperty(this, "toggleCheck", _descriptor7, this);
    }

    onTransition(event) {
      let element = event.target;

      if (element.classList.contains('text-gray-900')) {
        element.classList.remove('text-gray-900');
        element.classList.remove('p-4');
        element.classList.add('text-green-500');
        element.classList.add('p-24');
      } else {
        element.classList.remove('text-green-500');
        element.classList.remove('p-24');
        element.classList.add('text-gray-900');
        element.classList.add('p-4');
      }
    }

    get dropdownOptions() {
      return [{
        value: '1',
        label: 'Option 1'
      }, {
        value: '2',
        label: 'Option 2'
      }, {
        value: '3',
        label: 'Option 3'
      }, {
        value: '4',
        label: 'Option 4'
      }];
    }

    onClick() {
      alert('Hello');
    }

    onNotifyError() {
      this.notification.error('Error!');
    }

    onNotifyInfo() {
      this.notification.info('Info!');
    }

    onNotifySuccess() {
      this.notification.success('Success!');
    }

    onClearAllNotificationMessages() {
      this.notification.clearAllMessages();
    }

    onToastAutoPop() {
      this.notification.toast('This will close itself.', {
        countdown: true
      });
    }

    onToastManualPop() {
      this.notification.toast('This you will have to close.');
    }

    onCheckBoxClick(event) {
      alert("Check! ".concat(event.target.checked));
      this.checkboxAltValue = !this.checkboxAltValue;
    }

    onSelectRadioValue(value) {
      this.radioValue = value;
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "emberFreestyle", [Ember.inject.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "notification", [Ember.inject.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "checkboxAltValue", [Ember._tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return false;
    }
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "checkboxValue", [Ember._tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return false;
    }
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "dropdownValue", [Ember._tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "radioValue", [Ember._tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "toggleCheck", [Ember._tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return false;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "onClick", [Ember._action], Object.getOwnPropertyDescriptor(_class.prototype, "onClick"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onNotifyError", [Ember._action], Object.getOwnPropertyDescriptor(_class.prototype, "onNotifyError"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onNotifyInfo", [Ember._action], Object.getOwnPropertyDescriptor(_class.prototype, "onNotifyInfo"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onNotifySuccess", [Ember._action], Object.getOwnPropertyDescriptor(_class.prototype, "onNotifySuccess"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onClearAllNotificationMessages", [Ember._action], Object.getOwnPropertyDescriptor(_class.prototype, "onClearAllNotificationMessages"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onToastAutoPop", [Ember._action], Object.getOwnPropertyDescriptor(_class.prototype, "onToastAutoPop"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onToastManualPop", [Ember._action], Object.getOwnPropertyDescriptor(_class.prototype, "onToastManualPop"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onCheckBoxClick", [Ember._action], Object.getOwnPropertyDescriptor(_class.prototype, "onCheckBoxClick"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onSelectRadioValue", [Ember._action], Object.getOwnPropertyDescriptor(_class.prototype, "onSelectRadioValue"), _class.prototype)), _class);
  _exports.default = FreestyleOctaneController;
});