define("incubex/templates/authenticated", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "8sLrlVdl",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"id\",\"layout-authenticated\"],[10,\"class\",\"h-100vh\"],[8],[0,\"\\n  \"],[7,\"main\",true],[10,\"class\",\"flex-1\"],[8],[0,\"\\n    \"],[1,[22,\"outlet\"],false],[0,\"\\n  \"],[9],[0,\"\\n  \"],[5,\"site-header\",[],[[\"@onLogout\",\"@onLogoClick\"],[[28,\"action\",[[23,0,[]],\"onLogout\"],null],[28,\"action\",[[23,0,[]],\"onLogoClick\"],null]]]],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "incubex/templates/authenticated.hbs"
    }
  });

  _exports.default = _default;
});