define("incubex/templates/onboard/start", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "VlynkHqq",
    "block": "{\"symbols\":[],\"statements\":[[5,\"layout/logged-out\",[],[[\"@pageTitle\"],[\"Congratulations!\"]],{\"statements\":[[0,\"\\n  \"],[7,\"p\",true],[10,\"class\",\"mb-40 text-justify\"],[8],[0,\"\\n    You have been invited to set up your new IncubEx account.\\n    Please click the button below to complete your onboarding.\\n  \"],[9],[0,\"\\n\\n  \"],[5,\"link-to\",[[12,\"data-role\",\"complete-onboarding\"]],[[\"@route\",\"@class\"],[\"onboard.create-password\",\"link-button-primary\"]],{\"statements\":[[0,\"\\n    Complete Onboarding\\n  \"]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[]}],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "incubex/templates/onboard/start.hbs"
    }
  });

  _exports.default = _default;
});