define("incubex/controllers/authenticated", ["exports", "ember-get-config"], function (_exports, _emberGetConfig) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const {
    environment
  } = _emberGetConfig.default;
  let AuthenticatedController = (_class = (_temp = class AuthenticatedController extends Ember.Controller {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "notification", _descriptor, this);

      _initializerDefineProperty(this, "session", _descriptor2, this);

      _initializerDefineProperty(this, "menu", _descriptor3, this);

      _initializerDefineProperty(this, "marketDataSocket", _descriptor4, this);

      _initializerDefineProperty(this, "blockTradeSocket", _descriptor5, this);

      _initializerDefineProperty(this, "ordersSocket", _descriptor6, this);

      _initializerDefineProperty(this, "currentUser", _descriptor7, this);

      if (environment !== 'test') {
        setTimeout(this.performHubspotIdentification.bind(this), 2000);
      }
    }

    onLogout() {
      // Invalidates token and reloads
      // the window to /login
      this.currentUser.logout();
    }

    onLogoClick() {
      this.transitionToRoute('authenticated.index');
    }

    performHubspotIdentification() {
      let self = this;

      let onConversationsAPIReady = function onConversationsAPIReady() {
        var hsq = window._hsq = window._hsq || [];
        hsq.push(['identify', {
          email: self.currentUser.user.email
        }]);
        hsq.push(['trackPageView']); // eslint-disable-next-line no-console

        console.log('Hubspot identification sent', hsq, self.currentUser.user.email);
        window.HubSpotConversations.widget.refresh();
      };

      if (window.HubSpotConversations) {
        window.HubSpotConversations.on('conversationStarted', async payload => {
          payload.conversation.conversationId;
          await onConversationsAPIReady();
        });
      }
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "notification", [Ember.inject.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "session", [Ember.inject.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "menu", [Ember.inject.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "marketDataSocket", [Ember.inject.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "blockTradeSocket", [Ember.inject.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "ordersSocket", [Ember.inject.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "currentUser", [Ember.inject.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "onLogout", [Ember._action], Object.getOwnPropertyDescriptor(_class.prototype, "onLogout"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onLogoClick", [Ember._action], Object.getOwnPropertyDescriptor(_class.prototype, "onLogoClick"), _class.prototype)), _class);
  _exports.default = AuthenticatedController;
});