define("incubex/types/user-role", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    ADMIN: 'AdminUser',
    CLEARED: 'ClearedUser',
    VIEW_ONLY: 'ViewOnlyUser',
    CLEARING_ADMIN: 'ClearingAdmin'
  };
  _exports.default = _default;
});