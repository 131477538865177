define("incubex/types/url", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /* This file is intended to be
   * for urls NOT used by Ember Data.
   * To be shared between API calls
   * and Mirage config file.
   */
  var _default = {
    ONBOARD_MFA: '/onboard/users/mfa',
    ONBOARD_MFA_CONFIRM: '/onboard/users/mfa/confirm',
    ONBOARD_MFA_RECOVERY_CODES: '/onboard/users/mfa/recovery_codes',
    ONBOARD_MFA_VERIFY: '/onboard/users/mfa/verify',
    ONBOARD_USER: '/onboard/users',
    ONBOARD_USER_CREDENTIALS: '/onboard/users/credentials',
    ONBOARD_USER_PASSWORD: '/onboard/users/password',
    USER_PASSWORD: '/users/password',
    USER_SIGNIN: '/users/sign_in',
    USER_SENDOTP: '/users/send_otp',
    USER_PASSWORD_RESET_OTP: '/users/password/send_otp',
    USER_PASSWORD_RESET_OTP_VERIFY: '/users/password/verify_otp',
    PORTFOLIO_TABS: '/portfolio_tabs',
    PORTFOLIO_TAB: '/portfolio_tabs',
    CLEARING_ACCOUNTS: '/clearing_accounts',
    ALL_INSTRUMENTS: '/products',
    SEARCH_INSTRUMENTS: '/products/search',
    PRODUCT_TYPES: '/products/types',
    PRODUCT_SUB_GROUPS: '/products/subgroups',
    SECURITY_DEFINATIONS_BY_PRODUCT_ID: '/products/security_definitions',
    TIME: '/time',
    TIME_AND_SALES_TABS: '/time_and_sales_tabs',
    CURATED_PORTFOLIOS: '/curated_portfolios',
    PARKED_ORDERS: '/parked_orders',
    BLOCK_TRADES_SNAPSHOT: '/block_trades',
    SCREEN_TRADES_SNAPSHOT: '/screen_trades',
    PORTFOLIO_TABS_ORDERING: '/portfolio_tabs_ordering',
    CLEAR_ACCOUNT_SETTINGS: '/clearing_account_settings',
    BUSINESS_UNITS: '/business_units',
    CLEARING_ACCOUNTS_METADATA: '/clearing_accounts/metadata',
    GET_RISK_LIMIT: '/risk_limits',
    UPSERT_RISK_LIMIT: '/risk_limits/upsert',
    ORDER_TRADE_HISTORY: '/order_histories',
    PRODUCTS_METADATA: '/products/metadata',
    ORDER_HISTORIES_METADATA: '/order_histories/metadata',
    ORDER_HISTORY_CSV_DOWNLOAD: '/order_histories.csv',
    RISK_POSITIONS: '/risk_positions'
  };
  _exports.default = _default;
});