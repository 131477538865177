define("incubex/mirage/config", ["exports", "ember-cli-mirage/response", "ember-get-config", "incubex/types/url", "incubex/types/onboarding-status", "incubex/mirage/fixtures/onboarding-data"], function (_exports, _response, _emberGetConfig, _url, _onboardingStatus, _onboardingData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;
  const encodedToken = 'eyJhbGciOiJIUzI1NiJ9.eyJzdWIiOjEsImp0aSI6IjIwMTktMTItMjBUMTE6MzU6NDUuMjg4NjAwNTg0IiwidDdVc2VyIjoiYWRtaW5fdDciLCJ0N1VzZXJQYXNzd29yZCI6IlNlY3JldDEyMyEiLCJ0N0J1c2luZXNzVW5pdCI6bnVsbH0.QczcFEEMq8n_gTA7gFBje6WQVYcy3YE2j1zlb3VZse4';

  function _default() {
    this.passthrough('ws://**');
    this.passthrough('https://api.hubspot.com/**');
    this.namespace = _emberGetConfig.default.namespace;
    /*
     * All endpoints after this.namespace will be intercepted
     */

    this.get('users/:id');
    this.post(_url.default.USER_PASSWORD, (schema, request) => {
      let params = JSON.parse(request.requestBody);
      let response;
      let {
        email
      } = params.user;

      if (email === 'admin@example.com') {
        response = new _response.default(200, {}, {});
      } else {
        response = new _response.default(401, {}, {
          errors: 'The email you provided does not match an active account in our system.'
        });
      }

      return response;
    });
    this.post(_url.default.ONBOARD_USER_PASSWORD, (schema, request) => {
      let params = JSON.parse(request.requestBody);
      let response;
      let {
        password,
        confirm_password
      } = params;

      if (password === confirm_password) {
        response = new _response.default(200, {}, {});
      } else {
        response = new _response.default(401, {}, {
          errors: ["Validation failed: Password confirmation doesn't match Password."]
        });
      }

      return response;
    });
    this.post(_url.default.ONBOARD_USER_CREDENTIALS, (schema, request) => {
      let params = JSON.parse(request.requestBody);
      let response;
      let {
        first_name,
        last_name,
        work_phone
      } = params;

      if (first_name && last_name && work_phone) {
        response = new _response.default(200, {}, {});
      } else {
        response = new _response.default(400, {}, {
          errors: ['Validation failed: Fields are missing.']
        });
      }

      return response;
    });
    this.put(_url.default.USER_PASSWORD, (schema, request) => {
      let params = JSON.parse(request.requestBody);
      let response;
      let {
        reset_password_token
      } = params.user;

      if (reset_password_token) {
        let data = _onboardingData.default;
        return JSON.stringify(data);
      } else {
        response = new _response.default(422, {}, {
          errors: ['Password reset token is invalid']
        });
      }

      return response;
    });
    this.post(_url.default.USER_SIGNIN, (schema, request) => {
      let params = JSON.parse(request.requestBody);
      let response;
      let {
        email,
        password
      } = params.user;

      if (email === 'admin@example.com' && password === 'Password123!') {
        response = new _response.default(200, {}, {
          auth_token: encodedToken,
          token_type: 'Bearer'
        });
      } else {
        response = new _response.default(401, {}, {
          errors: 'Email or password is invalid.'
        });
      }

      return response;
    });
    this.get(_url.default.ONBOARD_USER, (schema, request) => {
      let token = request.requestHeaders.authentication;

      if (token) {
        let data = _onboardingData.default;
        return JSON.stringify(data);
      } else {
        return {
          errors: ['Token not provided']
        };
      }
    });
    this.post(_url.default.ONBOARD_USER, (schema, request) => {
      let token = request.requestHeaders.authentication;

      if (token) {
        let data = JSON.parse(request.requestBody);
        data = Object.assign(data, _onboardingData.default);

        if (data.onboardingStatus === _onboardingStatus.default.PASSWORD) {
          data.onboarding_status = _onboardingStatus.default.CREDENTIALS;
        } else if (data.onboardingStatus === _onboardingStatus.default.CREDENTIALS) {
          data.onboarding_status = _onboardingStatus.default.DONE;
        }

        return JSON.stringify(data);
      } else {
        return {
          errors: ['Token not provided']
        };
      }
    });
    this.get('/settings/:id');
    this.put('/settings/:id');
    this.post(_url.default.ONBOARD_MFA, (schema, request) => {
      let token = request.requestHeaders.authentication;

      if (token) {
        return new _response.default(204);
      } else {
        return new _response.default(400, {}, {
          errors: ['Token not provided']
        });
      }
    });
    this.post(_url.default.ONBOARD_MFA_VERIFY, (schema, request) => {
      let token = request.requestHeaders.authentication;

      if (token) {
        return new _response.default(200, {}, {
          codes: ['1234', '1212', '121212', '223233', '232323']
        });
      } else {
        return new _response.default(400, {}, {
          errors: ['Token not provided']
        });
      }
    });
    this.post(_url.default.ONBOARD_MFA_CONFIRM, (schema, request) => {
      let token = request.requestHeaders.authentication;

      if (token) {
        return new _response.default(200, {}, {});
      } else {
        return new _response.default(400, {}, {
          errors: ['Token not provided']
        });
      }
    });
    this.post(_url.default.ONBOARD_MFA_RECOVERY_CODES, (schema, request) => {
      let token = request.requestHeaders.authentication;

      if (token) {
        return new _response.default(200, {}, {});
      } else {
        return new _response.default(400, {}, {
          errors: ['Token not provided']
        });
      }
    });
    this.post(_url.default.USER_SENDOTP, (schema, request) => {
      let email = JSON.parse(request.requestBody).email;

      if (email) {
        return new _response.default(200, {}, {});
      } else {
        return new _response.default(400, {}, {
          errors: ['email not provided']
        });
      }
    });
    this.post(_url.default.USER_PASSWORD_RESET_OTP, (schema, request) => {
      let token = JSON.parse(request.requestBody).token;

      if (token) {
        return new _response.default(200, {}, {});
      } else {
        return new _response.default(400, {}, {
          errors: ['Token not provided']
        });
      }
    });
    this.post(_url.default.USER_PASSWORD_RESET_OTP_VERIFY, (schema, request) => {
      let token = JSON.parse(request.requestBody).token;
      let code = JSON.parse(request.requestBody).code;

      if (token && code) {
        return new _response.default(200, {}, {});
      } else {
        return new _response.default(400, {}, {
          errors: ['Token or code not provided']
        });
      }
    });
    this.get(_url.default.PORTFOLIO_TABS, (schema, request) => {
      let token = request.requestHeaders.authorization;

      if (token) {
        return new _response.default(200, {}, [{
          id: 1,
          name: 'Environmental Tab',
          ordering: null,
          ui_settings: null,
          active_only: null,
          default_order_duration: '1',
          default_clearing_account: 'Account 1',
          default_order_size: 2,
          user_id: 1,
          created_at: '2020-02-10T13:19:49.085Z',
          updated_at: '2020-02-10T13:19:49.085Z',
          tradable_instrument_ids: '2,3',
          tradable_instruments: [{
            id: 2,
            security_id: '101',
            market_segment_id: '10',
            instrument_description: 'CCA V20 Future: Dec20',
            display_expiration: 'Dec 20',
            product: {
              id: 1,
              market_segment_id: '10',
              long_name: 'Texas Compliance Renewable Energy Certificate Specific Vintage 2020',
              short_name: 'TX Cmplnc REC Specific V20',
              code: null,
              product_type: 'Future',
              group: 'Environmental',
              sub_group: 'RECs',
              vintage: null,
              leg1_market_segment_id: null,
              leg2_market_segment_id: null
            }
          }, {
            id: 3,
            security_id: '102',
            market_segment_id: '10',
            instrument_description: 'CCA V20 Future: Jan21',
            display_expiration: 'Jan 21',
            product: {
              id: 1,
              market_segment_id: '10',
              long_name: 'Texas Compliance Renewable Energy Certificate Specific Vintage 2020',
              short_name: 'TX Cmplnc REC Specific V20',
              code: null,
              product_type: 'Future',
              group: 'Environmental',
              sub_group: 'RECs',
              vintage: null,
              leg1_market_segment_id: null,
              leg2_market_segment_id: null
            }
          }]
        }, {
          id: 2,
          name: 'Security Tab',
          ordering: null,
          ui_settings: null,
          active_only: null,
          default_order_duration: '1',
          default_clearing_account: 'Account 1',
          default_order_size: 2,
          user_id: 1,
          created_at: '2020-02-10T13:19:49.085Z',
          updated_at: '2020-02-10T13:19:49.085Z',
          tradable_instrument_ids: '2,3',
          tradable_instruments: [{
            id: 2,
            security_id: '101',
            market_segment_id: '10',
            instrument_description: 'CCA V20 Future: Dec20',
            display_expiration: 'Dec 20',
            product: {
              id: 1,
              market_segment_id: '10',
              long_name: 'Texas Compliance Renewable Energy Certificate Specific Vintage 2020',
              short_name: 'TX Cmplnc REC Specific V20',
              code: null,
              product_type: 'Future',
              group: 'Environmental',
              sub_group: 'RECs',
              vintage: null,
              leg1_market_segment_id: null,
              leg2_market_segment_id: null
            }
          }]
        }]);
      } else {
        return new _response.default(400, {}, {
          errors: ['Token or code not provided']
        });
      }
    });
    this.get("".concat(_url.default.PORTFOLIO_TAB, "/:id"), (schema, request) => {
      let token = request.requestHeaders.authorization;

      if (token) {
        return new _response.default(200, {}, {
          id: 1,
          name: 'Environmental Products',
          ordering: 1,
          default_order_duration: '1',
          default_clearing_account: 'Account 1',
          default_order_size: 2,
          active_only: true,
          ui_settings: '{"columns":{"instrumentDescription":{"visible":true},"vintage":{"visible":false},"maturity":{"visible":true},"productDescription":{"visible":true},"productCode":{"visible":false},"productGroup":{"visible":false},"contractStatus":{"visible":false},"tradingStatus":{"visible":false},"volume":{"visible":true},"bidQty":{"visible":true},"bid":{"visible":true},"ask":{"visible":true},"askQty":{"visible":true},"last":{"visible":true},"lastQty":{"visible":true},"change":{"visible":true},"percentageChange":{"visible":true},"previousSettle":{"visible":true},"open":{"visible":true},"close":{"visible":false},"high":{"visible":false},"low":{"visible":false}}}',
          tradable_instruments: [{
            market_segment_id: 220,
            security_id: 4471,
            instrument_description: 'CCVA Instrument',
            id: '220:4471',
            product: {
              id: 3,
              market_segment_id: '220',
              long_name: 'Ohio Solar Renewable Energy Certificate Vintage 2020',
              short_name: 'OHIO SREC V20',
              code: null,
              product_type: 'Future',
              group: 'Environmental',
              sub_group: 'SRECs',
              vintage: null
            }
          }]
        });
      } else {
        return new _response.default(400, {}, {
          errors: ['Token or code not provided']
        });
      }
    });
    this.get("".concat(_url.default.PORTFOLIO_TAB, "/:id/tradable_instruments"), (schema, request) => {
      let token = request.requestHeaders.authorization;

      if (token) {
        return new _response.default(200, {}, [{
          market_segment_id: 220,
          security_id: 4471,
          instrument_description: 'CCVA Instrument',
          id: '2',
          product: {
            id: 3,
            market_segment_id: '220',
            long_name: 'Ohio Solar Renewable Energy Certificate Vintage 2020',
            short_name: 'OHIO SREC V20',
            code: null,
            product_type: 'Future',
            group: 'Environmental',
            sub_group: 'SRECs',
            vintage: null
          }
        }, {
          market_segment_id: 221,
          security_id: 4471,
          instrument_description: 'CCVA Instrument',
          id: '3',
          product: {
            id: 3,
            market_segment_id: '220',
            long_name: 'Ohio Solar Renewable Energy Certificate Vintage 2020',
            short_name: 'OHIO SREC V20',
            code: null,
            product_type: 'Future',
            group: 'Environmental',
            sub_group: 'SRECs',
            vintage: null
          }
        }]);
      } else {
        return new _response.default(400, {}, {
          errors: ['Token or code not provided']
        });
      }
    });
    this.post(_url.default.PORTFOLIO_TAB, (schema, request) => {
      let token = request.requestHeaders.authorization;
      let name = JSON.parse(request.requestBody).portfolio.name;

      if (token && name) {
        return new _response.default(200, {}, {});
      } else {
        return new _response.default(400, {}, {
          errors: ['Token or name not provided']
        });
      }
    });
    this.post("".concat(_url.default.PORTFOLIO_TAB, "/:id"), (schema, request) => {
      let token = request.requestHeaders.authorization;

      if (token) {
        return new _response.default(200, {}, {});
      } else {
        return new _response.default(400, {}, {
          errors: ['Token or name not provided']
        });
      }
    });
    this.put("".concat(_url.default.PORTFOLIO_TAB, "/:id"), (schema, request) => {
      let token = request.requestHeaders.authorization;

      if (token) {
        return new _response.default(200, {}, {});
      } else {
        return new _response.default(400, {}, {
          errors: ['Token or name not provided']
        });
      }
    });
    this.delete("".concat(_url.default.PORTFOLIO_TAB, "/:id"), (schema, request) => {
      let token = request.requestHeaders.authorization;

      if (token) {
        return new _response.default(200, {}, {});
      } else {
        return new _response.default(400, {}, {
          errors: ['Token not provided']
        });
      }
    });
    this.get("".concat(_url.default.CLEARING_ACCOUNTS), (schema, request) => {
      let token = request.requestHeaders.authorization;

      if (token) {
        return new _response.default(200, {}, [{
          t7_account: 'Account 1',
          business_unit_id: 1,
          id: 1,
          take_up_member: 'one',
          trading_capacity: 'Agency',
          account_status: 'Open'
        }, {
          t7_account: 'Account 2',
          business_unit_id: 2,
          id: 2,
          take_up_member: 'two',
          trading_capacity: 'Agency',
          account_status: 'Closed'
        }, {
          t7_account: 'Account 3',
          business_unit_id: 3,
          id: 3,
          take_up_member: 'three',
          trading_capacity: 'Agency',
          account_status: 'Suspended'
        }]);
      } else {
        return new _response.default(400, {}, {
          errors: ['Token or code not provided']
        });
      }
    });
    this.get(_url.default.SEARCH_INSTRUMENTS, (schema, request) => {
      let token = request.requestHeaders.authorization;

      if (token) {
        return new _response.default(200, {}, [{
          id: 5,
          market_segment_id: '12',
          long_name: 'ERCOT.HB_NORTH_month_on_rtp',
          short_name: 'ERCOT.HB_NORTH_month_on_rtp',
          code: null,
          product_type: 'Future',
          group: 'Power and Gas',
          sub_group: null,
          vintage: null
        }, {
          id: 6,
          market_segment_id: '13',
          long_name: 'ERCOT.HB_SOUTH_month_on_rtp',
          short_name: 'ERCOT.HB_SOUTH_month_on_rtp',
          code: null,
          product_type: 'Future',
          group: 'Power and Gas',
          sub_group: null,
          vintage: null
        }]);
      } else {
        return new _response.default(400, {}, {
          errors: ['Token or code not provided']
        });
      }
    });
    this.get("/products", () => {
      return [];
    });
    this.get("/time", () => {
      return {
        "time": 1613488792
      };
    });
    this.get("/products/:id/security_definitions", (schema, request) => {
      let token = request.requestHeaders.authorization;

      if (token) {
        return new _response.default(200, {}, [{
          type: 'Future',
          items: [{
            id: 1,
            security_id: '100',
            market_segment_id: '10',
            display_expiration: 'Jun 20'
          }, {
            id: 2,
            security_id: '101',
            market_segment_id: '10',
            display_expiration: 'Feb 20'
          }]
        }, {
          type: 'Calendar Spreads',
          items: [{
            id: '3',
            security_id: '103',
            market_segment_id: '20',
            display_expiration: 'Jun 20 / Feb 20'
          }, {
            id: '4',
            security_id: '104',
            market_segment_id: '20',
            display_expiration: 'Feb 20 / Mar 20'
          }]
        }]);
      } else {
        return new _response.default(400, {}, {
          errors: ['Token or code not provided']
        });
      }
    });
    this.get(_url.default.TIME_AND_SALES_TABS, (schema, request) => {
      let token = request.requestHeaders.authorization;

      if (token) {
        return new _response.default(200, {}, [{
          id: 1,
          name: 'First TS Tab Edited',
          filter_active_portfolio: false,
          trade_type: 'screen',
          product_type: 'Future',
          product_group: 'Environmental',
          portfolio_tab_id: 1,
          created_at: '2020-02-10T13:40:30.538Z',
          updated_at: '2020-02-10T13:50:20.958Z'
        }, {
          id: 2,
          name: 'Second TS Tab',
          filter_active_portfolio: true,
          trade_type: 'screen',
          product_type: 'Future',
          product_group: 'All',
          portfolio_tab_id: 1,
          created_at: '2020-02-10T13:52:52.808Z',
          updated_at: '2020-02-10T13:52:52.808Z'
        }]);
      } else {
        return new _response.default(400, {}, {
          errors: ['Token or code not provided']
        });
      }
    });
    this.get("".concat(_url.default.TIME_AND_SALES_TABS, "/:id"), (schema, request) => {
      let token = request.requestHeaders.authorization;

      if (token) {
        return new _response.default(200, {}, {
          id: 1,
          name: 'First TS Tab Edited',
          filter_active_portfolio: false,
          trade_type: 'screen',
          product_type: 'Future',
          product_group: 'Environmental',
          portfolio_tab_id: 1,
          created_at: '2020-02-10T13:40:30.538Z',
          updated_at: '2020-02-10T13:50:20.958Z'
        });
      } else {
        return new _response.default(400, {}, {
          errors: ['Token or code not provided']
        });
      }
    });
    this.post(_url.default.TIME_AND_SALES_TABS, (schema, request) => {
      let token = request.requestHeaders.authorization;
      let name = JSON.parse(request.requestBody).name;

      if (token && name) {
        return new _response.default(200, {}, {});
      } else {
        return new _response.default(400, {}, {
          errors: ['Token or name not provided']
        });
      }
    });
    this.post("".concat(_url.default.TIME_AND_SALES_TABS, "/:id"), (schema, request) => {
      let token = request.requestHeaders.authorization;

      if (token) {
        return new _response.default(200, {}, {});
      } else {
        return new _response.default(400, {}, {
          errors: ['Token or name not provided']
        });
      }
    });
    this.put("".concat(_url.default.TIME_AND_SALES_TABS, "/:id"), (schema, request) => {
      let token = request.requestHeaders.authorization;

      if (token) {
        return new _response.default(200, {}, {});
      } else {
        return new _response.default(400, {}, {
          errors: ['Token or name not provided']
        });
      }
    });
    this.delete("".concat(_url.default.TIME_AND_SALES_TABS, "/:id"), (schema, request) => {
      let token = request.requestHeaders.authorization;

      if (token) {
        return new _response.default(200, {}, {});
      } else {
        return new _response.default(400, {}, {
          errors: ['Token not provided']
        });
      }
    });
    this.get(_url.default.TIME, (schema, request) => {
      let token = request.requestHeaders.authorization;

      if (token) {
        return new _response.default(200, {}, {
          time: 1581676789
        });
      }
    });
    this.get("/security_definitions/by_market_segment_and_security_id", (schema, request) => {
      let token = request.requestHeaders.authorization;

      if (token) {
        return new _response.default(200, {}, {
          "id": 16,
          "security_id": "4",
          "market_segment_id": "2",
          "instrument_description": "OHIO SREC V21 Future: Jan21",
          "maturity_date": "2021-01-01T00:00:00.000Z",
          "instrument_product_complex": 1,
          "prior_settle_price": "8.59",
          "display_expiration": "Jan21",
          "open_interest": 94875,
          "price_precision": 2,
          "product": {
            "id": 5,
            "market_segment_id": "2",
            "long_name": "Ohio Solar Renewable Energy Certificate Vintage 2021",
            "short_name": "OHIO SREC V21 Future",
            "code": "OSE",
            "product_type": "Future",
            "group": "Environmental",
            "sub_group": "SRECs",
            "vintage": 2021,
            "leg1_market_segment_id": null,
            "leg2_market_segment_id": null,
            "market_id": "NODX",
            "website_link": "https://theincubex.com/team/",
            "launch_date": "2020-12-01T00:00:00.000Z",
            "source": "RDF",
            "deleted_at": null
          }
        });
      } else {
        return new _response.default(400, {}, {
          errors: ['Token or code not provided']
        });
      }
    });
    this.delete("".concat(_url.default.PORTFOLIO_TAB, "/:id/time_and_sales_tabs/:id"), (schema, request) => {
      let token = request.requestHeaders.authorization;

      if (token) {
        return new _response.default(200, {}, {});
      } else {
        return new _response.default(400, {}, {
          errors: ['Token not provided']
        });
      }
    });
    this.get(_url.default.TIME, (schema, request) => {
      let token = request.requestHeaders.authorization;

      if (token) {
        return new _response.default(200, {}, {
          time: 1581676789
        });
      }
    });
    this.get(_url.default.CURATED_PORTFOLIOS, (schema, request) => {
      let token = request.requestHeaders.authorization;

      if (token) {
        return new _response.default(200, {}, [{
          id: 1,
          name: 'May Instruments',
          tradable_instrument_ids: '1,2',
          tradable_instruments: [{
            id: 1,
            security_id: '100',
            market_segment_id: '10',
            instrument_description: 'CCA V20 Future: Jan20',
            prior_settle_price: null,
            display_expiration: 'Jan20',
            product: {
              id: 1,
              market_segment_id: '10',
              long_name: 'Texas Compliance Renewable Energy Certificate Specific Vintage 2020',
              short_name: 'TX Cmplnc REC Specific V20',
              code: null,
              product_type: 'Future',
              group: 'Environmental',
              sub_group: 'RECs',
              vintage: null,
              leg1_market_segment_id: null,
              leg2_market_segment_id: null
            }
          }, {
            id: 2,
            security_id: '101',
            market_segment_id: '10',
            instrument_description: 'CCA V20 Future: Dec20',
            prior_settle_price: null,
            display_expiration: 'Dec20',
            product: {
              id: 1,
              market_segment_id: '10',
              long_name: 'Texas Compliance Renewable Energy Certificate Specific Vintage 2020',
              short_name: 'TX Cmplnc REC Specific V20',
              code: null,
              product_type: 'Future',
              group: 'Environmental',
              sub_group: 'RECs',
              vintage: null,
              leg1_market_segment_id: null,
              leg2_market_segment_id: null
            }
          }]
        }]);
      }
    });
    this.get("/security_definitions/by_block_trade/:id", (schema, request) => {
      let token = request.requestHeaders.authorization;

      if (token) {
        return new _response.default(200, {}, {
          id: 1,
          security_id: '100',
          market_segment_id: '9',
          instrument_description: 'NJ SREC V20 Future: Jan20',
          prior_settle_price: null,
          display_expiration: 'Jan20',
          open_interest: null,
          product: {
            id: 1,
            market_segment_id: '9',
            long_name: 'New Jersey Solar Renewable Energy Certificate Vintage 2020 Future',
            short_name: 'NJ SREC V20 Future',
            code: 'NSD',
            product_type: 'Future',
            group: 'Environmental',
            sub_group: null,
            vintage: null,
            leg1_market_segment_id: null,
            leg2_market_segment_id: null
          }
        });
      } else {
        return new _response.default(400, {}, {
          errors: ['Token or code not provided']
        });
      }
    });
    this.post("".concat(_url.default.PARKED_ORDERS), (schema, request) => {
      let token = request.requestHeaders.authorization;
      let order = JSON.parse(request.requestBody);

      if (token) {
        return new _response.default(200, {}, order);
      } else {
        return new _response.default(400, {}, {
          errors: ['Token or name not provided']
        });
      }
    });
    this.put("".concat(_url.default.PARKED_ORDERS, "/:id"), (schema, request) => {
      let token = request.requestHeaders.authorization;
      let order = JSON.parse(request.requestBody);

      if (token) {
        return new _response.default(200, {}, order);
      } else {
        return new _response.default(400, {}, {
          errors: ['Token or name not provided']
        });
      }
    });
    this.delete("".concat(_url.default.PARKED_ORDERS, "/:id"), (schema, request) => {
      let token = request.requestHeaders.authorization;

      if (token) {
        return new _response.default(200, {}, {});
      } else {
        return new _response.default(400, {}, {
          errors: ['Token or name not provided']
        });
      }
    });
    this.get("".concat(_url.default.PARKED_ORDERS), (schema, request) => {
      let token = request.requestHeaders.authorization;

      if (token) {
        return new _response.default(200, {}, [{
          order_qty: 23,
          price: 16,
          clord_id: '221212',
          order_id: '41444',
          product_complex: 10,
          market_segment_id: 12,
          security_id: 11,
          side: 1,
          ord_type: 2,
          time_in_force: 1,
          expire_date: '2011-12-10',
          position_effect: 'O',
          clearing_account: 'clearing',
          id: 12
        }]);
      } else {
        return new _response.default(400, {}, {
          errors: ['Token or name not provided']
        });
      }
    });
    this.put("".concat(_url.default.CLEARING_ACCOUNTS, "/:id"), (schema, request) => {
      let token = request.requestHeaders.authorization;
      let response = null;

      if (token) {
        response = new _response.default(200, {}, {
          auth_token: encodedToken,
          token_type: 'Bearer'
        });
      } else {
        response = new _response.default(401, {}, {
          errors: 'Email or password is invalid.'
        });
      }

      return response;
    });
    this.get("".concat(_url.default.BUSINESS_UNITS), (schema, request) => {
      let token = request.requestHeaders.authorization;

      if (token) {
        return new _response.default(200, {}, [{
          id: 1,
          name: 'First Business Unit',
          t7_business_unit_id: 'local_session'
        }, {
          id: 2,
          name: 'Second Business Unit',
          t7_business_unit_id: 'local_session2'
        }, {
          id: 3,
          name: 'Third Business Unit',
          t7_business_unit_id: 'local_session3'
        }]);
      } else {
        return new _response.default(400, {}, {
          errors: ['Token or name not provided']
        });
      }
    });
    this.get("".concat(_url.default.CLEARING_ACCOUNTS_METADATA), (schema, request) => {
      let token = request.requestHeaders.authorization;

      if (token) {
        return new _response.default(200, {}, {
          trading_capacities: [{
            id: 1,
            code: 'Proprietary'
          }, {
            id: 2,
            code: 'Agency'
          }, {
            id: 3,
            code: 'Market-Making'
          }],
          account_statuses: [{
            id: 1,
            status: 'Open'
          }, {
            id: 2,
            status: 'Suspended'
          }, {
            id: 3,
            status: 'Closed'
          }]
        });
      } else {
        return new _response.default(400, {}, {
          errors: ['Token or name not provided']
        });
      }
    });
    this.post("".concat(_url.default.CLEARING_ACCOUNTS, "/:id/copy_risk_limits"), (schema, request) => {
      let token = request.requestHeaders.authorization;
      let body = JSON.parse(request.requestBody);

      if (token && body.clearing_account.target_clearing_account_id) {
        return new _response.default(200, {}, {});
      } else {
        return new _response.default(400, {}, {
          errors: ['Token or target account not provided']
        });
      }
    });
    this.get("".concat(_url.default.ORDER_TRADE_HISTORY), () => {
      return new _response.default(200, {}, {
        data: [{
          id: 1,
          t7_user_id: '2',
          order_id: '2',
          exec_id: '2',
          side: 'Buy',
          exec_type: 'New',
          ord_status: 'Filled',
          cum_qty: '',
          ord_qty: '',
          fill_px: '',
          fill_qty: '',
          created_at: '2020-11-25T08:31:11.805Z',
          t7_business_unit: '',
          clord_id: '',
          orig_clord_id: '',
          exec_restatement_reason: '',
          market_segment_id: '',
          security_id: '',
          order_qty: '',
          leaves_qty: '',
          cxl_qty: '',
          ord_type: '',
          price: '',
          time_in_force: '',
          trd_reg_ts_entry_time: '',
          trd_reg_ts_time_priority: '',
          expiration_date: '',
          position_effect: '',
          clearing_account: 'clearing account 2',
          fill_match_id: '',
          fill_exec_id: '',
          fill_liquidity_ind: '',
          leg1_fill_ref_id: '',
          leg1_security_id: '',
          leg1_last_px: '',
          leg1_last_qty: '',
          leg1_exec_id: '',
          leg1_side: '',
          leg2_fill_ref_id: '',
          leg2_security_id: '',
          leg2_last_px: '',
          leg2_last_qty: '',
          leg2_exec_id: '',
          leg2_side: '',
          product_complex: '',
          rejection_code: '',
          rejection_text: '',
          rejection_request_type: '',
          transaction_time: '1970-01-01T00:00:00.002Z',
          instrument_description: ''
        }, {
          id: 2,
          t7_user_id: '3',
          order_id: '3',
          exec_id: '3',
          side: 'Buy',
          exec_type: 'New',
          ord_status: 'Filled',
          cum_qty: '',
          ord_qty: '',
          fill_px: '',
          fill_qty: '',
          created_at: '2020-11-25T08:31:12.730Z',
          t7_business_unit: '',
          clord_id: '',
          orig_clord_id: '',
          exec_restatement_reason: '',
          market_segment_id: '',
          security_id: '',
          order_qty: '',
          leaves_qty: '',
          cxl_qty: '',
          ord_type: '',
          price: '',
          time_in_force: '',
          trd_reg_ts_entry_time: '',
          trd_reg_ts_time_priority: '',
          expiration_date: '',
          position_effect: '',
          clearing_account: 'clearing account 3',
          fill_match_id: '',
          fill_exec_id: '',
          fill_liquidity_ind: '',
          leg1_fill_ref_id: '',
          leg1_security_id: '',
          leg1_last_px: '',
          leg1_last_qty: '',
          leg1_exec_id: '',
          leg1_side: '',
          leg2_fill_ref_id: '',
          leg2_security_id: '',
          leg2_last_px: '',
          leg2_last_qty: '',
          leg2_exec_id: '',
          leg2_side: '',
          product_complex: '',
          rejection_code: '',
          rejection_text: '',
          rejection_request_type: '',
          transaction_time: '1970-01-01T00:00:00.003Z',
          instrument_description: ''
        }, {
          id: 3,
          t7_user_id: '4',
          order_id: '4',
          exec_id: '4',
          side: 'Buy',
          exec_type: 'New',
          ord_status: 'Filled',
          cum_qty: '',
          ord_qty: '',
          fill_px: '',
          fill_qty: '',
          created_at: '2020-11-25T08:31:13.483Z',
          t7_business_unit: '',
          clord_id: '',
          orig_clord_id: '',
          exec_restatement_reason: '',
          market_segment_id: '',
          security_id: '',
          order_qty: '',
          leaves_qty: '',
          cxl_qty: '',
          ord_type: '',
          price: '',
          time_in_force: '',
          trd_reg_ts_entry_time: '',
          trd_reg_ts_time_priority: '',
          expiration_date: '',
          position_effect: '',
          clearing_account: 'clearing account 4',
          fill_match_id: '',
          fill_exec_id: '',
          fill_liquidity_ind: '',
          leg1_fill_ref_id: '',
          leg1_security_id: '',
          leg1_last_px: '',
          leg1_last_qty: '',
          leg1_exec_id: '',
          leg1_side: '',
          leg2_fill_ref_id: '',
          leg2_security_id: '',
          leg2_last_px: '',
          leg2_last_qty: '',
          leg2_exec_id: '',
          leg2_side: '',
          product_complex: '',
          rejection_code: '',
          rejection_text: '',
          rejection_request_type: '',
          transaction_time: '1970-01-01T00:00:00.004Z',
          instrument_description: ''
        }, {
          id: 4,
          t7_user_id: '5',
          order_id: '5',
          exec_id: '5',
          side: 'Buy',
          exec_type: 'New',
          ord_status: 'Filled',
          cum_qty: '',
          ord_qty: '',
          fill_px: '',
          fill_qty: '',
          created_at: '2020-11-25T08:31:14.183Z',
          t7_business_unit: '',
          clord_id: '',
          orig_clord_id: '',
          exec_restatement_reason: '',
          market_segment_id: '',
          security_id: '',
          order_qty: '',
          leaves_qty: '',
          cxl_qty: '',
          ord_type: '',
          price: '',
          time_in_force: '',
          trd_reg_ts_entry_time: '',
          trd_reg_ts_time_priority: '',
          expiration_date: '',
          position_effect: '',
          clearing_account: 'clearing account 5',
          fill_match_id: '',
          fill_exec_id: '',
          fill_liquidity_ind: '',
          leg1_fill_ref_id: '',
          leg1_security_id: '',
          leg1_last_px: '',
          leg1_last_qty: '',
          leg1_exec_id: '',
          leg1_side: '',
          leg2_fill_ref_id: '',
          leg2_security_id: '',
          leg2_last_px: '',
          leg2_last_qty: '',
          leg2_exec_id: '',
          leg2_side: '',
          product_complex: '',
          rejection_code: '',
          rejection_text: '',
          rejection_request_type: '',
          transaction_time: '1970-01-01T00:00:00.005Z',
          instrument_description: ''
        }, {
          id: 5,
          t7_user_id: '6',
          order_id: '6',
          exec_id: '6',
          side: 'Buy',
          exec_type: 'New',
          ord_status: 'Filled',
          cum_qty: '',
          ord_qty: '',
          fill_px: '',
          fill_qty: '',
          created_at: '2020-11-25T08:31:14.894Z',
          t7_business_unit: '',
          clord_id: '',
          orig_clord_id: '',
          exec_restatement_reason: '',
          market_segment_id: '',
          security_id: '',
          order_qty: '',
          leaves_qty: '',
          cxl_qty: '',
          ord_type: '',
          price: '',
          time_in_force: '',
          trd_reg_ts_entry_time: '',
          trd_reg_ts_time_priority: '',
          expiration_date: '',
          position_effect: '',
          clearing_account: 'clearing account 6',
          fill_match_id: '',
          fill_exec_id: '',
          fill_liquidity_ind: '',
          leg1_fill_ref_id: '',
          leg1_security_id: '',
          leg1_last_px: '',
          leg1_last_qty: '',
          leg1_exec_id: '',
          leg1_side: '',
          leg2_fill_ref_id: '',
          leg2_security_id: '',
          leg2_last_px: '',
          leg2_last_qty: '',
          leg2_exec_id: '',
          leg2_side: '',
          product_complex: '',
          rejection_code: '',
          rejection_text: '',
          rejection_request_type: '',
          transaction_time: '1970-01-01T00:00:00.006Z',
          instrument_description: ''
        }, {
          id: 6,
          t7_user_id: '7',
          order_id: '7',
          exec_id: '7',
          side: 'Buy',
          exec_type: 'New',
          ord_status: 'Filled',
          cum_qty: '',
          ord_qty: '',
          fill_px: '',
          fill_qty: '',
          created_at: '2020-11-25T08:31:15.669Z',
          t7_business_unit: '',
          clord_id: '',
          orig_clord_id: '',
          exec_restatement_reason: '',
          market_segment_id: '',
          security_id: '',
          order_qty: '',
          leaves_qty: '',
          cxl_qty: '',
          ord_type: '',
          price: '',
          time_in_force: '',
          trd_reg_ts_entry_time: '',
          trd_reg_ts_time_priority: '',
          expiration_date: '',
          position_effect: '',
          clearing_account: 'clearing account 7',
          fill_match_id: '',
          fill_exec_id: '',
          fill_liquidity_ind: '',
          leg1_fill_ref_id: '',
          leg1_security_id: '',
          leg1_last_px: '',
          leg1_last_qty: '',
          leg1_exec_id: '',
          leg1_side: '',
          leg2_fill_ref_id: '',
          leg2_security_id: '',
          leg2_last_px: '',
          leg2_last_qty: '',
          leg2_exec_id: '',
          leg2_side: '',
          product_complex: '',
          rejection_code: '',
          rejection_text: '',
          rejection_request_type: '',
          transaction_time: '1970-01-01T00:00:00.007Z',
          instrument_description: ''
        }, {
          id: 7,
          t7_user_id: '8',
          order_id: '8',
          exec_id: '8',
          side: 'Buy',
          exec_type: 'New',
          ord_status: 'Filled',
          cum_qty: '',
          ord_qty: '',
          fill_px: '',
          fill_qty: '',
          created_at: '2020-11-25T08:31:16.375Z',
          t7_business_unit: '',
          clord_id: '',
          orig_clord_id: '',
          exec_restatement_reason: '',
          market_segment_id: '',
          security_id: '',
          order_qty: '',
          leaves_qty: '',
          cxl_qty: '',
          ord_type: '',
          price: '',
          time_in_force: '',
          trd_reg_ts_entry_time: '',
          trd_reg_ts_time_priority: '',
          expiration_date: '',
          position_effect: '',
          clearing_account: 'clearing account 8',
          fill_match_id: '',
          fill_exec_id: '',
          fill_liquidity_ind: '',
          leg1_fill_ref_id: '',
          leg1_security_id: '',
          leg1_last_px: '',
          leg1_last_qty: '',
          leg1_exec_id: '',
          leg1_side: '',
          leg2_fill_ref_id: '',
          leg2_security_id: '',
          leg2_last_px: '',
          leg2_last_qty: '',
          leg2_exec_id: '',
          leg2_side: '',
          product_complex: '',
          rejection_code: '',
          rejection_text: '',
          rejection_request_type: '',
          transaction_time: '1970-01-01T00:00:00.008Z',
          instrument_description: ''
        }, {
          id: 1,
          t7_user_id: '2',
          order_id: '2',
          exec_id: '2',
          side: 'Buy',
          exec_type: 'New',
          ord_status: 'Filled',
          cum_qty: '',
          ord_qty: '',
          fill_px: '',
          fill_qty: '',
          created_at: '2020-11-25T08:31:11.805Z',
          t7_business_unit: '',
          clord_id: '',
          orig_clord_id: '',
          exec_restatement_reason: '',
          market_segment_id: '',
          security_id: '',
          order_qty: '',
          leaves_qty: '',
          cxl_qty: '',
          ord_type: '',
          price: '',
          time_in_force: '',
          trd_reg_ts_entry_time: '',
          trd_reg_ts_time_priority: '',
          expiration_date: '',
          position_effect: '',
          clearing_account: 'clearing account 2',
          fill_match_id: '',
          fill_exec_id: '',
          fill_liquidity_ind: '',
          leg1_fill_ref_id: '',
          leg1_security_id: '',
          leg1_last_px: '',
          leg1_last_qty: '',
          leg1_exec_id: '',
          leg1_side: '',
          leg2_fill_ref_id: '',
          leg2_security_id: '',
          leg2_last_px: '',
          leg2_last_qty: '',
          leg2_exec_id: '',
          leg2_side: '',
          product_complex: '',
          rejection_code: '',
          rejection_text: '',
          rejection_request_type: '',
          transaction_time: '1970-01-01T00:00:00.002Z',
          instrument_description: ''
        }, {
          id: 2,
          t7_user_id: '3',
          order_id: '3',
          exec_id: '3',
          side: 'Buy',
          exec_type: 'New',
          ord_status: 'Filled',
          cum_qty: '',
          ord_qty: '',
          fill_px: '',
          fill_qty: '',
          created_at: '2020-11-25T08:31:12.730Z',
          t7_business_unit: '',
          clord_id: '',
          orig_clord_id: '',
          exec_restatement_reason: '',
          market_segment_id: '',
          security_id: '',
          order_qty: '',
          leaves_qty: '',
          cxl_qty: '',
          ord_type: '',
          price: '',
          time_in_force: '',
          trd_reg_ts_entry_time: '',
          trd_reg_ts_time_priority: '',
          expiration_date: '',
          position_effect: '',
          clearing_account: 'clearing account 3',
          fill_match_id: '',
          fill_exec_id: '',
          fill_liquidity_ind: '',
          leg1_fill_ref_id: '',
          leg1_security_id: '',
          leg1_last_px: '',
          leg1_last_qty: '',
          leg1_exec_id: '',
          leg1_side: '',
          leg2_fill_ref_id: '',
          leg2_security_id: '',
          leg2_last_px: '',
          leg2_last_qty: '',
          leg2_exec_id: '',
          leg2_side: '',
          product_complex: '',
          rejection_code: '',
          rejection_text: '',
          rejection_request_type: '',
          transaction_time: '1970-01-01T00:00:00.003Z',
          instrument_description: ''
        }, {
          id: 3,
          t7_user_id: '4',
          order_id: '4',
          exec_id: '4',
          side: 'Buy',
          exec_type: 'New',
          ord_status: 'Filled',
          cum_qty: '',
          ord_qty: '',
          fill_px: '',
          fill_qty: '',
          created_at: '2020-11-25T08:31:13.483Z',
          t7_business_unit: '',
          clord_id: '',
          orig_clord_id: '',
          exec_restatement_reason: '',
          market_segment_id: '',
          security_id: '',
          order_qty: '',
          leaves_qty: '',
          cxl_qty: '',
          ord_type: '',
          price: '',
          time_in_force: '',
          trd_reg_ts_entry_time: '',
          trd_reg_ts_time_priority: '',
          expiration_date: '',
          position_effect: '',
          clearing_account: 'clearing account 4',
          fill_match_id: '',
          fill_exec_id: '',
          fill_liquidity_ind: '',
          leg1_fill_ref_id: '',
          leg1_security_id: '',
          leg1_last_px: '',
          leg1_last_qty: '',
          leg1_exec_id: '',
          leg1_side: '',
          leg2_fill_ref_id: '',
          leg2_security_id: '',
          leg2_last_px: '',
          leg2_last_qty: '',
          leg2_exec_id: '',
          leg2_side: '',
          product_complex: '',
          rejection_code: '',
          rejection_text: '',
          rejection_request_type: '',
          transaction_time: '1970-01-01T00:00:00.004Z',
          instrument_description: ''
        }, {
          id: 4,
          t7_user_id: '5',
          order_id: '5',
          exec_id: '5',
          side: 'Buy',
          exec_type: 'New',
          ord_status: 'Filled',
          cum_qty: '',
          ord_qty: '',
          fill_px: '',
          fill_qty: '',
          created_at: '2020-11-25T08:31:14.183Z',
          t7_business_unit: '',
          clord_id: '',
          orig_clord_id: '',
          exec_restatement_reason: '',
          market_segment_id: '',
          security_id: '',
          order_qty: '',
          leaves_qty: '',
          cxl_qty: '',
          ord_type: '',
          price: '',
          time_in_force: '',
          trd_reg_ts_entry_time: '',
          trd_reg_ts_time_priority: '',
          expiration_date: '',
          position_effect: '',
          clearing_account: 'clearing account 5',
          fill_match_id: '',
          fill_exec_id: '',
          fill_liquidity_ind: '',
          leg1_fill_ref_id: '',
          leg1_security_id: '',
          leg1_last_px: '',
          leg1_last_qty: '',
          leg1_exec_id: '',
          leg1_side: '',
          leg2_fill_ref_id: '',
          leg2_security_id: '',
          leg2_last_px: '',
          leg2_last_qty: '',
          leg2_exec_id: '',
          leg2_side: '',
          product_complex: '',
          rejection_code: '',
          rejection_text: '',
          rejection_request_type: '',
          transaction_time: '1970-01-01T00:00:00.005Z',
          instrument_description: ''
        }, {
          id: 5,
          t7_user_id: '6',
          order_id: '6',
          exec_id: '6',
          side: 'Buy',
          exec_type: 'New',
          ord_status: 'Filled',
          cum_qty: '',
          ord_qty: '',
          fill_px: '',
          fill_qty: '',
          created_at: '2020-11-25T08:31:14.894Z',
          t7_business_unit: '',
          clord_id: '',
          orig_clord_id: '',
          exec_restatement_reason: '',
          market_segment_id: '',
          security_id: '',
          order_qty: '',
          leaves_qty: '',
          cxl_qty: '',
          ord_type: '',
          price: '',
          time_in_force: '',
          trd_reg_ts_entry_time: '',
          trd_reg_ts_time_priority: '',
          expiration_date: '',
          position_effect: '',
          clearing_account: 'clearing account 6',
          fill_match_id: '',
          fill_exec_id: '',
          fill_liquidity_ind: '',
          leg1_fill_ref_id: '',
          leg1_security_id: '',
          leg1_last_px: '',
          leg1_last_qty: '',
          leg1_exec_id: '',
          leg1_side: '',
          leg2_fill_ref_id: '',
          leg2_security_id: '',
          leg2_last_px: '',
          leg2_last_qty: '',
          leg2_exec_id: '',
          leg2_side: '',
          product_complex: '',
          rejection_code: '',
          rejection_text: '',
          rejection_request_type: '',
          transaction_time: '1970-01-01T00:00:00.006Z',
          instrument_description: ''
        }, {
          id: 6,
          t7_user_id: '7',
          order_id: '7',
          exec_id: '7',
          side: 'Buy',
          exec_type: 'New',
          ord_status: 'Filled',
          cum_qty: '',
          ord_qty: '',
          fill_px: '',
          fill_qty: '',
          created_at: '2020-11-25T08:31:15.669Z',
          t7_business_unit: '',
          clord_id: '',
          orig_clord_id: '',
          exec_restatement_reason: '',
          market_segment_id: '',
          security_id: '',
          order_qty: '',
          leaves_qty: '',
          cxl_qty: '',
          ord_type: '',
          price: '',
          time_in_force: '',
          trd_reg_ts_entry_time: '',
          trd_reg_ts_time_priority: '',
          expiration_date: '',
          position_effect: '',
          clearing_account: 'clearing account 7',
          fill_match_id: '',
          fill_exec_id: '',
          fill_liquidity_ind: '',
          leg1_fill_ref_id: '',
          leg1_security_id: '',
          leg1_last_px: '',
          leg1_last_qty: '',
          leg1_exec_id: '',
          leg1_side: '',
          leg2_fill_ref_id: '',
          leg2_security_id: '',
          leg2_last_px: '',
          leg2_last_qty: '',
          leg2_exec_id: '',
          leg2_side: '',
          product_complex: '',
          rejection_code: '',
          rejection_text: '',
          rejection_request_type: '',
          transaction_time: '1970-01-01T00:00:00.007Z',
          instrument_description: ''
        }, {
          id: 7,
          t7_user_id: '8',
          order_id: '8',
          exec_id: '8',
          side: 'Buy',
          exec_type: 'New',
          ord_status: 'Filled',
          cum_qty: '',
          ord_qty: '',
          fill_px: '',
          fill_qty: '',
          created_at: '2020-11-25T08:31:16.375Z',
          t7_business_unit: '',
          clord_id: '',
          orig_clord_id: '',
          exec_restatement_reason: '',
          market_segment_id: '',
          security_id: '',
          order_qty: '',
          leaves_qty: '',
          cxl_qty: '',
          ord_type: '',
          price: '',
          time_in_force: '',
          trd_reg_ts_entry_time: '',
          trd_reg_ts_time_priority: '',
          expiration_date: '',
          position_effect: '',
          clearing_account: 'clearing account 8',
          fill_match_id: '',
          fill_exec_id: '',
          fill_liquidity_ind: '',
          leg1_fill_ref_id: '',
          leg1_security_id: '',
          leg1_last_px: '',
          leg1_last_qty: '',
          leg1_exec_id: '',
          leg1_side: '',
          leg2_fill_ref_id: '',
          leg2_security_id: '',
          leg2_last_px: '',
          leg2_last_qty: '',
          leg2_exec_id: '',
          leg2_side: '',
          product_complex: '',
          rejection_code: '',
          rejection_text: '',
          rejection_request_type: '',
          transaction_time: '1970-01-01T00:00:00.008Z',
          instrument_description: ''
        }]
      });
    });
    this.get("".concat(_url.default.PRODUCTS_METADATA), (schema, request) => {
      let token = request.requestHeaders.authorization;

      if (token) {
        return new _response.default(200, {}, {
          ids: [['DAX / STOXX 50 Interproduct Spread', 15], ['FUT 8-13 Y. SWISS GOV.BONDS 6%', 12], ['FUT ON DAX INDEX', 13], ['FUT ON STOXX 50 INDEX', 14], ['Henry Hub Pen 2500 Future', 8], ['ISONE..Z.MAINE_month_off_dap', 7], ['ISONE..Z.MAINE_month_on_dap', 6], ['NJ SREC V20 Future', 1], ['National US Van Future', 9], ['OHIO SREC V20 Future', 4], ['OHIO SREC V21 Future', 5], ['TX Compliance REC Specific V20 Future', 2], ['TX Compliance REC Specific V20/V21 Spread', 10], ['TX Compliance REC Specific V21 Future', 3], ['TX REC Specific / OH Solar V20 Spread', 11]],
          sub_groups: ['SubGroup 1', 'RECs', 'SRECs', 'SubGroup 2', 'SubGroup 3', 'SubGroup 4', 'SubGroup 5', 'SubGroup 6', 'SubGroup 7', 'SubGroup 8', 'SubGroup 9'],
          groups: ['Environmental', 'Power and Gas', 'Trucking Freight', 'Group 1', 'Group 2', 'Group 3', 'Group 4']
        });
      } else {
        return new _response.default(400, {}, {
          errors: ['Token or target account not provided']
        });
      }
    });
    this.get("".concat(_url.default.ORDER_HISTORIES_METADATA), (schema, request) => {
      let token = request.requestHeaders.authorization;

      if (token) {
        return new _response.default(200, {}, {
          exec_types: ['NEW', 'REJECTED', 'TRADE', 'CANCELLED', 'REPLACED'],
          order_ids: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10']
        });
      } else {
        return new _response.default(400, {}, {
          errors: ['Token or target account not provided']
        });
      }
    });
    this.get("".concat(_url.default.RISK_POSITIONS), (schema, request) => {
      let token = request.requestHeaders.authorization;

      if (token) {
        return new _response.default(200, {}, [{
          "id": {
            "market_segment_id": "4",
            "security_id": "2"
          },
          "actual_position": "200",
          "short_position": "12",
          "long_position": "15",
          "short_working_order_count": "2",
          "long_working_order_count": "4",
          "short_risk_limit": "21",
          "long_risk_limit": "33",
          "short_position_risk_limit_percentage": "12",
          "long_position_risk_limit_percentage": "11",
          "alert_threshold1_percentage": "45",
          "alert_threshold2_percentage": "65"
        }, {
          "id": {
            "market_segment_id": "94",
            "security_id": "72057997764853760"
          },
          "actual_position": "100",
          "short_position": "23",
          "long_position": "45",
          "short_working_order_count": "12",
          "long_working_order_count": "54",
          "short_risk_limit": "10",
          "long_risk_limit": "98",
          "short_position_risk_limit_percentage": "24",
          "long_position_risk_limit_percentage": "21",
          "alert_threshold1_percentage": "65",
          "alert_threshold2_percentage": "57"
        }]);
      } else {
        return new _response.default(400, {}, {
          errors: ['Token or target account not provided']
        });
      }
    });
  }
});