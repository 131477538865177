define("incubex/components/toggle", ["exports", "@glimmer/component", "ember-cli-guid"], function (_exports, _component, _emberCliGuid) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <label
    {{class
     "toggle-component"
     @class
     checked=@checked
     disabled=@disabled
    }}
    for={{this.guid}}
    data-ui-component="toggle"
    data-role={{@role}}
  >
    <div class="__switch" />
    <span class="__text">
      {{@label}}
    </span>
    <Input
      @checked={{@checked}}
      @disabled={{@disabled}}
      @id={{this.guid}}
      @name={{this.name}}
      @type="checkbox"
    />
  </label>
  
  */
  {
    id: "XJfA2RyG",
    block: "{\"symbols\":[\"@role\",\"@disabled\",\"@checked\",\"@class\",\"@label\"],\"statements\":[[7,\"label\",false],[12,\"for\",[23,0,[\"guid\"]]],[12,\"data-ui-component\",\"toggle\"],[12,\"data-role\",[23,1,[]]],[3,\"class\",[\"toggle-component\",[23,4,[]]],[[\"checked\",\"disabled\"],[[23,3,[]],[23,2,[]]]]],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"__switch\"],[8],[9],[0,\"\\n  \"],[7,\"span\",true],[10,\"class\",\"__text\"],[8],[0,\"\\n    \"],[1,[23,5,[]],false],[0,\"\\n  \"],[9],[0,\"\\n  \"],[5,\"input\",[],[[\"@checked\",\"@disabled\",\"@id\",\"@name\",\"@type\"],[[23,3,[]],[23,2,[]],[23,0,[\"guid\"]],[23,0,[\"name\"]],\"checkbox\"]]],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    meta: {
      moduleName: "incubex/components/toggle.hbs"
    }
  });

  class ToggleComponent extends _component.default {
    constructor() {
      super(...arguments);
      (true && !(this.args.label) && Ember.assert('<Toggle> requires a label', this.args.label));
    }

    get guid() {
      let guid = _emberCliGuid.default.create();

      guid = _emberCliGuid.default.compact(guid);
      return guid;
    }

    get name() {
      return Ember.String.dasherize(this.args.label);
    }

  }

  _exports.default = ToggleComponent;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, ToggleComponent);
});