define("incubex/controllers/login", ["exports", "incubex/utils/input-validators", "incubex/types/url"], function (_exports, _inputValidators, _url) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let ApplicationController = (_class = (_temp = class ApplicationController extends Ember.Controller {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "session", _descriptor, this);

      _initializerDefineProperty(this, "currentUser", _descriptor2, this);

      _initializerDefineProperty(this, "notification", _descriptor3, this);

      _initializerDefineProperty(this, "api", _descriptor4, this);

      _initializerDefineProperty(this, "authenticationCode", _descriptor5, this);

      _initializerDefineProperty(this, "email", _descriptor6, this);

      _initializerDefineProperty(this, "emailError", _descriptor7, this);

      _initializerDefineProperty(this, "password", _descriptor8, this);

      _initializerDefineProperty(this, "recoveryCode", _descriptor9, this);

      _initializerDefineProperty(this, "stepAuthCode", _descriptor10, this);

      _initializerDefineProperty(this, "stepDefault", _descriptor11, this);

      _initializerDefineProperty(this, "stepRecoveryCode", _descriptor12, this);
    }

    get isLoginDisabled() {
      return !this.isValidEmail || Ember.isEmpty(this.email) || Ember.isEmpty(this.password);
    }

    get isValidEmail() {
      return (0, _inputValidators.validateEmail)(this.email);
    }

    onValidateEmail() {
      this.emailError = !this.isValidEmail;
    }

    async onSendOTP(event) {
      event.preventDefault();
      let data = {
        email: this.email,
        password: this.password
      };

      try {
        let response = await this.api.post(_url.default.USER_SENDOTP, data);
        this._email = this.email; // this.email is bound with UI, so becomes empty on UI another step.

        this._password = this.password;
        this.email = '';
        this.password = '';
        this.stepDefault = false;
        this.stepRecoveryCode = false;

        if (response && response.skipOtp) {
          await this.authenticateUser();
          this.stepAuthCode = false;
          return;
        } else {
          this.stepAuthCode = true;
        }
      } catch (error) {
        this.notification.error(error.errors || error);
      }
    }

    async onResendToken() {
      let data = {
        email: this._email,
        password: this._password
      };

      try {
        await this.api.post(_url.default.USER_SENDOTP, data);
        this.notification.success('An authentication code has been sent to the phone number associated with your account');
      } catch (error) {
        this.notification.error(error.errors || error);
      }
    }

    async onAuthenticateWithRecoveryCode() {
      event.preventDefault();
      let data = {
        user: {
          email: this._email,
          password: this._password,
          otp_attempt: this.recoveryCode
        }
      };

      try {
        await this.session.authenticate('authenticator:jwt', data);
        this.email = '';
        this.password = '';
      } catch (error) {
        if (error.message.startsWith("Invalid")) {
          error.message = "The Recovery Code you entered is not valid. Please try again.";
        }

        this.notification.error(error.errors || error);
      }
    }

    async onAuthenticateWithToken(event) {
      event.preventDefault();
      this.authenticateUser();
    }

    linkToRecoveryCodeStep() {
      this.stepDefault = false;
      this.stepRecoveryCode = true;
      this.stepAuthCode = false;
      this.authenticationCode = '';
    }

    linkToOtpStep() {
      this.stepDefault = false;
      this.stepRecoveryCode = false;
      this.stepAuthCode = true;
      this.recoveryCodeCode = '';
    }

    async authenticateUser() {
      let data = {
        user: {
          email: this._email,
          password: this._password,
          otp_attempt: this.authenticationCode
        }
      };

      try {
        await this.session.authenticate('authenticator:jwt', data);
        this.email = '';
        this.password = '';
      } catch (error) {
        if (error.message.startsWith("Invalid")) {
          error.message = "The One Time Password (OTP) you entered is not valid. Please try again.";
        }

        this.notification.error(error.errors || error);
      }
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "session", [Ember.inject.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "currentUser", [Ember.inject.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "notification", [Ember.inject.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "api", [Ember.inject.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "authenticationCode", [Ember._tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return '';
    }
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "email", [Ember._tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return '';
    }
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "emailError", [Ember._tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return false;
    }
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "password", [Ember._tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return '';
    }
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "recoveryCode", [Ember._tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return '';
    }
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "stepAuthCode", [Ember._tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "stepDefault", [Ember._tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return true;
    }
  }), _descriptor12 = _applyDecoratedDescriptor(_class.prototype, "stepRecoveryCode", [Ember._tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "onValidateEmail", [Ember._action], Object.getOwnPropertyDescriptor(_class.prototype, "onValidateEmail"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onSendOTP", [Ember._action], Object.getOwnPropertyDescriptor(_class.prototype, "onSendOTP"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onResendToken", [Ember._action], Object.getOwnPropertyDescriptor(_class.prototype, "onResendToken"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onAuthenticateWithRecoveryCode", [Ember._action], Object.getOwnPropertyDescriptor(_class.prototype, "onAuthenticateWithRecoveryCode"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onAuthenticateWithToken", [Ember._action], Object.getOwnPropertyDescriptor(_class.prototype, "onAuthenticateWithToken"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "linkToRecoveryCodeStep", [Ember._action], Object.getOwnPropertyDescriptor(_class.prototype, "linkToRecoveryCodeStep"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "linkToOtpStep", [Ember._action], Object.getOwnPropertyDescriptor(_class.prototype, "linkToOtpStep"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "authenticateUser", [Ember._action], Object.getOwnPropertyDescriptor(_class.prototype, "authenticateUser"), _class.prototype)), _class);
  _exports.default = ApplicationController;
});