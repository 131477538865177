define("incubex/components/input/checkbox", ["exports", "@glimmer/component", "ember-cli-guid"], function (_exports, _component, _emberCliGuid) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <label
    {{class
     "input-checkbox-component"
     @class
     checked=@checked
     disabled=@disabled
    }}
    for={{this.guid}}
    data-ui-component="input-checkbox"
  >
    <div class="__checkmark-box">
      {{#if @checked}}
        {{svg-jar "checkmark" class="__checkmark"}}
      {{/if}}
    </div>
    {{#if hasBlock}}
      {{yield}}
    {{else}}
      {{@label}}
    {{/if}}
    <Input
      @checked={{@checked}}
      @disabled={{@disabled}}
      @id={{this.guid}}
      @name={{@name}}
      @type="checkbox"
  
      @click={{action "onClick"}}
    />
  </label>
  
  */
  {
    id: "lRtTu9iu",
    block: "{\"symbols\":[\"@label\",\"&default\",\"@disabled\",\"@checked\",\"@class\",\"@name\"],\"statements\":[[7,\"label\",false],[12,\"for\",[23,0,[\"guid\"]]],[12,\"data-ui-component\",\"input-checkbox\"],[3,\"class\",[\"input-checkbox-component\",[23,5,[]]],[[\"checked\",\"disabled\"],[[23,4,[]],[23,3,[]]]]],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"__checkmark-box\"],[8],[0,\"\\n\"],[4,\"if\",[[23,4,[]]],null,{\"statements\":[[0,\"      \"],[1,[28,\"svg-jar\",[\"checkmark\"],[[\"class\"],[\"__checkmark\"]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"],[9],[0,\"\\n\"],[4,\"if\",[[25,2]],null,{\"statements\":[[0,\"    \"],[14,2],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[1,[23,1,[]],false],[0,\"\\n\"]],\"parameters\":[]}],[0,\"  \"],[5,\"input\",[],[[\"@checked\",\"@disabled\",\"@id\",\"@name\",\"@type\",\"@click\"],[[23,4,[]],[23,3,[]],[23,0,[\"guid\"]],[23,6,[]],\"checkbox\",[28,\"action\",[[23,0,[]],\"onClick\"],null]]]],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    meta: {
      moduleName: "incubex/components/input/checkbox.hbs"
    }
  });

  let InputCheckboxComponent = (_class = class InputCheckboxComponent extends _component.default {
    constructor() {
      super(...arguments);
      (true && !(this.args.name) && Ember.assert('<Input::Checkbox> requires a name', this.args.name));
    }

    get guid() {
      let guid = _emberCliGuid.default.create();

      guid = _emberCliGuid.default.compact(guid);
      return guid;
    }

    onClick(event) {
      if (this.args.onClick && !this.args.disabled) {
        this.args.onClick(event);
      }
    }

  }, (_applyDecoratedDescriptor(_class.prototype, "onClick", [Ember._action], Object.getOwnPropertyDescriptor(_class.prototype, "onClick"), _class.prototype)), _class);
  _exports.default = InputCheckboxComponent;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, InputCheckboxComponent);
});