define("incubex/utils/market-data", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.getSecurityStatus = _exports.getTradingStatus = _exports.marketDataColumns = void 0;

  const marketDataColumns = () => {
    var x1 = 50;
    var x2 = 65;
    var x3 = 75;
    var x4 = 100;
    var x5 = 200;
    return {
      productName: {
        visible: true,
        description: 'Product Name',
        valuePath: 'productName',
        width: x5
      },
      productDescription: {
        visible: false,
        description: 'Product Description',
        valuePath: 'productDescription',
        width: x4
      },
      productDetails: {
        visible: false,
        description: 'Product Details',
        valuePath: 'productDetails',
        width: x4
      },
      productCode: {
        visible: false,
        description: 'Product Code',
        valuePath: 'productCode',
        width: x4
      },
      productGroup: {
        visible: false,
        description: 'Product Group',
        valuePath: 'productGroup',
        width: x4
      },
      productSubGroup: {
        visible: false,
        description: 'Product Sub-Group',
        valuePath: 'productSubGroup',
        width: x4
      },
      vintage: {
        visible: false,
        description: 'Vintage',
        valuePath: 'vintage',
        width: x1
      },
      expiration: {
        visible: true,
        description: 'Expiration',
        valuePath: 'expiration',
        width: x4
      },
      expirationDate: {
        visible: false,
        description: 'Expiration Date',
        valuePath: 'expirationDate',
        width: x3
      },
      exchange: {
        visible: false,
        description: 'Exchange',
        valuePath: 'exchange',
        width: x2
      },
      contractStatus: {
        visible: false,
        description: 'Contract Status',
        valuePath: 'contractStatus',
        width: x2
      },
      tradingStatus: {
        visible: false,
        description: 'Trading Status',
        valuePath: 'tradingStatus',
        width: x2
      },
      volume: {
        visible: true,
        description: 'Volume',
        valuePath: 'volume',
        width: x2
      },
      hit: {
        visible: true,
        description: 'Hit',
        valuePath: 'hit',
        width: x3
      },
      openInterest: {
        visible: false,
        description: 'Open Interest',
        valuePath: 'open_interest',
        width: x2
      },
      bidQty: {
        visible: true,
        description: 'Bid Qty',
        valuePath: 'bidQty',
        width: x2
      },
      bid: {
        visible: true,
        description: 'Bid',
        valuePath: 'bid',
        width: x1
      },
      ask: {
        visible: true,
        description: 'Ask',
        valuePath: 'ask',
        width: x1
      },
      askQty: {
        visible: true,
        description: 'Ask Qty',
        valuePath: 'askQty',
        width: x2
      },
      lift: {
        visible: true,
        description: 'Lift',
        valuePath: 'lift',
        width: x3
      },
      last: {
        visible: true,
        description: 'Last',
        valuePath: 'lastPrice',
        width: x1
      },
      lastQty: {
        visible: true,
        description: 'Last Qty',
        valuePath: 'lastSize',
        width: x2
      },
      change: {
        visible: true,
        description: 'Change',
        valuePath: 'change',
        width: x1
      },
      percentageChange: {
        visible: true,
        description: '% CHANGE',
        valuePath: 'percentageChange',
        width: x2
      },
      priorSettle: {
        visible: false,
        description: 'Prior Settle',
        valuePath: 'priorSettle',
        width: x2
      },
      open: {
        visible: false,
        description: 'Open',
        valuePath: 'openPrice',
        width: x1
      },
      close: {
        visible: false,
        description: 'Close',
        valuePath: 'closePrice',
        width: x1
      },
      high: {
        visible: false,
        description: 'High',
        valuePath: 'highPrice',
        width: x1
      },
      low: {
        visible: false,
        description: 'Low',
        valuePath: 'lowPrice',
        width: x1
      }
    };
  };

  _exports.marketDataColumns = marketDataColumns;

  const getTradingStatus = () => {
    return {
      [0]: 'TradingHalt',
      //2
      [1]: 'Closed',
      //200
      [2]: 'Restricted',
      //201
      [3]: 'Book',
      //202
      [4]: 'Continuous',
      //203
      [5]: 'OpeningAuction',
      //204
      [6]: 'OpeningAuctionFreeze',
      //205
      [7]: 'IntradayAuction',
      //206
      [8]: 'IntradayAuctionFreeze',
      //207
      [9]: 'CircuitBreakerAuction',
      //208
      [10]: 'CircuitBreakerAuctionFreeze',
      //209
      [11]: 'ClosingAuction',
      //210
      [12]: 'ClosingAuctionFreeze',
      //211
      [13]: 'IPOAuction',
      //212
      [14]: 'IPOAuctionFreeze',
      //213
      [15]: 'PreCall',
      //214
      [16]: 'Call',
      //215
      [17]: 'Freeze'
    };
  };

  _exports.getTradingStatus = getTradingStatus;

  const getSecurityStatus = () => {
    return {
      [0]: 'Active',
      [1]: 'Inactive',
      [2]: 'Expired',
      [3]: 'KnockedOut',
      [4]: 'KnockOutRevoked',
      [5]: 'Suspended',
      [6]: 'PendingDeletion',
      [7]: 'KnockedOutAndSuspended'
    };
  };

  _exports.getSecurityStatus = getSecurityStatus;
});