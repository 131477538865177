define("incubex/controllers/authenticated/clearing-account-settings", ["exports", "incubex/utils/input-validators"], function (_exports, _inputValidators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _descriptor13, _descriptor14, _descriptor15, _descriptor16, _descriptor17, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let AuthenticatedClearingAccountSettingsController = (_class = (_temp = class AuthenticatedClearingAccountSettingsController extends Ember.Controller {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "ClearingAccountSettings", _descriptor, this);

      _initializerDefineProperty(this, "currentUser", _descriptor2, this);

      _initializerDefineProperty(this, "ClearingAccounts", _descriptor3, this);

      _initializerDefineProperty(this, "sessionApi", _descriptor4, this);

      _initializerDefineProperty(this, "notification", _descriptor5, this);

      _initializerDefineProperty(this, "businessUnit", _descriptor6, this);

      _initializerDefineProperty(this, "t7Account", _descriptor7, this);

      _initializerDefineProperty(this, "tradingCapacity", _descriptor8, this);

      _initializerDefineProperty(this, "status", _descriptor9, this);

      _initializerDefineProperty(this, "takeUpMember", _descriptor10, this);

      _initializerDefineProperty(this, "selectedClearingAccount", _descriptor11, this);

      _initializerDefineProperty(this, "enableCurrentDayPositionAlerts", _descriptor12, this);

      _initializerDefineProperty(this, "alertEmailAddresses", _descriptor13, this);

      _initializerDefineProperty(this, "positionLimitAlert1", _descriptor14, this);

      _initializerDefineProperty(this, "positionLimitAlert2", _descriptor15, this);

      _initializerDefineProperty(this, "showHelp", _descriptor16, this);

      _initializerDefineProperty(this, "emailError", _descriptor17, this);
    }

    get businessUnits() {
      return this.currentUser.businessUnits || [];
    }

    get statuses() {
      let statuses = this.metadata.account_statuses || [];
      return statuses.map(unit => {
        return {
          label: unit.status,
          value: unit.status
        };
      });
    }

    get tradingCapacities() {
      let tradingCapacities = this.metadata.trading_capacities || [];
      return tradingCapacities.map(unit => {
        return {
          label: unit.code,
          value: unit.code
        };
      });
    }

    async onBusinessUnitSelect(businessUnit) {
      this.businessUnit = businessUnit;
      this.selectedClearingAccount = null;

      this._updateUIProperties(null);
    }

    async onClearingAccountSelect(account) {
      this.selectedClearingAccount = account;

      this._updateUIProperties();
    }

    get clearingAccount() {
      if (!this.selectedClearingAccount) return null;
      let account = this.clearingAccounts.find(acc => acc.id === this.selectedClearingAccount.value);
      return account;
    }

    _updateUIProperties() {
      let account = this.clearingAccount;

      if (account) {
        this.status = account.account_status;
        this.tradingCapacity = account.trading_capacity;
        this.takeUpMember = account.take_up_member;
        this.enableCurrentDayPositionAlerts = account.send_position_alert;
        this.alertEmailAddresses = account.alert_emails;
        this.positionLimitAlert1 = account.position_alert_1;
        this.positionLimitAlert2 = account.position_alert_2;
      } else {
        this.status = null;
        this.tradingCapacity = null;
        this.takeUpMember = null;
        this.enableCurrentDayPositionAlerts = false;
        this.alertEmailAddresses = null;
        this.positionLimitAlert1 = null;
        this.positionLimitAlert2 = null;
      }
    }

    async onUpdateAccountSettings() {
      try {
        await this.ClearingAccountSettings.update({
          clearing_account: {
            trading_capacity: this.tradingCapacity,
            take_up_member: this.takeUpMember,
            account_status: this.status,
            send_position_alert: this.enableCurrentDayPositionAlerts,
            alert_emails: this.alertEmailAddresses,
            position_alert_1: this.positionLimitAlert1,
            position_alert_2: this.positionLimitAlert2
          }
        }, this.selectedClearingAccount.value);
        this.notification.success('Account settings updated successfully.');
        this.transitionToRoute('authenticated.index');
      } catch (error) {
        this.notification.error('Could not update account settings');
      }
    }

    async onBackClick() {
      this.transitionToRoute('authenticated.index');
    }

    matcher(product, term) {
      let words = term.split(' ');
      words = words.filter(item => item);
      let found = 1;
      let searchIndex = 0;
      words.forEach(x => {
        searchIndex = product.label.toLowerCase().indexOf(x.toLowerCase(), searchIndex);

        if (searchIndex < 0) {
          found = -1;
          return false;
        }
      });
      return found;
    }

    toggleShowHelp() {
      this.showHelp = !this.showHelp;
    }

    onValidateEmail() {
      return this.emailError = !this.isValidEmail;
    }

    get isValidEmail() {
      if (!this.alertEmailAddresses) return true;
      let addresses = this.alertEmailAddresses;
      let emails = addresses.split(';');
      return !emails.find(email => !(0, _inputValidators.validateEmail)(email.trim()));
    }

    get isButtonDisabled() {
      return !this.selectedClearingAccount || !this.isValidEmail;
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "ClearingAccountSettings", [Ember.inject.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "currentUser", [Ember.inject.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "ClearingAccounts", [Ember.inject.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "sessionApi", [Ember.inject.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "notification", [Ember.inject.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "businessUnit", [Ember._tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "t7Account", [Ember._tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return '';
    }
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "tradingCapacity", [Ember._tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "status", [Ember._tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "takeUpMember", [Ember._tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "selectedClearingAccount", [Ember._tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  }), _descriptor12 = _applyDecoratedDescriptor(_class.prototype, "enableCurrentDayPositionAlerts", [Ember._tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  }), _descriptor13 = _applyDecoratedDescriptor(_class.prototype, "alertEmailAddresses", [Ember._tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return '';
    }
  }), _descriptor14 = _applyDecoratedDescriptor(_class.prototype, "positionLimitAlert1", [Ember._tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return '';
    }
  }), _descriptor15 = _applyDecoratedDescriptor(_class.prototype, "positionLimitAlert2", [Ember._tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return '';
    }
  }), _descriptor16 = _applyDecoratedDescriptor(_class.prototype, "showHelp", [Ember._tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return false;
    }
  }), _descriptor17 = _applyDecoratedDescriptor(_class.prototype, "emailError", [Ember._tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return false;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "onBusinessUnitSelect", [Ember._action], Object.getOwnPropertyDescriptor(_class.prototype, "onBusinessUnitSelect"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onClearingAccountSelect", [Ember._action], Object.getOwnPropertyDescriptor(_class.prototype, "onClearingAccountSelect"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onUpdateAccountSettings", [Ember._action], Object.getOwnPropertyDescriptor(_class.prototype, "onUpdateAccountSettings"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onBackClick", [Ember._action], Object.getOwnPropertyDescriptor(_class.prototype, "onBackClick"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "matcher", [Ember._action], Object.getOwnPropertyDescriptor(_class.prototype, "matcher"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "toggleShowHelp", [Ember._action], Object.getOwnPropertyDescriptor(_class.prototype, "toggleShowHelp"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onValidateEmail", [Ember._action], Object.getOwnPropertyDescriptor(_class.prototype, "onValidateEmail"), _class.prototype)), _class);
  _exports.default = AuthenticatedClearingAccountSettingsController;
});