define("incubex/serializers/application", ["exports", "active-model-adapter"], function (_exports, _activeModelAdapter) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class ApplicationSerializer extends _activeModelAdapter.ActiveModelSerializer {
    keyForAttribute(key) {
      return Ember.String.underscore(key);
    }

  }

  _exports.default = ApplicationSerializer;
});