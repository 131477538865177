define("incubex/components/x-tab", ["exports", "incubex/templates/components/x-tab", "incubex/mixins/component-parent", "incubex/components/x-tab/pane"], function (_exports, _xTab, _componentParent, _pane) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_componentParent.default, {
    layout: _xTab.default,
    classNames: ['tabs'],
    classNameBindings: ['tab-style', 'customClass'],
    childPanes: Ember.computed.filter('children', function (view) {
      return view instanceof _pane.default;
    }),
    activeId: Ember.computed.oneWay('childPanes.firstObject.elementId'),
    isActiveId: Ember.computed('activeId', {
      get() {
        return Ember.getWithDefault(this, 'activeId', null);
      },

      set(key, value) {
        return value;
      }

    }),
    navItems: Ember.computed('childPanes.@each.{elementId,title,icon}', function () {
      let items = Ember.A();
      this.get('childPanes').forEach(pane => {
        let item = pane.getProperties('elementId', 'title', 'icon');
        items.push(item);
      });
      return items;
    }),
    actions: {
      select(id) {
        this.set('isActiveId', id);
      }

    }
  });

  _exports.default = _default;
});