define("incubex/mirage/scenarios/default", ["exports", "incubex/types/user-role"], function (_exports, _userRole) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;

  function _default(server) {
    /*
      Seed your development database using your factories.
      This data will not be loaded in your tests.
    */
    server.create('user', {
      id: '1',
      email: 'admin@example.com',
      type: _userRole.default.VIEW_ONLY
    });
  }
});