define("incubex/components/x-tree-toggle", ["exports", "incubex/components/x-tree-toggle"], function (_exports, _xTreeToggle) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <span role="button" class="toggle-icon" {{action toggleExpand bubbles=true}}>
    {{#if model.children.length}}
      {{#if model.isExpanded}}
        {{component expandedIcon}}
      {{else}}
        {{component collapsedIcon}}
      {{/if}}
    {{else}}
      &nbsp;&nbsp;
    {{/if}}
  </span>
  
  */
  {
    id: "PJPGASaB",
    block: "{\"symbols\":[],\"statements\":[[7,\"span\",false],[12,\"role\",\"button\"],[12,\"class\",\"toggle-icon\"],[3,\"action\",[[23,0,[]],[24,[\"toggleExpand\"]]],[[\"bubbles\"],[true]]],[8],[0,\"\\n\"],[4,\"if\",[[24,[\"model\",\"children\",\"length\"]]],null,{\"statements\":[[4,\"if\",[[24,[\"model\",\"isExpanded\"]]],null,{\"statements\":[[0,\"      \"],[1,[28,\"component\",[[24,[\"expandedIcon\"]]],null],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"      \"],[1,[28,\"component\",[[24,[\"collapsedIcon\"]]],null],false],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[]},{\"statements\":[[0,\"    \xA0\xA0\\n\"]],\"parameters\":[]}],[9],[0,\"\\n\"]],\"hasEval\":false}",
    meta: {
      moduleName: "incubex/components/x-tree-toggle.hbs"
    }
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember.Component.extend({
    layout: _xTreeToggle.default,
    tagName: 'span',
    classNames: ['tree-toggle']
  }));

  _exports.default = _default;
});