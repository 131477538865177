define("incubex/controllers/authenticated/settings", ["exports", "incubex/types/timezones", "moment"], function (_exports, _timezones, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class, _descriptor, _descriptor2, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let AuthenticatedSettingsController = (_class = (_temp = class AuthenticatedSettingsController extends Ember.Controller {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "notification", _descriptor, this);

      _initializerDefineProperty(this, "time", _descriptor2, this);
    }

    get isUpdateDisabled() {
      return !this.model.hasDirtyAttributes;
    }

    get timezoneOptions() {
      return _timezones.default.map(value => {
        let label;

        if (value === 'System') {
          label = 'My Location';
        } else {
          let offset = _moment.default.tz((0, _moment.default)(), value).format('Z');

          let timezone = _moment.default.tz((0, _moment.default)(), value).format('z');

          label = "".concat(timezone, " (GMT").concat(offset, ")");
        }

        return {
          label,
          value
        };
      });
    }

    async onUpdateSettings(event) {
      event.preventDefault();

      try {
        await this.model.save();
        this.notification.success('Your settings have been saved.');
        this.onCancel();
      } catch (error) {
        this.notification.error(error);
      }
    }

    async onCancel() {
      this.transitionToRoute('authenticated.index');
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "notification", [Ember.inject.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "time", [Ember.inject.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "onUpdateSettings", [Ember._action], Object.getOwnPropertyDescriptor(_class.prototype, "onUpdateSettings"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onCancel", [Ember._action], Object.getOwnPropertyDescriptor(_class.prototype, "onCancel"), _class.prototype)), _class);
  _exports.default = AuthenticatedSettingsController;
});