define("incubex/components/notification/toast", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class, _descriptor, _descriptor2, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <li {{class "__toast" is-closing=this.isClosing}} data-ui-component="notification-toast">
    <div class="__wrapper">
      <p class="__toast-text" data-role="toast-text">{{@toast.text}}</p>
    </div>
  
    {{#if (not @toast.options.countdown)}}
      <button
        class="__dismiss-button"
        onclick={{action "onPop"}}
        data-role="dismiss-toast"
      >
        Dismiss
      </button>
    {{/if}}
  </li>
  
  */
  {
    id: "7FQ99gGp",
    block: "{\"symbols\":[\"@toast\"],\"statements\":[[7,\"li\",false],[12,\"data-ui-component\",\"notification-toast\"],[3,\"class\",[\"__toast\"],[[\"is-closing\"],[[23,0,[\"isClosing\"]]]]],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"__wrapper\"],[8],[0,\"\\n    \"],[7,\"p\",true],[10,\"class\",\"__toast-text\"],[10,\"data-role\",\"toast-text\"],[8],[1,[23,1,[\"text\"]],false],[9],[0,\"\\n  \"],[9],[0,\"\\n\\n\"],[4,\"if\",[[28,\"not\",[[23,1,[\"options\",\"countdown\"]]],null]],null,{\"statements\":[[0,\"    \"],[7,\"button\",true],[10,\"class\",\"__dismiss-button\"],[11,\"onclick\",[28,\"action\",[[23,0,[]],\"onPop\"],null]],[10,\"data-role\",\"dismiss-toast\"],[8],[0,\"\\n      Dismiss\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[9],[0,\"\\n\"]],\"hasEval\":false}",
    meta: {
      moduleName: "incubex/components/notification/toast.hbs"
    }
  });

  let NotificationToastComponent = (_class = (_temp = class NotificationToastComponent extends _component.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "notification", _descriptor, this);

      _initializerDefineProperty(this, "isClosing", _descriptor2, this);

      if (this.args.toast.options.countdown) {
        this.timer = this.autoPop();
      }
    }

    pop() {
      if (!this.isClosing) {
        this.isClosing = true;
        setTimeout(() => this.notification.removeToast(this.args.toast), this.notification.delay.close);
      }
    }

    autoPop() {
      return setTimeout(() => this.pop(), this.notification.delay.auto);
    }

    onPop() {
      if (this.timer) {
        clearTimeout(this.timer);
      }

      this.pop();
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "notification", [Ember.inject.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "isClosing", [Ember._tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return false;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "onPop", [Ember._action], Object.getOwnPropertyDescriptor(_class.prototype, "onPop"), _class.prototype)), _class);
  _exports.default = NotificationToastComponent;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, NotificationToastComponent);
});